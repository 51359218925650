import { getPaymentGatewayConfig } from "../GlobalHelpers";

// Constants & Methods for Payment Gateways - * Please do not setup any configs here. *
export const paymentGateway = {
    name: {
        hydraGateway: 'hydra',
        ocbcGateway: 'ocbc',
        ocbcExpressGateway: 'ocbcExpress',
        midtrans: 'midtrans',
        ecPayGateway: 'ecpay',
        cityLineGateway: 'cityLine',
        cyberSourceGateway: 'cbs'
    }
}

export const isMidtransPaymentGateway = () => getPaymentGatewayConfig() === paymentGateway.name.midtrans
export const isHydraPaymentGateway = () => getPaymentGatewayConfig() === paymentGateway.name.hydraGateway
export const isOcbcPaymentGateway = () => getPaymentGatewayConfig() === paymentGateway.name.ocbcGateway
export const isOcbcExpressPaymentGateway = () => getPaymentGatewayConfig() === paymentGateway.name.ocbcExpressGateway
export const isECPaymentGateway = () => getPaymentGatewayConfig() === paymentGateway.name.ecPayGateway
export const isCityLinePaymentGateway = () => getPaymentGatewayConfig() === paymentGateway.name.cityLineGateway
export const isCyberSourcePaymentGateway = () => getPaymentGatewayConfig() === paymentGateway.name.cyberSourceGateway
