import { logn } from '@Components/utils/PikaLog'
import { extendObservable, makeAutoObservable, runInAction } from 'mobx'
import StoreCountry from '@Stores/StoreCountry'
import { getCountry } from '@Components/GlobalHelpers'
import { isSomething } from '@Components/utils/Utils'
import StoreAuth from './StoreAuth'
import { checkToken } from '@Services/Network'

function CurrentCountry() {
    const c = 'korea'
    return c
}
class SessionAuth {
    storeKey = 'login-extends'

    user = null
    users = []

    needResetPassword = false

    SetNeedResetPassword(value) {
        this.needResetPassword = value
    }

    NeedResetPassword() {
        return this.needResetPassword
    }

    ResetSessionStorage() {
        logn('REMOVING SESSION STORAGE')

        sessionStorage.removeItem('menu')
        sessionStorage.removeItem('menuGroupA-desktop')
        sessionStorage.removeItem('menuGroupA-mobile')
        sessionStorage.removeItem('menuGroupB-desktop')
        sessionStorage.removeItem('menuGroupB-mobile')
        sessionStorage.removeItem('genealogy-filter')

        sessionStorage.removeItem('dummy-user')
        sessionStorage.removeItem('customerData')
        sessionStorage.removeItem('customerHref')
        sessionStorage.removeItem('customerToken')
        sessionStorage.removeItem(`${StoreAuth.id}-breadcrumbs`)
        sessionStorage.removeItem(`${StoreAuth.id}-breadcrumbsHistory`)
        sessionStorage.removeItem('user-token')
        sessionStorage.removeItem('user-href')
        sessionStorage.removeItem('onself-response')
        sessionStorage.removeItem('tokenExpire')
    }

    async Current() {
        await this.GetStoredSession()
            .then(response => {
                runInAction(() => {
                    this.user = response
                })
            })
            .catch(error => {
                console.log('error', error)
            })

        return this.user
    }

    Token() {
        return this.GetLoginExtend('token')
    }

    Href() {
        return this.GetLoginExtend('href')
    }

    CustomerData() {
        let customerData = sessionStorage.getItem('customerData')
        if (customerData) {
            return JSON.parse(customerData)
        } else {
            return null
        }
    }

    SaveSession(data) {
        sessionStorage.setItem(
            'customerData',
            JSON.stringify(data.customerData)
        )
        sessionStorage.setItem('customerToken', data.token)
        sessionStorage.setItem('customerHref', data.href)

        delete data.customerData
        let loginExtends = this.GetLoginExtends()
        loginExtends[CurrentCountry()] = data

        this.SaveLoginExtends(JSON.stringify(loginExtends))

        runInAction(() => {
            this.user = data
        })
    }

    GetLoginExtends() {
        const defaultObjects = {
            [CurrentCountry()]: null
        }

        return localStorage.getItem(this.storeKey)
            ? JSON.parse(localStorage.getItem(this.storeKey))
            : defaultObjects
    }

    GetLoginExtend(key) {
        const loginExtends = this.GetLoginExtends()
        return loginExtends[StoreCountry.CountryLowerCase()]
            ? loginExtends[StoreCountry.CountryLowerCase()][key] || null
            : null
    }

    SaveLoginExtends(data) {
        localStorage.setItem(this.storeKey, data)
    }

    SetLoginExtend(key, value) {
        const loginExtends = this.GetLoginExtends()
        if (loginExtends[CurrentCountry()]) {
            loginExtends[CurrentCountry()][key] = value
        }

        this.SaveLoginExtends(JSON.stringify(loginExtends))
    }

    ShouldRestore() {
        return new Promise((resolve, reject) => {
            if (this.GetLoginExtends()[CurrentCountry()]) {
                const canRestore = this.GetLoginExtend('token')
                if (canRestore) {
                    resolve(true)
                }
                this.ResetSessionStorage()
                reject({
                    message: 'no-token'
                })
            } else {
                logn('NO RESTORE, REMOVING SESSION STORAGE')
                this.ResetSessionStorage()
                reject({
                    message: 'no-token'
                })
            }
        })
    }

    FetchAll() {
        this.users = []
        return new Promise((resolve, reject) => {
            let loginExtends = localStorage.getItem(this.storeKey)
            if (loginExtends) {
                loginExtends = JSON.parse(loginExtends)
                if (loginExtends[CurrentCountry()] && loginExtends.length > 0) {
                    loginExtends[CurrentCountry()].map((v, k) => {
                        this.users.push(v)
                    })
                } else {
                    reject(this.users)
                }
            }
        })
    }

    GetStoredSession() {
        return new Promise((resolve, reject) => {
            let loginExtends = localStorage.getItem(this.storeKey)
            if (loginExtends) {
                loginExtends = JSON.parse(loginExtends)
                resolve(loginExtends[CurrentCountry()])
            }

            reject(null)
        })
    }

    RemoveSession(force = false) {
        if (this.user || force === true) {
            const clearData = resolve => {
                let stored = this.GetLoginExtends()
                if (stored[CurrentCountry()]) {
                    delete stored[CurrentCountry()]
                    this.SaveLoginExtends(JSON.stringify(stored))
                }
                logn('LOGOUT, REMOVING SESSION STORAGE')
                this.ResetSessionStorage()
                runInAction(() => {
                    this.user = null
                })
                if (resolve) {
                    resolve()
                }
            }

            if (force === false) {
                clearData()
            } else {
                return new Promise((resolve, reject) => {
                    clearData(resolve)
                })
            }
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default new SessionAuth()
