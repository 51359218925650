import { makeAutoObservable, reaction } from 'mobx';

class StoreElement {
    backdrop = {
        enabled: false,
        backgroundColor: 'rgba(0,0,0, 0.35)',
        zIndex: 10
    }
    openHeaderFilter = false
    openCountFilter = false
    shouldDisableHeaderFilter = true
    openingHeaderFilter = false
    forceBodyLoading = false

    somethingWrongModal = false
    afterCloseModal = null

    counterClosedAlert = 0
    forceCommissionTopup = false

    hideFooter = false

    mountFilter = false

    globalWidth = window.innerWidth

    constructor() {
        makeAutoObservable(this)
    }

    SetSomethingWrongModal(value, functionAfterClose) {
        this.somethingWrongModal = value
        if (value === true && typeof functionAfterClose === 'function') {
            this.afterCloseModal = functionAfterClose
        }
        if (value === false) {
            if (this.afterCloseModal) {
                this.afterCloseModal()
            }
        }
    }

    IsSomethingWrong() {
        return this.somethingWrongModal
    }

    makeBodyBlur() {
        document.body.classList.add('blur')
        document.body.style.overflowY = 'hidden'

    }

    removeBlur() {
        document.body.classList.remove('blur')
        document.body.style.overflowY = 'auto'
    }
}

export const storeElement = new StoreElement()

reaction(
    () => storeElement.forceBodyLoading,
    isLoading => {
        if (isLoading === true) {
            storeElement.makeBodyBlur()
        } else {
            storeElement.removeBlur()
        }
    }
)

reaction(
    () => storeElement.hideFooter,
    isHide => {
        if(isHide) {
            document.body.style.position = 'fixed'
        } else {
            document.body.style.position = 'relative'
        }
    }
)