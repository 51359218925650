import { useEffect } from 'react'
import { reaction } from 'mobx'
import { shopStore, defaultShopStore, storeManager } from '@Stores/MainStore'
import { isNothing } from '@Utils/Utils'
import { logn, loge } from '@Utils/PikaLog'
import { findString } from '@Utils/String'
import { storeProduct } from '@Stores/StoreProduct'
import StoreAuth from '@Stores/User/StoreAuth'
import StoreUser from '@Stores/User/StoreUser'
import { Country } from '@Configs/Country'
import Raven from '@Raven'

export const useProductSource = () => {
    useEffect(() => {
        reaction(
            () => StoreAuth.isInitilized,
            (init, pInit, rAuth) => {
                if (init === true && (isNothing(pInit) || pInit === false)) {
                    if(StoreAuth.isAuthorized) startProductAPI()

                    reaction(
                        () => storeProduct.isInitialized,
                        (init, _, reaction) => {
                            if (init) {
                                if (StoreAuth.isAuthorized) {
                                    if (
                                        findString(
                                            window.location.pathname,
                                            'payment-response'
                                        )
                                    ) {
                                        storeManager.isCartRestoredDone = true
                                    }
                                }
                                reaction.dispose()
                            }
                        }
                    )

                    rAuth.dispose()
                }
            },
            { fireImmediately: true }
        )
    }, [])

    const mappingToLegacyResponse = response => {
        const { items = [] } = response
        const categories = null

        // const categories = items
        //     .reduce((list, r) => [...list, ...r.categories], [])
        //     .reduce(
        //         (list, c) =>
        //             list.findIndex(({ id }) => id === c.id) > -1
        //                 ? list
        //                 : [...list, c],
        //         []
        //     )

        const legacyResponse = {
            status: 'success',
            message: '',
            data: items,
            categories,
            starterKits: [],
            hot: [],
            renewal: [],
            top10: [],
            trend_price: []
        }

        return legacyResponse
    }

    const startProductAPI = () => {
        const status = StoreUser.getShortStatus()
        const allow = 'shop'
        Raven.getProductSourceV2({ allow, status })
            .then(response => {
                const legacyResponse = mappingToLegacyResponse(response)
                processProductSource(legacyResponse)
                logn(
                    '✅ 🐉 Successfully load product (v2) source from database.'
                )
            })
            .catch(error => {
                loge(
                    '❌ 🐉 Failed to connect our product (v2) source, please check getProductSourceV2 API.',
                    error
                )
            })
    }

    const processProductSource = response => {
        const { categories, top10, hot, data } = response

        if (hot !== undefined) {
            shopStore.hotProduct = hot
        }

        const productData = data

        shopStore.top10 = top10

        productData.map(item => {
            storeProduct.setProduct(item.item_code, item)
        })

        // storeProduct.categories = categories
        storeProduct.shop = data

        logn('📦 Products data has been completed generate.')

        // * mark as done
        storeProduct.isInitialized = true
        // * support legacy product source code
        setShoppingProductSource(data)
    }

    const setShoppingProductSource = data => {
        data.map(item => {
            item.stock = item.qty
            item.image = item.image_url
            item.video = item.video_url
            item.member_price = item.price
            item.qty = 0
            if (item.image === '') {
                item.image =
                    'https://member-kr2.unicity.com/unifoapi/assets/false/JPN/none.jpg'
            }
        })

        defaultShopStore.productDataSource = data
        shopStore.productDataSource = data
        shopStore.productDataNew = data
    }
}
