import Raven from '@Raven'
import StoreMenu from '@Stores/StoreMenu'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import StoreUser from './StoreUser'
import { updateBalance } from '@Services/APIs/APIBalance'
import SessionAuth from './SessionAuth'
import { ACL, AllowList, inArray } from '@Components/config'
import { get } from 'lodash'
import { storeEasyShop } from '@Stores/StoreEasyShop'
import { excludeOf, isSomething, someOf } from '@Components/utils/Utils'
import { getNativeName, isCountryVietnam } from '@Components/GlobalHelpers'
import { logn } from '@Components/utils/PikaLog'
import { store } from '@Stores/MainStore'
import { Country } from '@Configs/Country'
import { StorageExtend } from '@Utils/StorageManager'
import { AddToBreadCrumb } from '@Components/genealogy/GenealogyHelpers'
import { storeNotification } from '@Stores/StoreNotification'
import { CodeSandboxCircleFilled } from '@ant-design/icons'

const { Code3 } = Country
class StoreAuth {
    id = null
    token = null
    tokenExpire = null
    href = null
    whoami = null
    acl = null
    country = Country.path
    allowCredit = null
    userRank = null
    userStatus = null

    isDummyUser = false
    isCPWE = false

    loginType = ''

    isInitilized = false
    isAuthorized = false

    isInitilizedAutoLogin = false
    autoLoginStatus = ''

    ACLFile = ''

    hasPendingUploadProfile = false
    imagePending = null

    iconUpload = false

    lineUserId = null
    canConnectWithLine = false

    restored = false

    loginRetried = false

    constructor() {
        makeAutoObservable(this)

        reaction(
            () => this.restored,
            isRestored => {
                if (isRestored === true) {
                    this.RefreshToken(this.id, sessionStorage.getItem('customerToken'), Code3[Country.path], false)
                }
            }
        )
    }

    /**
     * @param {'normal'|'auto'} type
     */
    setLoginType(type) {
        if (excludeOf(type, ['normal', 'auto'])) {
            type = 'normal'
        }
        this.loginType = type
    }

    setLineUserId(value) {
        this.lineUserId = value
    }

    GetLineUserId() {
        return this.lineUserId
    }

    setLoginToNormal() {
        this.setLoginType('normal')
    }

    setLoginToAuto() {
        this.setLoginType('auto')
    }

    get isNormal() {
        return this.loginType === 'normal'
    }

    get isAutoLogin() {
        return this.loginType === 'auto'
    }

    HasPendingUploadProfile() {
        return this.hasPendingUploadProfile
    }

    setPendingUploadProfile(value) {
        this.hasPendingUploadProfile = value
    }

    setPendingImage(value) {
        this.imagePending = value
    }

    ImagePending() {
        return this.imagePending
    }

    setIconUpload(value) {
        this.iconUpload = value
    }

    IconUpload() {
        return this.iconUpload
    }

    /**
     *
     * @param {'success'|'failed'} status
     */
    setAutoLoginStatus(status) {
        if (excludeOf(status, ['success', 'failed'])) {
            status = 'failed'
        }

        if (status === 'success') {
            this.isInitilized = true
            this.isInitilizedAutoLogin = true
        }
        this.autoLoginStatus = status
    }

    get isAutoLoginSuccess() {
        return this.autoLoginStatus === 'success'
    }

    get isAutoLoginFailed() {
        return this.autoLoginStatus === 'failed'
    }

    async Login(customerId, customerPassword, timeout) {
        return await new Promise((resolve, reject) => {
            Raven.login(customerId, customerPassword, timeout)
                .then(response => {
                    if (customerId === '2970466') {
                        localStorage.setItem('dummy-user', '2970466')
                    }
                    this.InitUser(response)
                    resolve(response)
                })
                .catch(response => {
                    let errorResult = null
                    if (response === undefined) {
                        errorResult = 'Service Unavailable'
                    } else {
                        errorResult = response.error || response.message
                    }
                    reject({
                        pass: false,
                        error: errorResult,
                        isTimeout: response.error.isTimeout
                    })
                })
        })
    }

    Logout() {
        this.user = null
        SessionAuth.RemoveSession()
    }

    SetAcl(type, status) {
        let acl = {
            country: Country.path
        }

        Object.keys(ACL).map((v, k) => {
            if (ACL[v].api.type === type && ACL[v].api.status === status) {
                if (ACL[v].allowed === false) {
                    return false
                }

                acl = ACL[v]
                acl.status = v
                acl.allowList = AllowList[ACL[v].allowed]
            }
        })

        let aclFile = 'AclPublic'
        if (inArray(acl.allowed, ['full', `${Country.path}Full`, 'japanStatusH', 'philippinesPC'])) {
            aclFile = 'AclFull'
        } else if (acl.allowed === 'profile') {
            aclFile = 'AclProfile'
        } else if (acl.allowed === 'express') {
            aclFile = 'AclPublic'
        } else {
            aclFile = 'AclPublic'
        }

        this.ACLFile = aclFile

        return acl
    }

    RefreshToken(customerId, customerToken = sessionStorage.getItem('customerToken'), country3Alpha, usePromise = true) {
        let cId = customerId
        if (localStorage.getItem('dummy-user')) {
            cId = localStorage.getItem('dummy-user')
        }
        
        if (usePromise) {
            return new Promise((resolve, reject) => {
                Raven.getMenuWithLogin(cId, country3Alpha, customerToken)
                    .then(response => {
                        storeNotification.setRawContent(response.popup)
                        this.InitUser(response, resolve, reject)
                    })
                    .catch(error => {
                        console.log('error', error)
                        reject(error)
                    })
            })
        } else {
            Raven.getMenuWithLogin(cId, country3Alpha, customerToken)
                .then(response => {
                    // sessionStorage.setItem('tokenExpire', JSON.stringify(SessionAuth.user.tokenExpire))
                    storeNotification.setRawContent(response.popup)
                    this.InitUser(response)
                })
                .catch(error => {
                    console.log('error', error)
                })
        }
    }

    Href(full = true) {
        if (full === true) {
            return this.href
        } else {
            if (!this.href) {
                return null
            }
            return this.href.split('/').pop()
        }
    }

    GetToken() {
        return SessionAuth.GetLoginExtend('token')
    }

    GetId() {
        return SessionAuth.GetLoginExtend('id')
    }

    Reset() {
        this.id = null
        this.token = null
        this.tokenExpire = null
        this.href = null
        this.isAuthorized = false
        this.isDummyUser = false
        this.whoami = null
        this.acl = null
        this.country = null
    }

    Restore(data) {
        
        return new Promise((resolve, reject) => {
            if (data) {
                data.then(response => {
                    
                    if (response) {
                        this.token = response.token
                        this.tokenExpire = response.tokenExpire
                        this.menu = response.menu
                        this.id = localStorage.getItem('dummy-user') ? localStorage.getItem('dummy-user') : response.id
                        this.href = response.href
                        this.country = response.country

                        if (sessionStorage.getItem('onself-response')) {
                            const restoreObjects = {
                                onself: sessionStorage.getItem('onself-response') ? JSON.parse(sessionStorage.getItem('onself-response')) : null,
                                menu: sessionStorage.getItem('menu') ? JSON.parse(sessionStorage.getItem('menu')) : null
                            }

                            this.restored = true
                            this.InitUser(restoreObjects)
                        } else {
                           
                            this.RefreshToken(this.id, response.token, Code3[Country.path])
                                .then(responseRefreshToken => {
                                    sessionStorage.setItem('onself-response', JSON.stringify(responseRefreshToken.onself))
                                    resolve(responseRefreshToken)
                                })
                                .catch(error => {
                                    reject(error)
                                })
                        }
                    } else {
                        console.error('no response', response)
                        reject()
                    }
                })
            } else {
                reject()
            }
        })
    }

    async InitUser(response, resolve = undefined, reject = undefined, autoLogin = false) {
        try {
            if (response && response.onself === undefined) {
                console.log('LOGIN PROCESSING')
                const userInit = {
                    id: this.id,
                    token: this.token,
                    menu: this.menu,
                    href: this.href,
                    country: Country.path
                }

                userInit.id = response.baId
                userInit.href = response.href

                Object.keys(sessionStorage).map((v, k) => {
                    if (/^menuGroup+?/.test(v)) {
                        sessionStorage.removeItem(v)
                    }
                })
                Object.keys(localStorage).map((v, k) => {
                    if (/^dashboard+?/.test(v)) {
                        localStorage.removeItem(v)
                    }
                })

                const loginData = get(response, 'loginData', null)
                if (loginData) {
                    userInit.token = loginData.token
                } else {
                    if (response.checkToken) {
                        userInit.token = response.checkToken.token
                    }
                }
                if (store.isWebview) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ data: { token: userInit.token, profile: null }, targetFunc: 'session-login' }))
                }

                runInAction(() => {
                    this.isAuthorized = true
                    this.isInitilized = true
                })

                this.SaveUser(userInit)

                return response
            } else if (response && response.onself) {
                console.log('LOGIN 있음')
                const userInit = {
                    id: this.id,
                    token: this.token,
                    menu: this.menu,
                    href: this.href,
                    country: Country.path
                }

                Object.keys(sessionStorage).map((v, k) => {
                    if (/^menuGroup+?/.test(v)) {
                        sessionStorage.removeItem(v)
                    }
                })
                Object.keys(localStorage).map((v, k) => {
                    if (/^dashboard+?/.test(v)) {
                        localStorage.removeItem(v)
                    }
                })

                const profile = get(response, 'onself.ushop.profile', null)

                if (profile) {
                    this.userRank = response.userRank
                    this.userStatus = response.userStatus

                    const customerID = profile.id.unicity
                    if (isSomething(customerID)) {
                        const sizes = get(profile, 'profilePicture.sizes', [])
                        const avatar = get(
                            sizes.find(item => item.size === '500x500'),
                            'media',
                            ''
                        )

                        AddToBreadCrumb(profile)

                        StorageExtend.setToCountry(
                            {
                                avatar: avatar,
                                id: customerID,
                                name_english: getNativeName(get(profile, 'humanName'), 'fullName'),
                                name_native: getNativeName(get(profile, 'humanName'), 'nativeName'),
                                timestamp: new Date().getTime()
                            },
                            `login-recovery.${customerID}`
                        )
                    }

                    let type = get(profile, 'type', null)
                    if (type) {
                        if (type === 'Employee') {
                            if (get(profile, 'employmentDetails.employerName', null) !== 'Unicity') {
                                type = get(profile, 'employmentDetails.employerName', '')
                            }
                        }
                    }

                    const aclSetup = this.SetAcl(profile.type, profile.status)
                    runInAction(() => {
                        this.acl = aclSetup
                    })

                    profile.acl = aclSetup
                }

                // if (response.token !== undefined && response.customer.href !== undefined) {
                //     store.facebookConnected = true
                // }

                const loginData = get(response, 'loginData', null)
                if (loginData) {
                    userInit.token = loginData.token
                } else {
                    if (response.checkToken) {
                        userInit.token = response.checkToken.token
                    }
                }
                if (store.isWebview) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ data: { token: userInit.token, profile: profile }, targetFunc: 'session-login' }))
                }

                userInit.id = profile.id.unicity
                userInit.href = profile.href

                runInAction(() => {
                    this.token = userInit.token
                    this.menu = userInit.menu
                    this.id = userInit.id
                    this.href = userInit.href
                    this.country = Country.path
                })

                if (autoLogin) {
                    this.href = profile.href
                }
                userInit.customerData = profile

                // SessionAuth.SetTokenLeft(userInit.tokenExpire)

                this.SaveUser(userInit)
                StoreUser.setUserData(response.onself.ushop)
                StoreMenu.setMenu(response.menu)
                this.allowCredit = response.allowCredit

                if (response.quotes) {
                    StoreMenu.setQuotes(response.quotes)
                }

                storeNotification.content = response.popup
                storeNotification.isLoaded = true

                runInAction(() => {
                    this.isAuthorized = true
                    this.isInitilized = true
                })

                if (resolve !== undefined) {
                    resolve(response)
                }
            } else {
                if (reject !== undefined) {
                    reject()
                }
            }
        } catch (e) {
            if (reject !== undefined) {
                reject(e)
            }
            console.error(e)
        }
    }

    SaveUser = session => {
        SessionAuth.SaveSession(session)
    }

    ShortStatus() {
        return this.userStatus
    }

    StatusE() {
        return this.ShortStatus() === 'E'
    }

    StatusL() {
        return this.ShortStatus() === 'L'
    }

    IsShowPv() {
        if (this.isAuthorized) {
            return someOf(this.ShortStatus(), ['A', 'B'])
        } else {
            if (storeEasyShop.isEnabled) {
                return storeEasyShop.easyChoice === 1
            } else {
                return false
            }
        }
    }

    get PriceLevel() {
        return get(this.acl, 'api.priceLevel', null)
    }

    /** An atonym version of `isAuthorized`.
     *
     * Return `false` if any customer already logged in.
     */
    get isAnonymous() {
        return this.isAuthorized === false
    }

    isMockUp() {
        return localStorage.getItem('dummy-user')
    }

    AutoLogin(customerID, customerToken, countryCode3) {
        logn('🔐 🤖 Auto-login has been detect.')

        // return new Promise((resolve, reject) => {
        //     Raven.getMenuWithLogin(customerID, countryCode3, customerToken)
        //         .then(response => {
        //             if (customerID === '2970466') localStorage.setItem('dummy-user', customerID)
        //             this.id = customerID
        //             this.token = customerToken
        //             this.menu = response.menu
        //             this.InitUser(response, resolve, reject, true)
        //         })
        //         .catch(error => {
        //             reject(error)
        //         })
        // })
    }
}

export default new StoreAuth()
