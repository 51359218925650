import { language } from '@Language';
import { isEqualText } from '@Utils/String';
import { isNothing, isSomething } from '@Utils/Utils';
import { makeAutoObservable } from 'mobx';
import get from 'lodash/get';
import set from 'lodash/set';
import remove from 'lodash/remove';

class StoreNotification {
    isLoaded = false    // is popup data ready to use
    isShow = false
    isDontShow = false
    isRunning = false   // when running is true, start the popup queue
    rawContent = []     // when assign things from api, use rawContent
    content = []        // this one is proccessed content, should not be assigned by api

    constructor() {
        makeAutoObservable(this)
    }

    setRawContent(content) {
        if (isSomething(content)) {
            this.rawContent = content
            this.isLoaded = true
        } else {
            this.rawContent = []
        }
    }

    getContent() {
        return this.content
    }

    setContent(legitContent) {
        this.content = legitContent
    }

    hasContent() {
        return isSomething(this.content)
    }

    hasContentLength() {
        return this.hasContent() && this.content.length > 0
    }

    removeContentAt(contentID) {
        remove(this.content, item => item.id === contentID)
    }

    getContentFrom(index) {
        if (this.content[index]) {
            return this.content[index]
        } else {
            return {}
        }
    }

    isContentExist(index) {
        return isSomething(this.getContentFrom(index))
    }

    getVisibleFrom(index) {
        if (this.isContentExist(index)) {
            return this.getContentFrom(index).visible
        } else {
            return false
        }
    }

    setVisibleTo(index, value) {
        if (this.isContentExist(index)) {
            const sessionView = get(this.getContentFrom(index), `sessionView.${language.current}`, 0)
            if (sessionView > 0) {
                set(this.content, `[${index}].sessionView.${language.current}`, sessionView + 1)
            } else {
                set(this.content, `[${index}].sessionView.${language.current}`, 1)
            }
            set(this.content, `[${index}].visible`, value)
        }
    }

    isShowOnce(index) {
        return isEqualText(this.getContentFrom(index).isShowOnce, 'showOnlyOnce')
    }

    isShowOnceEverySession(index) {
        return isEqualText(this.getContentFrom(index).isShowOnce, 'showOnceEverySession')
    }

    nextFrom(index) {
        const nextIndex = index + 1
        if (this.isContentExist(nextIndex)) {
            const content = this.getContentFrom(nextIndex)
            if (this.isShowOnceEverySession(nextIndex)) {
                // use sessionView to control the display, don't just use true/false
                // sessionView also separated by languages 
                const sessionView = get(content, `sessionView.${language.current}`, 0)
                if (isNothing(sessionView) || sessionView === 0) {
                    this.setVisibleTo(nextIndex, true)
                }
            } else {
                this.setVisibleTo(nextIndex, true)
            }
        } else {
            this.stop()
        }
    }

    start() {
        this.isRunning = true
    }

    stop() {
        this.isRunning = false
    }
}

export const storeNotification = new StoreNotification()