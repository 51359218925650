import { makeAutoObservable } from 'mobx';
import { checkOutStore } from '../../stores/MainStore';

class ShoppingClass {

    constructor() {
        makeAutoObservable(this)
    }

    isPreferredCustomer() {
        let isPreferredCustomer = false

        try {
            let cType = checkOutStore.dToken.acl.api.type
            let cStatus = checkOutStore.dToken.acl.api.status

            isPreferredCustomer = (cType === 'PreferredCustomer' && cStatus === 'Active')

        } catch (e) {

        }

        return isPreferredCustomer
    }

    isFamilyCustomer() {
        let isFamilyCustomer = false

        try {
            let cType = checkOutStore.dToken.acl.api.type
            let cStatus = checkOutStore.dToken.acl.api.status

            isFamilyCustomer = (cType === 'Family' && cStatus === 'Active')

        } catch (e) {

        }

        return isFamilyCustomer
    }
}

export const shoppingClass = new ShoppingClass()