const constants_kr = {
    KR: {
        '2018accum': '2018 아쿰',
        '2019_maldives': '',
        '2019_orlando': '',
        '2c2p_err_payment_fail': '',
        about_us: '회사 소개',
        above_250k: '250,000페소 초과',
        account_not_related:
            '귀하의 페이스북 계정은 유니시티 계정과 관련이 없습니다.',
        accumulated: '누적',
        active: '활동중',
        active_legs: '액티브 레그',
        add: '',
        added: '',
        added_to_cart: '',
        adding_log: '로그 추가',
        additional_filters: '추가 필터',
        additional_info: '추가 정보',
        address: '주소',
        address1: '주소 1',
        address1_is_empty: '',
        address2: '주소 2',
        address2_is_empty: '',
        address2_warranty: '',
        addressbook_add: '새 주소',
        addressbook_add_first: '첫 번째 주소 추가',
        addressbook_add_new: '새 주소 추가',
        addressbook_edit: '주소 편집',
        addressbook_main_address: '이것은 당신의 주요 주소입니다.',
        address_book: '주소록',
        address_japanese: '',
        address_line1: '주소 1',
        address_line1_au: '',
        address_line1_hk: '',
        address_line1_id: '',
        address_line1_jp: '',
        address_line1_my: '',
        address_line1_nz: '',
        address_line1_ph: '',
        address_line1_sg: '',
        address_line1_th: '',
        address_line1_th1: '주소 - 도로명',
        address_line1_tw: '',
        address_line2: '주소 2',
        address_line2_au: '',
        address_line2_hk: '',
        address_line2_jp: '',
        address_line2_my: '',
        address_line2_nz: '',
        address_line2_ph: '',
        address_line2_sg: '',
        address_line2_th1: '시/군/구 - 읍/면/동',
        address_line2_th2: '구 - 시/군',
        address_line2_tw: '',
        address_native: '[:국가_코드:] 주소',
        address_native_1: '[:국가_코드:] 주소 1',
        address_native_2: '[:국가_코드:] 주소 2',
        address_one: '주소 1',
        address_one_au: '',
        address_one_id: '주소 1',
        address_one_jp: '',
        address_one_my: '',
        address_one_nz: '',
        address_one_ph: '',
        address_one_sg: '',
        address_one_warranty: '',
        address_three: '',
        address_two: '주소 2',
        address_two_au: '',
        address_two_id: '주소 2',
        address_two_jp: '',
        address_two_my: '',
        address_two_nz: '',
        address_two_ph: '',
        address_two_sg: '',
        add_autoship: '오토십 (AutoShip) 추가',
        add_more_products: '더 많은 제품 추가',
        add_new_profile: '새 프로필 추가',
        add_products: '새 프로필 추가',
        add_the_key_that_you_use_in_react: '',
        add_to_cart: '장바구니에 담기',
        afp: 'AFP',
        age_over: '귀하는 반드시 [:나이:]세 이상이어야 합니다.',
        agree_company: '나는 회사의 [:동의할 방침:]에 동의합니다.',
        al: 'AL',
        all: '전체',
        all_product: '전체 제품',
        all_products: '모든 제품',
        amount: '금액',
        amphure: '읍/면/동/지구',
        announcements: '공지사항',
        annual_income_above_250k:
            '총 연간 소득이 25만 페소 초과 300만 페소 이하인 경우, 원천세 8%가 징수됩니다.',
        annual_income_above_300k:
            '총 연간 소득이 300만 페소 초과인 경우, 원천세 10%가 징수됩니다.',
        annual_income_above_300m: '',
        anti_aging: '노화 방지',
        application_number: '신청번호',
        apply: '',
        app_store: '앱스토어',
        april: '사월',
        apt: 'AFP',
        apt1: 'AFP',
        apv: 'APV',
        aramex: 'Aramex',
        area: '시, 군, 구',
        are_you_sure: '',
        ar_higher: '',
        ar_lower: '',
        as: 'AS',
        asc: '동무',
        associate: '동무',
        august: '팔월',
        aus: '',
        authorization: '승인서',
        automatic_40_gross_income: '총소득의 40% 자동 공제.',
        autoship: '오토십 (Autoship)',
        autoship_address: '오토십 (Autoship) 주소',
        autoship_payment_details: '오토십 (Autoship) 지불 세부내역',
        autoship_pv: '오토십 (Autoship) CV',
        autoship_pv_jp: '오토십 (Autoship) PSV',
        autoship_summary: '오토십 (Autoship) 개요',
        auto_ship_info: '오토십 (Autoship)',
        au_login_no: '아니요, 일반 가격으로 구입하고 싶습니다.',
        au_login_no_referral_id: '아니요, 그러나 추천인이 있습니다.',
        au_login_referral:
            '추천인의 성명, 이메일 주소 또는 전화번호를 가지고 있습니다.',
        au_login_yes: '예, 디스트리뷰터 번호가 있습니다.',
        availability: '재고상태',
        award_plan_summary: '',
        ayutthaya_dsc: '',
        a_to_z: 'A ~ Z',
        ba: '유통 업체',
        back: '뒤로',
        backorder: '백오더',
        backorder_confirm: '주문을 처리하시겠습니까?',
        back_err_no: '주민등록증 뒷면에 표시된 번호가 유효하지 않습니다.',
        back_order: '백오더',
        back_to_cart: '장바구니로 돌아가기',
        back_to_previous_step: '이전 단계로 돌아가기',
        back_to_shopping: '쇼핑으로 돌아가기',
        baid: 'BA ID',
        balance: '잔액',
        bankwire: '은행 송금',
        bank_acc_name: '은행 계좌명',
        bank_code: '은행 코드',
        bank_img: '은행 사진',
        bank_info: '은행 계좌',
        bank_name: '은행명',
        bank_no: '은행 계좌번호',
        bank_remark:
            '※ 양수인 성명 앞에 귀하의 회원번호를 반드시 기입 또는 입력하십시오.',
        bank_type: '계좌 유형',
        base_rank: '기본 등급',
        ba_address: 'BA 주소',
        be: 'Be',
        'be part of the forefront of metabolic health.\n discover with us new ingredients, new formulas, and new products which positively impact your health and wellness. achieve more, and enjoy life to its fullest.':
            '',
        begin_enrollment: '가입절차 시작',
        below_250k: '250,000페소 이하',
        bernard_test: '',
        bestselling: '베스트 상푸',
        best_selling_products: '베스트 상품',
        be_skin_care_products_be: 'BE™ 스킨케어 제품 BE™',
        bha: '바레인',
        big_address_is_empty: '',
        billing_address: '청구서 발송지',
        bill_to: '청구서 수취인',
        bioslifeform: '',
        bioslifeinfo: '',
        bioslifewarranty: '',
        bios_life: 'BIOS LIFE™',
        bios_life_air: '바이오스 라이프 에어',
        bios_life_slim_marketing_materials: '바이오스 라이프 슬림 마케팅 자료',
        bir: '',
        birthday: '생년월일',
        birthday_id: '생년월일',
        bir_address_barangay: '',
        bir_address_building: '',
        bir_address_city: '',
        bir_address_lot: '',
        bir_address_province: '',
        bir_address_street: '',
        bir_address_subdivision: '',
        bir_address_town: '',
        bir_address_unit: '',
        bir_address_zip: '',
        bir_country: '',
        bir_error_invalid_tin: '',
        bir_image_upload_text: '',
        bir_post_success: '',
        bir_process_text: '',
        bir_rdo_href: '',
        bir_rdo_label: '',
        bir_rdo_placeholder: '',
        bir_tax_instructions_1: '',
        bir_tax_instructions_2: '',
        bir_tax_instructions_3: '',
        bir_tax_instructions_info: '',
        bir_tin_placeholder: '',
        bir_update_success: '',
        bir_update_text: '',
        body_care: '바디 케어',
        bone_and_joint: '뼈와 관절',
        bonus_type_cash_out: '',
        bonus_type_earned: '',
        bookmark: '',
        bookmarked: '',
        bookmarks: '북마크',
        bookmark_add: '',
        bookmark_remove: '',
        branch_address: '지점 주소',
        branch_code: '지점 코드',
        branch_name: '지점명',
        breadcrumb: '',
        bring_forward: '',
        brochure: '브로슈어',
        browse_your_downline: '나의 다운라인 확인하기',
        btn_back: '',
        burirum_dsc: '',
        businesstools: '비즈니스 도구',
        business_tax_type: '영업세 유형을 기재하십시오.',
        by_best_selling: '많이 팔리는 순서',
        by_name: '이름',
        by_price: '가격',
        by_pv: 'CV',
        b_bn_id_card_err_invalid: '',
        call_me_when: '',
        cancel: '취소',
        cancel_registration: '',
        cancel_registrration_dsc: '',
        card_cvv: '카드 CVV 번호',
        card_name: '카드 소지자 성명',
        card_number: '신용카드 번호',
        card_view: '카드보기',
        cart: '장바구니',
        cartname: '이름 :',
        cash: '현금',
        cashedout: '현금',
        categories: '카테고리',
        ccm: 'CCM (체어맨스 클럽 멤버)',
        chachoengsao_dsc: '',
        chaingmai_dsc: '',
        chair: '의자',
        change: '변경하기',
        changepasswordhint: '비밀번호를 변경하려면 다시 로그인을 해야 합니다.',
        change_country: 'Change Country',
        change_email: '이메일 주소가 정상적으로 변경되었습니다.',
        change_information: '정보 변경',
        change_password: '비밀번호 변경',
        change_password_description:
            '강력한 암호는 디지털 생활의 핵심입니다. ** 강력한 비밀번호를 생성 **하여 정보를 보호하세요.',
        change_profile: '',
        change_profile_picture: '',
        chanthaburi_dsc: '',
        chat_with_us: '우리와 채팅',
        'check real-time organization structure': '',
        checkingpersonal: '개인 수표',
        checking_personal: '개인 수표',
        checking_stock: '재고 확인 중',
        checkout: '결제',
        checkout_as_guest: '',
        chinese: '',
        choose_bankname: '은행 이름을 선택하십시오.',
        choose_country: '국가를 선택하십시오',
        choose_country_landing: 'CHOOSE YOUR COUNTRY',
        choose_district: '지구를 선택하십시오',
        choose_file: '파일 선택하기...',
        choose_products: '상품을 선택하세요.',
        choose_province: '주 (州) 또는 광역시를 선택하세요.',
        choose_purchase_location: '',
        choose_shipping_method: '배송 방법 선택',
        city: '시',
        city_district: '구 - 시/군/구',
        city_my: '',
        city_ph: '',
        city_placeholder_au: '',
        city_placeholder_nz: '',
        claim_your_tshirt: '무료 티셔츠를 받으십시오!',
        cleanse: '클렌징',
        clear: '지우기',
        clear_all: '모두 지우기',
        clear_cart: '장바구니 지우기',
        'click here': '',
        click_here: '이곳을 클릭하십시오.',
        click_here_if_not_receive_sms:
            'SMS 메시지를 받지 못한 경우, 여기를 클릭하십시오.',
        click_here_if_not_receive_sms_2: 'SMS 메시지를 받지 못한 경우',
        close: '휴무',
        closing: '',
        coapplicant: '공동 신청인',
        coapplicant_type: '공동 신청인 유형',
        code: '코드',
        comment: '코멘트',
        commission: '커미션',
        commission_month: '커미션월',
        commission_not_found1: '파일을 다운로드 할 준비가 되지 않았습니다.',
        commission_not_found2:
            '도움이 필요할 경우, 고객 서비스 센터에 문의하십시오.',
        company: '직장',
        company_name: '회사명',
        compensation: '보수',
        complete: '완료',
        compressed: '압축',
        compressedtv: '압축 TV',
        compressed_tv: '[기준 시점] 기준 압축된 TV',
        configs: '구성',
        confirm: '확인',
        confirmation_desc: '',
        confirmation_email_checkbox: '',
        confirm_autoship: '오토십 (AutoShip) 확인',
        confirm_change_registered_address:
            '등록된 회사 주소가 (등록된 주소가 다른 경우, 필수 입력사항임)로 변경되었음을 확인합니다.',
        confirm_duplicated_order_pay: '다시 주문',
        confirm_request_otp: 'OTP 코드 요청',
        connect_with_facebook: '페이스북과 연동하기',
        connect_with_us: '당사와 연결이 가능합니다.',
        contact: '연락처',
        contactus: '연락처',
        contact_address_ortigas: '',
        contact_information: '연락처',
        contact_shipping: '',
        contact_us: '연락처',
        continue: '계속',
        continue_to_payment: '',
        conveniencestore: '',
        copied: '복사됨',
        copied_referral_code: '',
        copy_to_clipboard: '클립보드에 복사하기',
        counter: '카운터',
        country: '국가',
        country_idn: '',
        country_is_empty: '',
        country_jpn: '',
        country_my: '',
        country_ph: '',
        country_sg: '',
        country_th: '',
        country_vn: '',
        cover_award_en: '',
        cover_award_native: '',
        cover_program_promotion_en: '',
        cover_program_promotion_native: '',
        co_app: '공동 신청인',
        co_info: '공동 신청인',
        cpd_address1_exceed_max_length: '',
        cpd_address2_exceed_max_length: '',
        cpd_ba_not_found: '',
        cpd_ba_only_digit: '',
        cpd_coutry_mustbe_sg: '',
        cpd_coutry_mustbe_th: '',
        cpd_email_contain_space: '',
        cpd_email_invalid_domain: '',
        cpd_invalid_address1: '',
        cpd_invalid_address1_end_with_space: '',
        cpd_invalid_address1_start_with_space: '',
        cpd_invalid_address2: '',
        cpd_invalid_address2_end_with_space: '',
        cpd_invalid_address2_start_with_space: '',
        cpd_invalid_email: '',
        cpd_invalid_name: '',
        cpd_invalid_name_end_with_space: '',
        cpd_invalid_name_start_with_space: '',
        cpd_sg_mobile_digit_8: '',
        cpd_sg_zip_digit_6: '',
        cpd_th_mobile_digit_10: '',
        cpd_th_zip_digit_5: '',
        cpv: 'CPV',
        cp_cart_customer_level: '',
        cp_cart_profile_name_empty: '',
        cp_cart_shiping_method: '',
        cp_clear_basket: '',
        cp_clear_basket_sentence: '',
        cp_duplicated_profile_name: '',
        cp_exceed_product_list: '',
        cp_exit: '',
        cp_help: '',
        cp_invalid_product_list: '',
        create_now: '새로 만들기',
        create_shopping_cart_profile: '장바구니 프로필 생성하기',
        creating_order: '주문서 작성',
        creditcard: '신용카드',
        creditcard_number: '신용카드 번호',
        creditonaccount: '',
        credit_balance: '',
        credit_balance_default_data: '',
        ct_code_bh: '바레인',
        ct_code_hk: '',
        ct_code_id: '인도네시아',
        ct_code_in: '인도',
        ct_code_jp: '일본',
        ct_code_ph: '필리핀',
        ct_code_sg: '싱가포르',
        ct_code_th: '태국',
        ct_code_tw: '',
        ct_code_us: '미국',
        ct_code_vn: '베트남',
        currency: '금액(원)',
        cur_aed: '아랍에미리트 디나르',
        cur_aus: '호주 달러',
        cur_hkd: '홍콩 달러',
        cur_idr: '인도 루피',
        cur_jpy: '일본 엔',
        cur_jyp: '',
        cur_kyat: '미얀마 차트',
        cur_myr: '말레이시아 링기트',
        cur_nzl: '뉴질랜드 달러',
        cur_php: '필리핀 페소',
        cur_sgd: '싱가포르 달러',
        cur_tak: '방글라데시 타카',
        cur_thb: '태국 바트',
        cur_twd: '대만 달러',
        cur_usd: 'USD',
        cur_vnd: '베트남 동',
        cur_บาท: '태국 바트',
        cur_円: '일본 엔',
        customer: '고객',
        'customer support': '',
        customer_address: '고객 주소',
        customer_by_customer: '고객별',
        customer_details: '',
        customer_not_found: '고객을 찾을 수 없습니다.',
        customer_service: '고객 서비스',
        dashboard: '나의실적',
        data_no_found: '데이터를 찾을 수 없습니다...',
        date: '날짜',
        date_format: '일-월-년',
        date_of_birth: '',
        date_of_purchase: '',
        date_of_purchase_warranty: '',
        days: '일',
        db_cardholder_not_found: '',
        db_creditcard_invalid: '',
        db_creditcard_not_found: '',
        db_expired_invalid: '',
        db_expired_not_found: '',
        db_invalid_transactions_type: '',
        db_methoddetails_not_found: '',
        db_securitycode_invalid: '',
        db_securitycode_not_found: '',
        deadline: '주문 기한: 2019년 10월 24일',
        december: '십이월',
        december_sem: '십이월',
        default_address: '연락 가능한 주소',
        default_rdo: '',
        delete: '삭제',
        delete_address: '주소 삭제',
        delete_address_description:
            '선택한 주소는 영구적으로 삭제됩니다. 계속하시겠습니까?',
        delete_autoship: '오토십 (AutoShip) 삭제',
        delete_autoship_description:
            '선택한 오토십 (AutoShip)이 영구적으로 삭제됩니다. 계속하시겠습니까?',
        delete_profile: '프로필 삭제',
        delete_profile_permanently: '',
        delivery: '택배',
        'delivery service': '',
        deliveryjkt: '',
        deliverysby: '',
        delivery_fee: '배송료',
        delivery_free: '무료',
        delivery_information: '',
        delivery_local_shipping: '현지 배송',
        delivery_time: '배송 소요기간',
        description_info_holiday1: '',
        description_info_holiday2: '',
        description_info_holiday3: '',
        description_info_holiday4: '',
        description_info_holiday5: '',
        description_info_holiday6: '',
        descr_view_eye: '',
        deselect_all: '모두 선택 해제',
        details: '세부사항',
        detail_label_dropdown: '',
        dia: 'PDI',
        digestive_and_cleansing: '소화 및 청소',
        digital_member_card: '디지털 회원 카드',
        digital_member_copy_clipboard: '',
        digital_member_copy_sharing: '',
        digital_member_email: '',
        digital_member_enroll: '',
        digital_member_mobile: '',
        digital_member_my_shop: '',
        digital_member_name: '',
        digital_member_number: '',
        digital_member_rank: '',
        digital_member_since: '회원 가입일',
        digital_member_sponser: '',
        digital_member_status: '',
        digital_member_title1: '',
        digital_member_title2: '',
        digital_member_title3: '',
        digital_member_title4: '',
        digits_3_minimum:
            '회원 가입자와 스폰서의 아이디는 3자리 이상이어야 합니다.',
        digits_8_minimum:
            '회원 가입자와 스폰서의 아이디는 8자리 이상이어야 합니다.',
        dig_down: '상세보기',
        dig_down_new_tab: '상세보기 (새로운 탭 열림)',
        dir: 'GD',
        disclaimer: '권리 포기 각서',
        disclaimer_enroll: '',
        discontinued: '',
        discount: '할인',
        distributor: '도매가격',
        distributor_id: '회원번호',
        district: '시/군/구',
        dl_precentage: '비중 (%)',
        dl_totalactive: '총 활성 BA',
        dl_totalba: '총 BA',
        documents: '서류',
        doc_browse: '',
        doc_message: '',
        doc_type: '',
        doc_type_enrollment_form: '',
        doc_type_etc: '',
        doc_type_termination: '',
        doesnt_have_share_cart: '',
        dont_have_account: '계정이 없습니까?',
        dont_have_product: '이 프로필에는 상품이 없습니다.',
        dont_have_shopping_cart: '아직 장바구니 프로필이 없습니다.',
        downline: '',
        download: '다운로드',
        downloads: '다운로드',
        download_on_the: '다운로드',
        download_qr_code: 'QR코드 다운로드 하기',
        dp_items_not_found: '',
        dp_method_not_found: '',
        dp_transactions_not_found: '',
        dsc: 'DSC',
        dst: '디스트리뷰터',
        d_all_confirmation_email_dear: '',
        d_all_confirmation_email_dsc_shop: '',
        d_all_confirmation_email_login_to_web: '',
        d_all_confirmation_email_order_details: '',
        d_all_confirmation_email_preheader: '',
        d_all_confirmation_email_subtitle: '',
        d_all_confirmation_email_subtitle2: '',
        d_all_confirmation_email_title: '',
        ea: '개 (개수)',
        earned: '획득',
        earnings: '',
        earnings_amount: '소득금액',
        'easy to order 365 days a year': '',
        easyship: '이지십 (EasyShip)',
        easyship_set: '이지십 (Easyship) 세트',
        easy_order_year: '',
        easy_referral_id: '',
        easy_ship: '',
        edit: '편집',
        edit_shopping_cart_profile: '장바구니 프로필 편집하기',
        'elevate yourself': '',
        email: '이메일',
        email_address: '이메일 주소',
        email_already_exists: '중복된 이메일 주소입니다.',
        email_confirmation: '',
        email_dear: '~ 귀하',
        email_dear1: '',
        email_dear2: '회원님',
        email_id: '이메일',
        email_is_empty: '',
        email_jp: '',
        email_order_details: '주문 세부사항',
        email_payment_h1: '유니시티를 이용해 주셔서 대단히 감사합니다. ',
        email_payment_preheader:
            '쇼핑해 주셔서 감사합니다! 주문 확인입니다. 필요한 모든 정보는 다음과 같습니다. 문의사항이 있을 경우, 언제든지 연락을 주시기 바랍니다! 또 뵙겠습니다!',
        email_payment_subtitle:
            '아래의 주문내용을 확인해 주시기 바랍니다. \n문의사항이 있으실 경우, 언제든지 유니시티 싱가포르로 문의해 주시기 바랍니다.\n',
        email_payment_subtitle2: '향후에도 유니시티를 애용해 주시기 바랍니다!',
        email_payment_thankyou1:
            '쇼핑해 주셔서 감사합니다! 주문 확인입니다. 필요한 모든 정보는 다음과 같습니다. 문의사항이 있을 경우, 언제든지 연락을 주시기 바랍니다! 또 뵙겠습니다!',
        email_payment_thankyou2: '또 뵙겠습니다!',
        email_ph: '',
        email_shipping_h1: '주문하신 물품은 배송 중입니다!',
        email_shipping_note_label: '납품 부서 측 메모:',
        email_shipping_preheader:
            '주문하신 물품이 발송되었습니다. 발송번호와 주문 세부사항은 다음과 같습니다. 문의사항이 있을 경우, 언제든지 연락을 주시기 바랍니다! 또 뵙겠습니다!',
        email_shipping_remark_label: '',
        email_shipping_remark_sentence:
            '토요일, 일요일, 공휴일에는 배송 서비스가 제공되지 않으며 배송 소요기간은 3~5영업일임을 알려드립니다.',
        email_shipping_see_you: '곧 뵙겠습니다!',
        email_shipping_tracking_no: '추적 번호',
        email_shipping_your_order:
            '주문하신 물품이 발송되었으며, 이 물품은 {{월}} CV에 해당합니다! 아라멕스 (Aramex) 송장번호와 주문 세부 정보는 다음과 같습니다.',
        email_warranty: '',
        email_warranty_id: '',
        employee: '종업원',
        empty_seminar_information: '',
        empty_shipping_email: '',
        empty_shipping_mobile: '',
        end_date: '종료일',
        enroll: '회원가입',
        enrolled: '가입된',
        enroller: '인롤러',
        enroller_id: '인롤러 아이디',
        enroller_id_short: '등록자 ID',
        enroller_name: '인롤러 성명',
        enroller_name_short: '등록자 이름',
        enrollment: '회원가입',
        enrollment_ba_not_found: '',
        enrollment_result: '회원가입 결과',
        enrollment_sum: '회원가입 정보 요약',
        enroll_agreement: '계약서',
        enroll_description:
            "본 신청서에 서명한 후 이를 제출하고 스타터 키트에 대한 금액을 지불함으로써, 본인은 유니시티의 디스트리뷰터 (일명, 'Associate') 가 되는 것을 신청하고 있음을 확인합니다. 본인은 유니시티가 본 신청서에 기재된 전화번호(들), 팩스 번호 및/또는 이메일 주소로 연락하는 데 동의합니다. 본인은 신청서/계약서의 약관을 읽고 이에 동의하였음을 증명합니다. 또한, 본인은 유니시티 보상플랜과 유니시티 정책 및 절차서 (본 문서에 포함되어 있으며 본 계약서의 일부분을 구성함)를 읽고 이해하였으며, 이에 동의합니다.",
        enroll_description_au:
            "본인은 유니시티 인터내셔널의 디스트리뷰터 (일명, 'Associate') 가입을 신청하고 있음을 확인합니다. 본인은 본 신청서에 서명한 후 이를 제출함으로써 이 신청절차가 완료됨에 동의하고 이를 확인합니다. 본인은 유니시티가 본 신청서에 기재된 전화번호(들), 팩스 번호 및/또는 이메일 주소로 연락하는 데 동의합니다. 본인은 신청서/유통계약서의 약관을 읽고 이에 동의하였음을 증명합니다. 또한, 본인은 유니시티 보상플랜과 유니시티 정책 및 절차서 (본 문서에 포함되어 있으며 본 계약서의 일부분을 구성함)를 읽고 이해하였으며, 이에 동의합니다.",
        enroll_description_id:
            "본인은 유니시티 인터내셔널의 디스트리뷰터 (일명, 'Associate') 지정을 신청하고 있음을 확인합니다. 본인은 본 신청서에 서명한 후 이를 제출하고 스타터 키트에 대한 금액을 지불함으로써 이 신청절차가 완료됨에 동의하고 이를 확인합니다. 본인은 유니시티가 본 신청서에 기재된 전화번호(들), 팩스 번호 및/또는 이메일 주소로 연락하는 데 동의합니다. 본인은 신청서/유통계약서의 약관을 읽고 이에 동의하였음을 증명합니다. 또한, 본인은 유니시티 보상플랜과 유니시티 정책 및 절차서 (본 문서에 포함되어 있으며 본 계약서의 일부분을 구성함)를 읽고 이해하였으며, 이에 동의합니다.",
        enroll_description_jp:
            '1. 본인은 후원자에게서 가입 안내서 (개요 문서) 1부를 무료로 받았으며,',
        enroll_description_jp11:
            '이에 앞서 후원자에게서 동 안내서에 대해 설명을 들었습니다. 본인은 동 안내서의 내용과 약관을 충분히 이해하였으며, 따라서 가입을 할 것입니다.',
        enroll_description_jp12:
            '또한, 본인은 가입 안내서에 표시된 약관 및 보너스 계획과, 가입신청서 뒷면에 있는, 가입 항목의 개인 정보 취급에 대해 충분히 이해하고 동의합니다.',
        enroll_description_jp13:
            '본인은 가입하고자 할 경우 성인이어야 하며, 모든 학생과 공무원은 가입이 금지되어 있음을 이해합니다.',
        enroll_description_jp14: '',
        enroll_description_jp2:
            '2. 본인은 스폰서에게서 회원 가입과 관련하여 다음 사항에 대한 설명을 들었으며, 이를 충분히 이해하였습니다.',
        enroll_description_jp21: '(１)회사명, 주소, 전화번호, 대표자 성명',
        enroll_description_jp22:
            '(２)제품명, 제품 종류와, 성능 또는 품질의 중요한 부분',
        enroll_description_jp23:
            '(３)제품의 판매 가격, 가격 지불 방법, 배송 소요기간 및 배송 방법과, 제품 판매 조건과 관련된 기타 중요한 부분',
        enroll_description_jp24: '(４)특정 부담의 내용',
        enroll_description_jp25:
            '(５)보너스 계획의 구조 및 특정 혜택에 관한 사항',
        enroll_description_jp26:
            '(６)계약의 해지 조건 (계약 철회 가능기간 포함)과, 계약에 관한 기타 사항',
        enroll_description_jp27:
            "(７)'불성실 사실 통지' 또는 '사실 통지 불이행'을 비롯한, 금지사항 관련 사항",
        enroll_description_jp28: '(８)개인정보 취급에 관한 사항',
        enroll_description_jp3:
            '3. 가입비를 신용 카드로 지불할 때, 제3자가 카드번호나 유효기간을 알지 못하도록 충분한 주의를 기울이십시오.',
        enroll_description_jp31:
            '당사는 당사에 귀책사유에 해당하지 않는 사유로 정보가 유출되어 신용카드가 남용됨으로써 발생할 수있는 피해에 대해 책임을 일절 지지 않습니다.',
        enroll_description_jp32: '',
        enroll_description_jp33: '',
        enroll_description_jp4:
            '4. 가입이 승인된 후, 부정확 한 사안 및/또는 사실이 아닌 사안에 대해 공개되었거나, 승인을 거부할 수 있는 정당한 사유가 있는 경우, 당사는 동 가입과 직접적 관련이 있는 모든 계약을 해지할 수 있습니다.',
        enroll_description_jp41: '상기 사항에 동의할 경우, 칸에 체크하십시오.',
        enroll_description_jp42: '',
        enroll_description_jp43: '',
        enroll_description_th:
            "본인은 유니시티 마케팅 (태국) 주식회사의 디스트리뷰터 (일명, 'Associate') 가 되는 것을 신청하고 있음을 확인합니다. 본인은 본 신청서에 서명한 후 이를 제출하고 스타터 키트에 대한 금액을 지불함으로써 이 신청절차에 동의함을 확인합니다. 본인은 유니시티가 본 신청서에 기재된 전화번호(들), 팩스 번호 및/또는 이메일 주소로 연락하는 데 동의합니다. 본인은 신청서/유통계약서의 약관을 읽고 이에 동의하였음을 증명합니다. 또한, 본인은 유니시티 보상플랜과 유니시티 정책 및 절차서 (본 문서에 포함되어 있으며 본 계약서의 일부분을 구성함)를 읽고 이해하였으며, 이에 동의합니다.",
        enroll_description_vn:
            "본인은 유니시티 인터내셔널의 디스트리뷰터 (일명, 'Associate') 지정을 신청하고 있음을 확인합니다. 본인은 본 신청서에 서명한 후 이를 제출하고 스타터 키트에 대한 금액을 지불함으로써 이 신청절차가 완료됨에 동의하고 이를 확인합니다. 본인은 유니시티가 본 신청서에 기재된 전화번호(들), 팩스 번호 및/또는 이메일 주소로 연락하는 데 동의합니다. 본인은 신청서/유통계약서의 약관을 읽고 이에 동의하였음을 증명합니다. 또한, 본인은 유니시티 보상플랜과 유니시티 정책 및 절차서 (본 문서에 포함되어 있으며 본 계약서의 일부분을 구성함)를 읽고 이해하였으며, 이에 동의합니다.",
        enroll_option_msg1: '무료 회원가입이 가능하도록',
        enroll_option_msg2: '~ 이상을 주문하고자 합니다.',
        enroll_option_msg3: '~ 미만을 주문하고자 합니다.',
        enroll_order: '회원가입 순서',
        enroll_pc: '',
        enroll_reminder1: '회원가입 독촉장 1',
        enroll_reminder2: '회원가입 독촉장 2',
        enter_email_address: '',
        enter_id: '',
        enter_number: '숫자, 알파벳, 공백을 포함하여 입력하세요.',
        enter_your_otp: 'OTP 코드를 입력하십시오.',
        error_2004: '고객을 찾을 수 없습니다.',
        error_2006: '고객을 찾을 수 없습니다.',
        error_2302:
            '유니시티에 이미 가입되어 있는 아이디입니다.[br]다른 디스트리뷰터 아이디를 사용하십시오.',
        error_500: '',
        error_account_lock: '잘못된 계정 정보',
        error_account_suspense: '귀하의 계정이 정지되었습니다.',
        error_account_terminate: '잘못된 계정 정보',
        error_account_vietnam: '베트남 디스트리뷰터만 허용됩니다.',
        error_card_name: '신용카드 이름은 필수 입력사항입니다.',
        error_card_number: '잘못된 신용카드 번호입니다.',
        error_cart_limit_price:
            '총 지불예정금액 #LCUR#TOTAL#RCUR USD가 최대 구매가능 금액인 #LCUR#LIMTOTAL#RCUR USD를 초과합니다.',
        error_cart_limit_qty: '총 수량이 품목당 한도인 #LIMQTY개를 초과합니다.',
        error_change_same_password:
            '새 비밀번호는 이전 비밀번호와 다른 것이어야 합니다. 새 비밀번호를 선택하세요.',
        error_couldnt_get_data: '서버에서 데이터를 가져올 수 없습니다.',
        error_could_not_authorize_card:
            '신용 카드 결제를 승인하고 캡처 할 수 없습니다.',
        error_customlink_discontinue: '',
        error_cvv: 'CVV 번호가 올바르지 않습니다.',
        error_duplicated_order:
            '같은 주문을 다시 시도했을 수 있습니다. 이전에 성공적으로 지불 한 주문은 {{order_id}} 주문이며 {{date}}에 {{time}}에 CV : {{pv}}, 가격 : {{total}}입니다.',
        error_duplicated_order_without_order:
            '같은 주문을 다시 시도했을 수 있습니다. 성공적으로 지불 한 이전 주문이 {{time}}에 {{date}}에 제출되었습니다.',
        error_empty_email: '이메일 주소는 필수 입력사항입니다.',
        error_expire_m: '만료일 (월)이 올바르지 않습니다.',
        error_expire_y: '만료일 (년)이 올바르지 않습니다.',
        error_inactive_product: '품절 제품',
        error_incorrect: '~이/가 올바르지 않습니다.',
        error_internal_server:
            '거래 처리에 문제가 있습니다. 거래가 취소되었습니다.',
        error_jp_address1_invalid: '',
        error_jp_address2_invalid: '',
        error_jp_fullname_invalid: '',
        error_liquefy_prohibited: '',
        error_login_incorrect: '잘못된 계정 정보',
        error_message: '제품을 추가하면 무료 회원가입이 가능합니다.',
        error_mobile_empty: '휴대전화 번호는 필수 입력사항입니다.',
        error_mobile_save: '휴대전화 번호가 정상적으로 변경되었습니다.',
        error_not_enough_inventory: '재고가 충분하지 않습니다.',
        error_payment_required: '지불 요망',
        error_referral_code_empty: '',
        error_retry_with:
            '주문을 즉시 처리할 수 없습니다. 나중에 다시 시도하십시오.',
        error_signature: '서명은 필수 입력항목입니다.',
        error_sku_not_supported: '이 SKU는 지원되지 않습니다: #SKU',
        error_stater_kit: '스타터 키트가 필요합니다.',
        error_transaction_requires_authorization: '거래에는 승인이 필요합니다.',
        error_upload_id_card: '주민등록증 사본을 업로드 하세요.',
        error_user_type_denied: '이 사용자 유형으로는 로그인 할 수 없습니다.',
        err_government_id_not_found: '',
        err_idcard_duplicate: '주민등록증 번호가 중복 입력되었습니다.',
        err_idcard_number: '주민등록증 번호는 필수 입력항목입니다.',
        err_ird_duplicate: '이미 등록된 IRD 번호입니다.',
        err_lenght: '',
        err_no: '유효하지 않은 주민등록번호입니다.',
        err_number: '주민등록번호는 숫자로만 이루어져 있어야 합니다.',
        err_ph_gov_duplicate: '',
        err_space: '주민등록번호에는 공백이 없어야 합니다.',
        err_upload_back: '주민등록증 뒷면을 업로드 하십시오.',
        err_upload_bookbank_photo: '책은행 사진은 필수 입력항목입니다.',
        err_upload_duplicate:
            '주민등록증 앞면 사진과 뒷면 사진 각각의 파일 이름이 동일한지 또는 다른지 확인하여, 이들 사진을 승인하십시오.',
        err_upload_front: '주민등록증 앞면을 업로드 하십시오.',
        err_upload_govid: '주민등록증 앞면은 반드시 업로드 하여야 합니다.',
        err_upload_govid_back: '주민등록증 뒷면은 반드시 업로드 하여야 합니다.',
        err_upload_govid_photo:
            '사진과 주민등록증은 반드시 업로드 하여야 합니다.',
        err_upload_portrait: '얼굴 사진 이미지를 업로드 하십시오.',
        etc_remark: '',
        eventidn: '',
        example: '예:',
        example_email: '',
        example_location_of_purchase: '',
        exapmple_id: '',
        except_receipt: '',
        exd: 'ED',
        existing_customer: '기존 고객입니까?',
        exm: 'EM',
        exp: '만료일',
        expiration_date: '만료일: 2020년 01월 30일',
        expire_date: '유효기한',
        expiry: '',
        ex_health_check_form_ba_number: '',
        ex_health_check_form_email: '',
        ex_health_check_form_fullname: '',
        ex_health_check_form_id_number: '',
        ex_health_check_form_phone_number: '',
        family: '가족',
        featured: '',
        february: '이월',
        feedback: '피드백',
        feedback_description: '서비스 개선을 위해 의견을 남겨주세요!',
        feedback_facebook_messenger:
            '우리는 귀하의 의견에 귀를 기울이고 있습니다!',
        feedback_facebook_messenger_close: '',
        feedback_issue:
            '의견 제출 과정에서 문제가 발생했습니다. 나중에 다시 시도하십시오.',
        female: '여성',
        file_no_found: '',
        file_type_is_invalid: '파일 형식이 잘못되었습니다.',
        find: '찾기',
        'find our shop': '',
        firstname: '이름',
        firstnameen: '이름 (영문)',
        firstnameid: '이름 (인도네시아어)',
        firstnamenative: '',
        firstnameth: '이름 (태국어)',
        first_order: '최초 주문',
        first_payment_details: '최초 주문 지불 세부내역',
        first_summary: '최초 주문 개요',
        fixed_deduction: '',
        flat_income_deduction: '정액 소득공제',
        focus: '초점',
        follow_us: '지시사항을 따르세요.',
        footer_phone_kr: '문의 전화 (무료통화)',
        footer_subscription: '',
        for: '',
        forgotpass_msg1:
            '손쉬운 세 단계로 비밀번호를 변경하실 수 있습니다. 또한, 새 비밀번호를 안전하게 유지하는 데 도움이 됩니다.',
        forgotpass_msg2:
            '아래에 귀하의 정확한 이메일 주소와 디스트리뷰터 아이디를 입력하십시오.',
        forgotpass_msg3:
            '당사의 시스템이 재설정되며, 새 비밀번호를 이메일로 보내드리겠습니다.',
        forgotpass_msg4:
            "새 비밀번호를 설정하려면 '프로필' 코너로 이동하여, 원하는 비밀번호로 변경할 수 있습니다.",
        forgotpass_msg5: '회원번호',
        forgotpass_msg6: 'Ushop에 등록한 디스트리뷰터 아이디를 입력하세요.',
        forgotpass_msg7: '이메일 주소를 입력하세요.',
        forgotpass_msg8:
            '유니시티에 회원가입을 할 때 사용한 이메일 주소를 입력하십시오. 그 다음, 이 이메일 주소로 코드를 보내드립니다.',
        forgot_pass: '비밀번호찾기',
        forgot_password: '비밀번호를 잊으셨나요?',
        for_customer_type: '고객 유형:',
        free: '',
        free_tshirt: '무료 티셔츠',
        freight: '화물',
        'friendly customer support': '',
        friendly_csupport: '',
        from: '에서',
        fsb_no: '아니요',
        fsb_yes: '예',
        fullname: '성명',
        fullname_native: '[:국가_코드:] 성명',
        full_name: '이름',
        full_name_chinese: '',
        full_name_warranty: '',
        gcash_text_info: '',
        gcash_text_info_url: '',
        gender: '성별',
        genealogy: '다운라인',
        genealogy_card_view: '상세',
        genealogy_full_name: '성명',
        general: '일반',
        generate_referral_link: '추천 링크 생성',
        'get motivated everyday': '',
        get_in_touch_with_us: '당사와 연락하십시오.',
        get_new_password: '새 비밀번호 받기',
        glic1: 'GLIC2019 초대 프로모션',
        glic2: 'GLIC2019 특별 프로모션',
        glic_ba_not_found: '회원을 찾을 수 없습니다.',
        glic_cannot_update_status_2to4:
            '주문 상태를 2에서 4로 업데이트 할 수 없습니다.',
        glic_empty_order_id: '주문번호가 비어 있습니다.',
        glic_empty_pickup_code: '비밀번호를 입력해 주세요.',
        glic_error_inactive_product: '제품이 비활성화 되어 있습니다.',
        glic_error_not_enough_inventory: '재고가 충분하지 않습니다.',
        glic_no_item: '품목을 찾을 수 없습니다.',
        glic_pickup_already: '이미 사용 중인 비밀번호입니다.',
        glic_pickup_not_found: '주문번호 또는 비밀번호가 틀렸습니다.',
        google_play: '구글플레이',
        government_id: '주민등록번호',
        government_id_back: '주민등록증 뒷면에 표시된 번호',
        govid: '주민등록번호',
        govidcard: '주민등록증',
        go_back_to_homepage: '홈페이지로 돌아가기',
        go_to_shopping: '쇼핑하기',
        graduated_income_tax: '소득누진세',
        grad_inc_tax: '',
        gross_annual_income: '귀하의 연간 총 소득은 얼마입니까?',
        gv: 'GV',
        g_cash: '',
        hadyai_dsc: '',
        halfday: '반나절 배송',
        handling_fee: '수수료',
        have_co_app: '공동 신청인을 추가하시겠습니까?',
        ha_noi: '하노이',
        head_quarter: '',
        healthcheckgovid: '',
        healthcheck_event_exceed_quota: '',
        healthcheck_event_exist_ba_id: '',
        healthcheck_event_invalid_ba_id: '',
        healthcheck_event_invalid_email: '',
        healthcheck_event_invalid_id_card: '',
        healthcheck_event_invalid_mobile: '',
        healthcheck_event_invalid_name: '',
        healthcheck_event_invalid_time: '',
        healthcheck_event_mail_before_ending: '',
        healthcheck_event_mail_cancel_link: '',
        healthcheck_event_mail_ending: '',
        healthcheck_event_mail_intro: '',
        healthcheck_event_mail_msg: '',
        healthcheck_event_mail_number: '',
        healthcheck_event_mail_subject: '',
        healthcheck_event_token_not_found: '',
        healthcheck_success: '',
        health_check_form_ba_number: '',
        health_check_form_btn: '',
        health_check_form_condition: '',
        health_check_form_confirm_btn: '',
        health_check_form_confirm_popup: '',
        health_check_form_dropdown: '',
        health_check_form_email: '',
        health_check_form_fullname: '',
        health_check_form_id_number: '',
        health_check_form_label: '',
        health_check_form_label_popup: '',
        health_check_form_msg1: '',
        health_check_form_msg10: '',
        health_check_form_msg11: '',
        health_check_form_msg2: '',
        health_check_form_msg3: '',
        health_check_form_msg4: '',
        health_check_form_msg5: '',
        health_check_form_msg6: '',
        health_check_form_msg7: '',
        health_check_form_msg8: '',
        health_check_form_msg9: '',
        health_check_form_phone_number: '',
        health_check_form_title: '',
        health_check_mail_prepare_1: '',
        health_check_mail_prepare_2: '',
        health_check_mail_prepare_3: '',
        health_check_mail_prepare_4: '',
        health_check_mail_prepare_5: '',
        health_check_mail_prepare_6: '',
        health_check_mail_prepare_7: '',
        health_check_mail_prepare_8: '',
        health_check_mail_prepare_9: '',
        health_check_mail_prepare_title: '',
        health_check_mail_program_1: '',
        health_check_mail_program_10: '',
        health_check_mail_program_11: '',
        health_check_mail_program_2: '',
        health_check_mail_program_3: '',
        health_check_mail_program_4: '',
        health_check_mail_program_5: '',
        health_check_mail_program_6: '',
        health_check_mail_program_7: '',
        health_check_mail_program_8: '',
        health_check_mail_program_9: '',
        health_check_mail_program_title: '',
        heart_health: '심장 건강',
        hello: '안녕하세요',
        help: '도움',
        herbal_supplements: '허브/보충제',
        hidethree:
            '화면의 크기를 넓히려면, 화살표를 사용하여 ID, Rank, VIP를 숨기거나 표시하십시오.',
        highestrank: '최고 직급',
        history: '리포트',
        hkg: '',
        home: '홈',
        homephone: '집 전화번호',
        home_number_placeholder: '',
        hongkong: '홍콩',
        hot: '',
        hours: '시간',
        household: '가정',
        ho_chi_minh: '호치민 시티',
        hydra_incorrect_creditcard: '',
        id: '아이디',
        idbackcard: '주민등록증 뒷면에 표시된 번호',
        idcard: '주민등록증번호',
        idcard_description:
            '주민등록증 사본에 명시된 개인 성명으로유효한 시민권에 적용됩니다.',
        idn: '인도네시아',
        id_card: '국가 ID 카드',
        id_err_lenght: '잘못된 주민등록번호입니다.',
        immune_health: '면역 건강',
        in: '인도',
        incorrect_username_password:
            '회원번호 또는 비밀번호를 다시 확인하십시오',
        indonesia: '인도네시아',
        info: '정보',
        information: '정보',
        information_download: '다운로드',
        information_header: '변호사 조건',
        information_msg1: 'BA 귀하',
        information_msg10:
            '본 주문을 진행하기 위한 최저 의무 송장금액은 적용되지 않습니다.',
        information_msg11:
            '제3자가 자신의 회원가입 오더를 픽업할 목적으로 신규 회원가입을 할 수는 없습니다.',
        information_msg2:
            "배송 방식으로 '방문수령'을 선택하였고 제3자가 귀하의 상품을 대리수령하기를 원할 경우, 다음의 사항을 당사에 제출하십시오.",
        information_msg3: '위임장',
        information_msg4: '송장 요청서',
        information_msg5: '전송된 각 송장에 대해 10바트 태국 수입인지 1장',
        information_msg6: '원본대조필 사본으로 주문한 자의 신분증 사본',
        information_msg7:
            '원본대조필 사본으로 주문물품을 방문 수령하는 자의 신분증 사본',
        information_msg8: '비고',
        information_msg9: '본 위임은 본 주문에 대한 모든 제품에 적용됩니다.',
        info_card_name: '카드명',
        info_card_number: '카드번호',
        initial: '주문 처리 직원',
        input_number: '숫자만 입력 가능',
        instock: '재고',
        invalid_date: '',
        invalid_distributor_number: '잘못된 디스트리뷰터 번호입니다.',
        invalid_email: '',
        invalid_enroller_or_sponser: '잘못된 인롤러 또는 스폰서입니다.',
        invalid_member_id: '',
        invalid_mobile_no: '',
        invalid_order_no: '',
        invalid_referral_id: '',
        invalid_referral_id_status: '',
        invalid_referral_id_status_ph: '',
        invalid_serial_no: '',
        invalid_sponser: '잘못된 스폰서입니다.',
        invalid_zip: '',
        in_this_leg: '이 다리에',
        ird: '세무국 (IRD)',
        is_not_existing: '',
        is_not_registered: '',
        is_out_of_stock: '품절되었습니다.',
        item: '',
        itemized_deductions: '항목별 공제',
        items: '',
        item_code: '',
        item_not_found: '',
        i_agree: '동의합니다.',
        i_agree_company: '회사의 약관에 동의합니다.',
        i_disagree: '동의하지 않습니다.',
        january: '일월',
        january_sem: '일월',
        japan: '일본',
        japanese_name: '일본명',
        japan_post_bank_account:
            '유초은행 (일본 우체국 은행) 가이엔마에 우체국',
        joined: '가입일자',
        jp: '',
        jpn: '일본',
        jpn_autoship_delivery_fee_remark: '',
        jp_add_to_cart: '',
        jp_err_duplication: '이미 존재하는 신청확인번호입니다.',
        jp_err_lenght: '신청확인번호는 9자리여야 합니다.',
        jp_err_no: '유효하지 않은 신청확인번호입니다.',
        jp_err_number: '신청확인번호는 숫자로만 구성되어 있어야 합니다.',
        jp_err_space: '신청확인번호에는 공백이 없어야 합니다.',
        jp_important_note_desc: '',
        jp_important_note_title: '',
        jp_pv: 'PSV',
        jp_roman_writing: '영어',
        jp_starting_from: '',
        jp_tv: 'TV',
        july: '칠월',
        june: '유월',
        kampaengpetch_dsc: '',
        kanji_name: '일본명',
        key: 'Translation',
        kg: 'kg',
        khm: '',
        khonkaen_phawadon_dsc: '',
        kor: '',
        korea: '대한민국',
        korea_food: '',
        korea_food2: '',
        korea_food3: '',
        korea_points: '포인트',
        kpi: '핵심성과지표 (KPI)',
        kpis: '핵심성과지표 (KPI)',
        lampang_nuntakarn_dsc: '',
        landmark: '랜드마크',
        landmark_ph: '',
        language: 'KO',
        language_bahasa: '인도네시아어',
        language_desc: '한국어',
        language_english: '영어',
        language_japanese: '일본어',
        language_korean: '한국어',
        language_thai: '태국어',
        language_vietnamese: '베트남어',
        lao: '',
        lastname: '성',
        lastnameen: '성 (영문)',
        lastnameid: '성 (인도네시아어)',
        lastnamenative: '',
        lastnameth: '성 (태국어)',
        last_order: '마지막 주문',
        last_run_period: '마지막 실행 기간',
        learn_more: '더 알아보기',
        learn_more_about: '',
        learn_more_en:
            '<ol><li class="text-danger">Color Red is mean .... </li><li> Star is mean </li></ol>',
        learn_more_native: 'https://www.unicity.com',
        leave: '',
        leave_enroll: '회원가입 코너 나가기',
        leave_enroll_cancel: '취소',
        leave_enroll_msg1: '나가시겠습니까?',
        leave_enroll_msg2: '모든 값이 초기화되며, 저장되지 않습니다.',
        leg: '레그',
        leg1: 'A 레그',
        leg2: 'B 레그',
        leg3: 'C 레그',
        leg_3_short: 'C 레그',
        lets_get_intouch: '연락을 합시다.',
        level: '단계',
        li1:
            '참가자는 모든 약관을 준수해야 합니다 (스폰서의 CV와 TV, 스폰서 및 신규 회원의 활동상태 100CV).',
        li2:
            '자격을 갖춘 회원은 반드시 개인적으로 본 트립에 참가하여야 합니다. 트립 점수는 양도 및 교환이 불가능합니다.',
        li3:
            '회원은 필요한 비자와 출장 시 필요한 서류를 신청하고 발급을 받을 책임을 지며, 관련 수수료도 회원이 부담하여야 합니다. 유니시티는 비자 또는 필요 서류의 발급 거절에 대해 책임지지 않습니다.',
        li4: '회원은 등록된 국가의 공항에서만 출발이 가능합니다.',
        li5:
            '유니시티는 필요하다고 판단되는 경우 본 리더십 세미나를 변경할 권리가 있습니다. 유니시티는 항공사 측 과실, 정치적 상황, 폭동, 시위, 파업 및/또는 자연재해를 포함하여 이에 국한되지 않는 사유로 인한, 본 행사의 손해, 지연 또는 취소에 대해 책임을 지지 않습니다.',
        li6: '인증은(Qualification) TH 형식으로만 제공됩니다.',
        li7: '모든 기준을 충족하는 경우에만 직급이 표시됩니다.',
        li8:
            '모든 참가자들은 점수 적립 첫 달부터 시작하여 자격 기간이 종료될 때까지 최소 현지에서 200 CV를 유지하여야 합니다.',
        li9:
            "참가자들은 자격 종료 후 트립 기간까지 'Active 상태' (100 CV)를 유지하여야 합니다.",
        life_health: '생명 건강',
        limited_offer: '',
        limit_age: '',
        limit_age_18: '',
        limit_age_id: '신청인은 만 17세 이상이어야 합니다. (일-월-년)',
        line: '라인',
        list_autoship1: '오토십 1 (Autoship 1)',
        list_autoship2: '오토십 2 (Autoship 2)',
        list_autoship3: '오토십 3 (Autoship 3)',
        list_autoship4: '오토십 4 (Autoship 4)',
        list_autoship5: '오토십 5 (Autoship 5)',
        list_view: '',
        loading_data: '데이터 로드 중...',
        load_more: '더로드',
        location_of_purchase: '',
        loggedinwarning: '로그인 후 해당 서비스를 이용할 수 있습니다',
        login: '로그인',
        login_button_different_account: '다른 계정 ...',
        login_button_login: '로그인',
        login_button_new_password: '새 비밀번호',
        login_by: '로그인 방법',
        login_cover_title: 'MAKE LIFE BETTER',
        login_header_subtitle: '어서 오십시오',
        login_header_title: '로그인 해주세요',
        login_id: '로그인 ID',
        login_no: '',
        login_no_referral_id: '아니요, 그러나 추천인이 있습니다.',
        login_no_register_ba_ph: '',
        login_no_register_pc_ph: '',
        login_referral:
            '추천인의 성명, 이메일 주소 또는 전화번호를 가지고 있습니다.',
        login_referral_ph: '',
        login_remember_me: 'Remember me',
        login_to_your_account: '귀하의 계정에 로그인하십시오',
        login_with_facebook: '페이스북으로 로그인',
        login_yes: '',
        login_yes_ph: '',
        logout: '로그아웃',
        log_out_desc: '',
        look_forward: '',
        lopburi_dsc: '',
        lsb: 'LSB',
        lsb_amount: '금액',
        macau: '',
        magazine: '',
        mail_creditbalance: '',
        mail_creditcard: '신용 카드',
        maintenance_desc: '',
        maintenance_desc1:
            '시스템 유지 보수를 위해 시스템이 일시적으로 다운되었습니다.',
        maintenance_desc2:
            '이 사이트는 유지 보수를 위해 10 월 1 일 (화요일) 00:00부터 06:00까지 이용할 수 없습니다.',
        maintenance_desc3: '불편을 끼쳐 드려 죄송합니다.',
        maintenance_title: '',
        main_office: '본사',
        maldives: '',
        male: '남성',
        march: '삼월',
        marital_status: '결혼여부',
        marketing_materials: '마케팅 자료',
        married: '기혼',
        max: '최대',
        maximum_drilldown: '최대 레벨에 도달했습니다. 드릴다운 하십시오.',
        maximum_reached: '최대 행에 도달했습니다.',
        may: '오월',
        mbr: '멤버',
        mckinley_office: '',
        media: '미디어',
        member_card: '회원 카드',
        member_id: '회원번호',
        member_id_is_empty: '',
        member_id_must_be_number: '',
        member_id_warranty: '',
        mena: 'MENA',
        mena_invalid_email: '',
        mena_invalid_mobile: '',
        mena_invalid_whatsapp: '',
        mena_update_fail: '',
        mens_and_womens_health: '남성과 여성의 건강',
        'metabolic breakthrough': '',
        mgr: 'GM',
        middle_name: '중간이름',
        min: '최소',
        mins: '분',
        miscellaneous: '기타',
        mizuho_account: '미즈호 은행 아오야마 지점',
        mmr: '',
        mobile: '휴대전화번호',
        mobilephone: '휴대전화번호',
        mobilephone_id: '휴대전화 번호',
        mobilephone_jp: '',
        mobilephone_ph: '',
        mobilephone_sg: '',
        mobilephone_warranty: '',
        mobile_already_exists: '중복된 휴대전화 번호입니다.',
        mobile_no_is_empty: '',
        mobile_no_must_be_number: '',
        mobile_number_placeholder: '',
        mobile_phone: '휴대전화',
        'monday-friday': '월요일-금요일',
        month: '월',
        monthly: '월간',
        months: '기간',
        month_1: '',
        month_10: '',
        month_11: '',
        month_12: '',
        month_2: '',
        month_3: '',
        month_4: '',
        month_5: '',
        month_6: '',
        month_7: '',
        month_8: '',
        month_9: '',
        month_apr: '사월',
        month_aug: '팔월',
        month_commission: '',
        month_dec: '십이월',
        month_feb: '이월',
        month_jan: '일월',
        month_jul: '칠월',
        month_jun: '유월',
        month_mar: '삼월',
        month_may: '오월',
        month_nov: '십일월',
        month_oct: '시월',
        month_sep: '구월',
        more_info: '추가 정보',
        more_than_warranty_period: '',
        moscow_seminar1: '',
        moscow_seminar1_kh: '',
        moscow_seminar1_vn: '',
        moscow_seminar2: '',
        moscow_seminar2_kh: '',
        moscow_seminar2_vn: '',
        moscow_seminar3: '',
        moscow_seminar3_kh: '',
        moscow_seminar3_vn: '',
        motordelivery: '모터 배송',
        mounth: '',
        myorders: '주문내역',
        mys: '',
        my_cart: '내 장바구니',
        my_referral_code: '',
        nakornpathom_dsc: '',
        nakornratchasrima_dsc: '',
        nakornsawan_nat_dsc: '',
        nakorn_sri_thammarat_dsc: '',
        name: '받는사람',
        name_is_empty: '',
        name_japanese: '',
        name_jp: '',
        name_on_creditcard: '신용카드 명의자 성명',
        nature_pure_water_purifier: '네이처 정수기',
        neigene: '네이젠',
        neigene_skin_care: '네이젠 스킨케어',
        net_earnings: '금액',
        net_total: '',
        newba: '신규 회원가입',
        newjoiner: '신규',
        newpassregister_msg: '',
        news: '뉴스',
        news_event: '',
        news_promotions: '',
        newzealand: '뉴질랜드',
        new_address: '새 주소',
        new_password: '비밀번호 재설정',
        new_password_description:
            'If you have not set up your account or forgotten your password, enter your email address and distributor or customer ID below.',
        new_website_promotions: '',
        next: '다음',
        nfr: 'NFR',
        nickname: '닉네임',
        no: '',
        none: '없음',
        nonthaburi_rattanathibet_dsc: '',
        non_vat: '부가가치세 비과세',
        note: '',
        nothing_here: '이곳에는 아무것도 없습니다.',
        not_enough_ar_balance: '',
        not_found_content: '',
        not_found_province: '입력 한 내용이 주 이름과 일치하지 않습니다.',
        nourish: '영양 공급',
        november: '십일월',
        november_sem: '십일월',
        no_autoship: '저장한 항목에 더 이상 접근할 수 없습니다.',
        no_autoship_display: '',
        no_bookmarks: '',
        no_commission: '표시 할 커미션이 없습니다.',
        no_data: '검색 결과가 없습니다.',
        no_enroll_id:
            '추천 회원가입자 또는 스폰서 회원번호(ID)가 없습니다. / 추천 회원가입자 또는 스폰서는 있지만 이들의 회원번호(ID)는 확실하게 알지 못합니다.',
        no_financial_statement: '재무제표가 없습니다.',
        no_info_points: '',
        no_issue_retrieving: '이 사용자를 검색하는 중에 문제가 발생했습니다.',
        no_item: '',
        no_item_in_cart: '이런, 여기에 아무것도 없네요.',
        no_lsb_msg1: '',
        no_lsb_msg2:
            '라이프스타일 보너스를 받으려면 여기에서 다시 확인하십시오.',
        no_match_found_for: '',
        no_need_receipts: '영수증은 필요하지 않습니다.',
        no_orderhistory: '현재 표시 할 주문 내역이 없습니다.',
        no_product: '',
        no_products_found: '제품을 찾을 수 없습니다.',
        no_receive: '',
        no_saved_address: '저장된 주소가 없습니다.',
        no_withholding: '추가 원천세 없음',
        nric_description:
            '싱가포르 주민등록증 사본에 명시된 개인 성명으로 되어 있는, 싱가포르의 유효한 시민권/영주권에 적용됩니다.',
        nric_duplicate: '중복된 싱가포르 주민등록번호입니다.',
        number_of_new_joiner: '신규 회원 수',
        nutrient: '영양소',
        nutrition: '영양소',
        nzl: '',
        n_a: '해당없음',
        october: '시월',
        october_oct_test: '시월',
        ok: '확인',
        om_err_lenght: '',
        one_two_business_days: '1~2영업일',
        online: '온라인',
        online_shop: '',
        only_active_distributor: '활동 중인 디스트리뷰터만 주문할 수 있습니다.',
        only_english_test: '',
        only_thai_test: '',
        only_unicity_income: '유니시티가 귀하의 유일한 수입원입니까?',
        only_vn_account: '베트남 디스트리뷰터만 허용됩니다.',
        only_vn_ba_for_vn_sponsor:
            '베트남에서는 베트남 디스트리뷰터만 후원이 허용됩니다.',
        open: '열기',
        'open 24/7': '',
        optional: '선택사항',
        options: '',
        opt_expired: 'OTP 코드 만료일은 다음과 같습니다.',
        oral_care: '구강 치료',
        order: '주문',
        orderhistory: '주문 내역',
        orders: '주문',
        order_date: '주문일',
        order_details: '주문 세부사항',
        order_details_korea:
            '아래 배송지 정보는 영문으로만 입력하실 수 있습니다.',
        order_history: '주문 내역',
        order_history_buy_from: '구매 방법',
        order_history_calculating: '기다려주세요',
        order_history_detail: '세부 묘사',
        order_history_number: '주문',
        order_list: '',
        order_no: '주문번호',
        order_no_is_empty: '',
        order_no_must_be_number: '',
        order_number: '주문번호',
        order_number_warranty: '',
        organize_too_big: '너무 클 경우에는 정리할 것',
        original_base: '',
        orlando: '',
        os_cash: '',
        os_creditcard: '신용 카드',
        os_storecredit: '',
        'other-packs': '',
        others: '기타',
        other_business: '기타 사업',
        other_packs: '기타 팩',
        our_mobile_app: '모바일앱',
        our_shop: '우리 매장',
        outlet_stores: '아웃렛 매장',
        out_of_stock: '품절',
        ov: 'OV',
        pack: '팩',
        package_enroll: '신규 회원가입',
        package_shop: '재구매',
        page_not_found: '페이지를 찾을 수 없습니다.',
        page_not_found_description_1: '검색 중인 페이지를 찾을 수 없습니다.',
        page_not_found_description_2:
            '페이지 오른쪽 상단에 있는 검색창을 사용해 보십시오.',
        paid: '지급',
        palladium_watergate_dsc: '',
        pasig_office: '',
        password: '비밀번호',
        password_confirm: '비밀번호 확인',
        password_confirm_id: '비밀번호 확인',
        password_id: '비밀번호',
        password_match: '비밀번호가 입력되지 않았거나 일치하지 않습니다.',
        pata_pinklao_dsc: '',
        pathumthani_dsc: '',
        pattaya_dsc: '',
        pay: '지불하기',
        payment_amount: '',
        payment_details: '지불 세부사항',
        payment_email_soon:
            '또한, 주문 세부내역이 명시된 확인 이메일을 곧 보내드리겠습니다.',
        payment_enroll_distributor_id: '귀하의 회원 아이디는 다음과 같습니다.',
        payment_enroll_terminated: '',
        payment_error_contact:
            '오류가 발생했습니다. 고객 서비스 센터에 전화로 도움을 요청하십시오.',
        payment_if_error_persists:
            '당사에서 귀하의 주문 처리에 도움을 드릴 수 있습니다.',
        payment_order_number_is: '',
        payment_order_placed:
            '귀하의 주문이 접수되었으며, 접수된 주문은 조속한 시일 내에 처리됩니다. 귀하의 주문번호는 다음과 같습니다.',
        payment_order_placed_jp_only:
            '귀하의 주문이 접수되었으며, 접수된 주문은 조속한 시일 내에 처리됩니다. 귀하의 주문번호는 다음과 같습니다.',
        payment_order_placed_soon: '',
        payment_order_placed_two: '',
        payment_process_step_1: '',
        payment_process_step_2: '',
        payment_process_warn: '',
        payment_result: '지불 결과',
        payment_something_wrong: '지불 과정에서 문제가 발생했습니다.',
        payment_status: '',
        payment_thank_you: '감사합니다!',
        payment_try_again:
            '이 오류가 계속되면, 다시 시도하시거나, 고객 서비스 센터에 전화로 문의하십시오.',
        payment_try_again_th: '',
        payment_type: '지불 유형',
        paymnet_checkout_other: '다른 제품도 확인하세요.',
        pay_bankwire: '은행 송금으로 지불',
        pay_by: '지불 수단',
        pay_domestic: '국내 지불',
        pay_info: '지불 정보',
        pay_international: '해외 지불',
        pay_now: '지금 결제하기',
        pay_securely_with: '~(으)로 안전하게 지불하세요!',
        pay_via: '지불 방법',
        pay_with_credit_card: '신용카드로 지불하기',
        pccmember: '다운로드',
        pc_referral_link: '',
        pc_referral_link_option: '',
        pdf_award_file_en: '',
        pdf_award_file_native: '',
        pdf_create_file_ready: '',
        pdf_create_processing: '',
        pdf_create_success: '',
        pdf_file: 'PDF 파일',
        pdf_program_promotion_file_en: '',
        pdf_program_promotion_file_native: '',
        pending: '지급 보류',
        people_japanese: '',
        percentage: '비중 (%)',
        percentage_tax: '비례세',
        percentage_tax_statement: '재무제표가 포함된 비례세 신고서',
        perferred_customer: '',
        period: '기간',
        personal_care: '개인 미용 및 위생',
        personal_information: '개인 정보',
        petchaburi_dsc: '',
        ph1: '1단계',
        phanomsarakham_dsc: '',
        phayao_dsc: '',
        phitsanulok_dsc: '',
        phl: '필리핀',
        phone: '전화',
        phone_no: '전화번호',
        photos: '사진',
        phuket_noppanan_dsc: '',
        ph_ba_advice_note:
            '유니시티의 세무 서비스는 유니시티를 보상 이외의 유일한 수입원으로 등록한 BA에만 적용됩니다.',
        ph_ba_advice_note_1: '',
        ph_ba_advice_note_one:
            "1. BA의 상호가 유니시티에 등록된 것과 동일한 경우, BA는 유니시티에 영수증을 발행하여야 합니다. 그렇지 않은 경우 BA는 유니시티에 등록된 상호를 BA의 상호로 변경하도록 선택할 수 있습니다. 그런 다음, BA의 업종이 유니시티와 일치하지 않더라도 자사의 상호로 '기타 소득'이라는 공식 영수증을 발행할 수 있습니다.",
        ph_ba_advice_note_two:
            '2. BA는 정부 기관에 등록사항을 업데이트 하여야 합니다. BA가 자사의 상호로 영수증을 발행하지 않기를 원하는 경우, BA는 수입원으로 수수료를 추가하고 개인 명의로 공식 영수증을 처리하도록 필리핀 국세청 개인 등록사항을 업데이트 하여야 합니다.',
        ph_ba_declation_message_one:
            '본인은 유니시티 네트워크 필리핀 주식회사가 본인을 대신하여 필리핀 국세청에 신청서를 제출할 권한을 부여합니다.',
        ph_ba_declation_message_three:
            '또한 유니시티가 월 소득세 납부 기금을 공제할 권한을 부여합니다. (선택 사항)',
        ph_ba_declation_message_two:
            '본인은 유니시티 네트워크 필리핀 주식회사가 필리핀 국세청 등록 처리 수수료로 수수료에서 1,500.00필리핀 페소를 공제할 권한을 부여합니다.',
        ph_commission_payout_warning:
            '수수료 지불과 관련된 문제를 방지하려면 은행 계좌와, 필리핀 국세청에 등록된 상호가, 당사 시스템에 반영된 것과 같아야 합니다.',
        ph_excluding_compensation: '',
        ph_tin_id_example:
            '납세자등록번호는 반드시 9~12개의 숫자로 구성되어 있어야 합니다 (예: 123456789000).',
        pickup: '픽업',
        'pickup-ortigas': '',
        'pickup-taguig': '',
        pickupjkt: '',
        pickuportigas: '방문 수령 - 오르티가스',
        pickupsby: '',
        pickuptaguig: '방문 수령 - 타기그',
        pickup_hanoi: '방문수령 - 하노이',
        pickup_hochiminh: '방문수력 - 호치민 시',
        pickup_info:
            '다른 사람이 귀하를 대신하여 주문 물품을 수령하도록 권한을 부여하려면,',
        pickup_info2: '추가 정보입니다.',
        pickup_office: '유니시티 센터에서 수령하기',
        'placeholder-update-info-email': '',
        'placeholder-update-info-mobilephone': '',
        'placeholder-update-info-whatsapp': '',
        'placeholder-update-info-workphone': '',
        placeholder_address_line1: '주소 1',
        placeholder_address_line1_au: '',
        placeholder_address_line1_hk: '',
        placeholder_address_line1_id: '',
        placeholder_address_line1_jp: '',
        placeholder_address_line1_jp_dev: '',
        placeholder_address_line1_kr: 'e.g. Silla Apt. 102-304',
        placeholder_address_line1_me: '',
        placeholder_address_line1_my: '',
        placeholder_address_line1_nz: '',
        placeholder_address_line1_ph: '',
        placeholder_address_line1_sg: '',
        placeholder_address_line1_street: '주소',
        placeholder_address_line1_th: '',
        placeholder_address_line1_th1: '주소 - 도로명',
        placeholder_address_line1_tw: '',
        placeholder_address_line1_twn: '',
        placeholder_address_line2: '주소 2',
        placeholder_address_line2_au: '',
        placeholder_address_line2_hk: '',
        placeholder_address_line2_id: '',
        placeholder_address_line2_jp: '',
        placeholder_address_line2_jp_dev: '',
        placeholder_address_line2_kr: 'e.g. 199-1 Naesu-2-dong',
        placeholder_address_line2_me: '',
        placeholder_address_line2_my: '',
        placeholder_address_line2_nz: '',
        placeholder_address_line2_ph: '',
        placeholder_address_line2_sg: '',
        placeholder_address_line2_street: '아파트, 동, 호',
        placeholder_address_line2_th: '',
        placeholder_address_line2_th1: '시/군/구 - 읍/면/동',
        placeholder_address_line2_th2: '구 - 시/군',
        placeholder_address_line2_tw: '',
        placeholder_card_cvv: '',
        placeholder_card_name: '',
        placeholder_card_number: '',
        placeholder_city: '구',
        placeholder_city_au: '',
        placeholder_city_id: '',
        placeholder_city_kr: 'e.g. Seoul',
        placeholder_city_my: '',
        placeholder_city_nz: '',
        placeholder_city_twn: '',
        placeholder_comment: '의견',
        placeholder_comment_hk: '',
        placeholder_comment_id: '',
        placeholder_comment_kr: '예: 의견',
        placeholder_comment_my: '',
        placeholder_comment_sg: '',
        placeholder_comment_th: '',
        placeholder_country: '국가',
        placeholder_district: '시/군/구',
        placeholder_district_hk: '',
        placeholder_district_id: '',
        placeholder_easy_referral_id: '',
        placeholder_email: '이메일',
        placeholder_email_au: '',
        placeholder_email_hk: '',
        placeholder_email_id: '',
        placeholder_email_jp: '',
        placeholder_email_kr: 'e.g. lorem_ipsum@gmail.com',
        placeholder_email_me: '',
        placeholder_email_my: '',
        placeholder_email_nz: '',
        placeholder_email_ph: '',
        placeholder_email_sg: '',
        placeholder_email_th: '',
        placeholder_email_twn: '',
        placeholder_first_name_hk: '',
        placeholder_first_name_tw: '',
        placeholder_full_name: '성명',
        placeholder_full_name_au: '',
        placeholder_full_name_hk: '',
        placeholder_full_name_id: '',
        placeholder_full_name_jp: '',
        placeholder_full_name_kr: 'e.g. Hong Gildong',
        placeholder_full_name_me: '',
        placeholder_full_name_my: '',
        placeholder_full_name_nz: '',
        placeholder_full_name_ph: '',
        placeholder_full_name_sg: '',
        placeholder_full_name_th: '',
        placeholder_full_name_tw: '',
        placeholder_landmark: '주요 건물',
        placeholder_landmark_id: '',
        placeholder_landmark_ph: '',
        placeholder_last_name_hk: '',
        placeholder_last_name_tw: '',
        placeholder_mobile_phone: '휴대전화',
        placeholder_mobile_phone_au: '',
        placeholder_mobile_phone_hk: '',
        placeholder_mobile_phone_id: '',
        placeholder_mobile_phone_jp: '',
        placeholder_mobile_phone_kr: 'e.g. 0234501800',
        placeholder_mobile_phone_me: '',
        placeholder_mobile_phone_my: '',
        placeholder_mobile_phone_nz: '',
        placeholder_mobile_phone_ph: '',
        placeholder_mobile_phone_sg: '',
        placeholder_mobile_phone_th: '',
        placeholder_mobile_phone_tw: '',
        placeholder_password: '비밀번호',
        placeholder_password_confirm: '비밀번호 확인',
        placeholder_postal_code: '우편번호',
        placeholder_postal_code_id: '',
        placeholder_province: '주 (州)',
        placeholder_province_id: '',
        placeholder_province_ph: '',
        placeholder_referral_id_th: '',
        placeholder_share_this_cart_name_th: '',
        placeholder_sns1_me: '',
        placeholder_sns1_sg: '',
        placeholder_sns2_me: '',
        placeholder_sns2_sg: '',
        placeholder_state: '주 (예: 뉴욕)',
        placeholder_state_au: '',
        placeholder_state_kr: 'e.g. Jongno-gu',
        placeholder_state_nz: '',
        placeholder_zip_au: '',
        placeholder_zip_code: '우편번호',
        placeholder_zip_hk: '',
        placeholder_zip_id: '',
        placeholder_zip_jp: '',
        placeholder_zip_kr: 'e.g. 30174',
        placeholder_zip_me: '',
        placeholder_zip_my: '',
        placeholder_zip_nz: '',
        placeholder_zip_ph: '',
        placeholder_zip_sg: '',
        placeholder_zip_th: '',
        placeholder_zip_tw: '',
        place_order: '주문하기',
        please_agree_terms_and_conditions: '이용 약관에 동의하십시오.',
        please_agree_terms_and_conditions_vn: '회사의 약관에 동의합니다.',
        please_choose_option: '아래 옵션을 선택하세요.',
        please_enter_distributor_id: '',
        please_select: '선택하세요.',
        please_select_every_unit: '모든 단위의 크기를 선택하십시오.',
        please_select_month: '기간을 선택하세요.',
        please_select_your_size: '사이즈를 선택하십시오.',
        please_specifygross_income:
            '총 소득에서 허용 가능한 공제를 기재하십시오.',
        please_verify_enroller_sponsor: '인롤러와 스폰서를 확인하십시오.',
        please_verify_id_card: '주민등록증을 확인하십시오.',
        please_wait_auto_ship:
            '오토십 (Autoship)을 이용한 지불을 계속 진행하십시오.',
        please_wait_process_request: '요청을 처리하는 동안 기다려 주십시오.',
        point: '포인트',
        points: '포인트',
        postalwire: '',
        postal_code: '우편번호',
        prd: 'PD',
        preferredcustomer: '우선 고객',
        preferred_customer: '우대가격',
        preferred_price: '',
        preview_downline: '다운라인 보기',
        price: '가격',
        pricehightolow: '높은 가격순',
        pricelowtohigh: '낮은 가격순',
        price_level: '가격 수준',
        price_level_share_this_cart: '가격 수준 :',
        price_retail_profit: '',
        printed_invoice: '',
        privacy_policy: '개인정보 정책',
        proceed_payment: '',
        processing: '처리 중',
        processing_autoship: '오토십 (Autoship) 처리',
        processing_first_order: '최초 주문 처리',
        product: '상품',
        products: '상품들',
        product_catalogue: '제품 카탈로그',
        product_catalogue_en: '',
        product_catalogue_link_en: 'https://put-your-link-here',
        product_catalogue_link_native: 'https://put-your-link-here',
        product_catalogue_sg: '',
        product_details: '',
        product_empty: '제품 항목은 필수 입력항목입니다.',
        product_image: '',
        product_info_button: '제품상세정보',
        product_inv: '제품 재고 정보',
        product_is_back_order_item: '제품 아이디 (xxxx)는 백오더 품목입니다.',
        product_name: '제품명',
        product_not_available: '',
        professional_income: '근로소득',
        profile: '계기반',
        profile_name: '프로필명',
        profile_name_cannot_be_empty: '프로필명은 필수 입력항목입니다.',
        profile_name_empty: '프로필 이름은 필수 입력사항입니다.',
        program_and_promotion_summary: '',
        promotion: '프로모션',
        promotions: '프로모션',
        promotionsidn: '',
        province: '광역시/도',
        province_id: '주 (州) 또는 광역시',
        province_is_empty: '',
        prr: 'PR',
        prs: 'PS',
        psv: 'PSV',
        public_holiday: '',
        purchase_date_is_empty: '',
        purchase_location_is_empty: '',
        pv: 'CV',
        per_pv: 'PV',
        pvhightolow: '',
        pvlowtohigh: 'CV 낮은 순서',
        pv_range: 'CV 범위',
        qrcode_give_link: '고객에게 링크와 QR코드를 제공하십시오.',
        qrcode_give_link_desc:
            '고객은 링크 또는 QR코드를 사용할 수 있습니다. 이 링크를 사용하여회원가입을 하면, 귀하를 인롤러로 하여 고객 역시 귀하 하위로 회원가입이 됩니다. 고객이 쇼핑할 때 이 링크를 사용하는 경우, 귀하 하위의 소매 고객으로 자동 배치됩니다. 고객들이 쇼핑 또는 회원가입을 할 준비가 되었을 때 사용할 수 있도록 충분한 수의 링크와 QR코드를 배포하십시오.',
        qr_code_action_des:
            '최초 쇼핑인 경우, 가입하십시오. 만일 이미 BA ID가 있다면, "로그인"을 클릭하십시오.',
        qty: '수량',
        qualification: '',
        qualified: '',
        qualified_month: '',
        quantity: '수량',
        quarterly_income_tax: '분기별 소득세 신고서에만 해당',
        quarterly_income_tax_return: '분기별 소득세 신고서에만 해당',
        quick_links: '빠른 링크',
        quick_shop: '빠른 쇼핑',
        quick_shopping: '빠른 쇼핑',
        rank: '',
        ranks: '직급',
        rankshort: '직급 상승',
        rank_achievements: '달성 직급',
        ranong_dsc: '',
        ratchaburi_dsc: '',
        rayong_dsc: '',
        rc_ba_id_not_found: '잘못된 추천 코드입니다.',
        rc_referral_code_not_found: '',
        read_more: '더 읽어보기',
        read_more_list: '',
        'real-time volume': '',
        realtime_org: '',
        receipts_supported: '영수증을 첨부하여야 합니다.',
        receipt_amount: '영수액',
        recipient: '',
        recive_email: '이메일 받기',
        recognition: '인정',
        recognitionidn: '',
        redeem: '',
        referral: '추천',
        referral_code: '',
        referral_detail: '추천 세부사항',
        referral_id_ph: '',
        referral_input: '추천인의 아이디, 성명, 이메일 주소 또는 전화번호',
        referrer: '',
        refresh: '',
        refresh_success: '새로고침_성공',
        ref_number: '레퍼런스 번호',
        register: '회원가입',
        registered_in_pasig: '본인은 현재 파시그 시 내에 등록되어 있습니다.',
        registered_in_taguig: '',
        registered_outside_pasig:
            '본인은 현재 파시그 시 외부에 등록되어 있습니다.',
        registered_outside_taguig: '',
        register_account: '계정 등록',
        register_already: '',
        register_click_here: '이곳을 클릭하세요.',
        register_login: '로그인 하세요',
        register_msg1: '디스트리뷰터 아이디는 필수 입력사항입니다.',
        register_msg2: '(등록하기 전 유니시티 아이디가 반드시 있어야 합니다.)',
        register_msg3: '계정이 이미 있습니까?',
        register_to_login: '로그인 하세요',
        registration_details: '',
        registration_packages: '회원가입 패키지',
        registration_ph: '',
        remark: '',
        remember_credit_card: '신용 카드 기억',
        renewal_date: '갱신일',
        renew_now: '',
        reorder: '재주문',
        report: '리포트',
        reports: '리포트',
        request_absence: '',
        required: '[:기입 내용:]을 작성하여야 합니다.',
        required_by_bir: '본 항목은 필리핀 국세청에서 기입합니다.',
        required_middlename: '',
        required_nationality: '',
        required_value: '필요한 값',
        resend_otp_done: '귀하의 OTP 코드가 전송되었습니다.',
        reset: '리셋',
        resources: '',
        retail_customer: '소매가격',
        retail_price: '',
        retail_profit: '',
        retired: '무실적 해지',
        return: '돌아가기',
        'revolutionary drink': '',
        reward: '보상',
        rewardidn: '',
        reward_trip: '',
        'roi-ed_dsc': '',
        sakonnakorn_dsc: '',
        sales: '매출',
        sales_tools: '판매 도구',
        sameday: '당일 배송',
        same_address: '동일한 주소 정보를 사용하세요.',
        same_payment:
            '첫 주문과 오토십 (Autoship) 주문에 대해 동일한 지불 방법을 사용합니다.',
        same_registered_address:
            '필리핀 국세청에 등록된, 귀하의 주소가 아래 주소와 동일합니까?',
        'samuthprakarn_k.mittira_dsc': '',
        samuthsakhon_dsc: '',
        saturday: '토요일',
        save: '저장',
        saved_address: '저장된 주소',
        save_success: '',
        save_to_autoship: '오토십 (Autoship)으로 저장하기',
        save_to_autoship_warning1: '제품 아이디',
        save_to_autoship_warning2: '오토십 (Autoship)에는 사용할 수 없습니다.',
        savingspersonal: '개인 저축예금',
        savings_account: '예금 계좌',
        savings_personal: '개인 저축예금',
        search: '검색',
        search_ba_by_number: '',
        search_results: '검색 결과',
        seconds: '초 (sec)',
        secs: '초',
        security_code: '보안 코드',
        select: '선택',
        select_10: '',
        select_8: '',
        select_9: '',
        select_all: '모두 선택하기',
        select_doc_type: '',
        select_gender: '성별을 선택하세요.',
        select_level: '단계 선택',
        select_modal_payment: '',
        select_month: '월 선택',
        select_price: '',
        select_purchase_location: '',
        select_report: '보고서 선택',
        select_shpping_method: '-- 배송 방법 --',
        select_status: '혼인상태를 선택하십시오.',
        select_type: '유형을 선택하세요.',
        seminar: '세미나',
        seminar_points: '',
        seminar_rank: '',
        sending_email: '이메일 발송',
        send_documents: '서류 보내기',
        send_feedback: '의견 보내기',
        september: '구월',
        serial_no_is_empty: '',
        serial_no_must_be_number: '',
        serial_number: '',
        serial_number_warranty: '',
        sgp: '싱가포르',
        sg_exceed_limit_qty: '',
        share: '',
        share_a_cart: '',
        share_a_cart_not_found: '',
        share_carts: '',
        share_this_cart: '이 장바구니 공유하기',
        share_this_cart_adddownline: '',
        share_this_cart_address1: '',
        share_this_cart_address1_placeholder: '',
        share_this_cart_address2: '',
        share_this_cart_address2_placeholder: '',
        share_this_cart_address_add: '',
        share_this_cart_address_book: '',
        share_this_cart_address_edit: '',
        share_this_cart_back: '',
        share_this_cart_choose_province: '',
        share_this_cart_city: '',
        share_this_cart_confirm_close_cancel: '',
        share_this_cart_confirm_close_head: '',
        share_this_cart_confirm_close_ok: '',
        share_this_cart_confirm_close_text: '',
        share_this_cart_delete: '',
        share_this_cart_delivery: '',
        share_this_cart_distributorid: '회원번호',
        share_this_cart_distributorid_placeholder: '',
        share_this_cart_downlines: '',
        share_this_cart_edit: '',
        share_this_cart_editdownline: '',
        share_this_cart_email: '',
        share_this_cart_email_placeholder: '',
        share_this_cart_ex: '',
        share_this_cart_for: '',
        share_this_cart_for_downlines: '',
        share_this_cart_for_preferred_customers: '',
        share_this_cart_for_retail_customers: '',
        share_this_cart_mobile: '',
        share_this_cart_mobilephone_placeholder: '',
        share_this_cart_mobile_placeholder: '',
        share_this_cart_name: '',
        share_this_cart_name_placeholder: '',
        share_this_cart_new_address: '',
        share_this_cart_next: '',
        share_this_cart_optional: '',
        share_this_cart_pickup: '',
        share_this_cart_profile_name: '',
        share_this_cart_retail_customers: '',
        share_this_cart_save: '',
        share_this_cart_save_address: '',
        share_this_cart_select: '',
        share_this_cart_shipping_methods: '배송 방법',
        share_this_cart_shipto: '',
        share_this_cart_zip_code: '',
        share_this_cart_zip_code_placeholder: '',
        share_this_cart_zip_placeholder: '',
        share_via: '~을/를 통해 공유',
        shipment: '배송 방법',
        shipment_6: '모터 배송',
        'shipping service directly to your home': '',
        shipping_address: '배송 주소',
        shipping_desc:
            '배송료 (싱가포르 영토 내에만 해당)\n 실구매액 $350 이상 - 최초 3kg 무료 배송. 3kg 초과분에 대해서는 1kg당 $1\n 실구매액 $350 미만 - 최초 3kg는 $6\n 배송 소요기간 - 주문 접수 완료일로부터 5영업일 이내\n 하기 지역 또는 구역으로 배송하는 경우, 일반 배송료 외에 $15 (재화용역세 7% 별도)의 추가 요금이 부과됩니다.\n 주롱 섬 / 공항화물 / 알프스 아베 (Alps Ave) / PSA 터미널 / SAF 캠프 / 주롱 항 / 센토사 섬 / 교도소 / 싱가포르 테크놀러지스 빌딩 / 조선소\n 요금은 사전 통지 없이 변경될 수 있습니다.',
        shipping_direct: '',
        shipping_method: '배송 방법',
        shipping_warehouse: '',
        ship_address: '배송 주소',
        ship_delivery: '배송',
        ship_from: '',
        ship_to: '운송 수취인',
        shop: '쇼핑하기',
        shopping: '쇼핑',
        shopping_bag: '',
        shopping_details_etc: '',
        shopping_details_invoice: '',
        shopprofile: '구매 프로필',
        shopprofile_edit: '',
        shopprofile_list: '',
        shopprofile_remove_discontinued_product: '',
        shop_categories: '제품',
        shop_now: '',
        shop_remarks_placeholder_ph: '',
        short_name: '',
        show_all: '전체보기',
        show_breadcrumb: '',
        show_card: '카드',
        show_commissions: '커미션 표시',
        show_detail: '세부사항 표시',
        show_genealogy_table: '계보 표 표시',
        show_less: '간략보기',
        show_more: 'Show more',
        show_more_addressbook: '주소록 더보기 ...',
        show_more_commission_month: '',
        show_more_months_comm: '',
        show_more_orders: '더 많은 주문 표시 ...',
        show_table: '표',
        sign_again: '다시 서명하십시오.',
        sign_up: '회원가입',
        sign_up_new: '지금 가입하세요!',
        sign_your_singature: '서명하십시오.',
        since: '가입일자',
        single: '미혼',
        skin_care: '스킨케어',
        skin_treatment: '피부 트리트먼트',
        skip: '건너뛰기',
        skip_this_step: '',
        sms_phone_error_sending:
            '알 수 없는 오류가 발생하여 SMS가 전송되지 않았습니다. 도움이 필요할 경우 고객 서비스에 문의하십시오.',
        sms_phone_exceed_otp:
            '이 계정은 현재 허용된 것보다 많은 수의 OTP 코드를 요청했습니다. 계정을 초기화하려면 고객 서비스에 문의하십시오.',
        sms_phone_incorrect_otp: '잘못된 OTP입니다. 다시 시도하십시오.',
        sms_phone_not_verified:
            '전화번호 ({{전화번호}})가 아직 확인되지 않았습니다. SMS를 통해 OTP 인증코드를 요청하려면 아래 버튼을 클릭하십시오.',
        sms_phone_otp_expired:
            '입력한 OTP 코드가 만료되었습니다. 코드를 다시 입력하거나 새 OTP 코드를 다시 요청하십시오.',
        sngl_product_information: '',
        sngl_warranty_registration: '',
        sns: 'SNS',
        sns_city_label: '',
        sns_city_sg: '',
        sns_mailbox_label: '',
        sns_mailbox_sg: '',
        songkhla_dsc: '',
        sort: '분류',
        sortingfunctions:
            '귀하의 단계가 1단계인 경우, 표 헤더를 클릭하여 데이터를 정렬할 수 있습니다.',
        sortinglvlhint: '행 정렬을 재설정하려면 LVL을 클릭하십시오.',
        sort_by: '정렬 기준',
        sort_by_ov: 'OV',
        specify_business_tax_type: '영업세 유형을 기재하십시오.',
        specify_percentage_tax: '귀하의 비례세를 기재하십시오.',
        specify_source_income: '수입원을 기재하십시오.',
        sponsor: '스폰서 정보',
        sponsor_id: '스폰서 ID',
        sponsor_id_short: '스폰서 ID',
        sponsor_name: '스폰서 성명',
        sponsor_name_short: '스폰서 이름',
        spouse: '배우자',
        spouse_fname: '이름',
        spouse_fullname: '',
        spouse_kname: '일본명',
        spouse_lname: '성',
        sp_downline_customer: '',
        sp_preferred_customer: '',
        sp_retail_customer: '',
        sp_select_customer_lv: '',
        srd: 'SD',
        sriracha_dsc: '',
        srm: 'SM',
        standard_deductions: '표준 공제',
        standard_eductions: '',
        starting_from: '시작:',
        start_date: '시작일',
        start_month: '시작 월',
        state: '구',
        statement: '성명서',
        state_placeholder_au: '',
        state_placeholder_my: '',
        state_placeholder_nz: '',
        status: '회원상태',
        stop_notification: '이 알림 표시 중지',
        storecredit: '매장 크레딧',
        storecreditcard: '매장 신용 카드',
        street: '층, 건물/번지, 구내, 도로명',
        subarea: '부락',
        submit: '제출',
        submit_feedback: '제출',
        submit_receipts_from_business: '',
        submit_sworn_jan_25_tax:
            '매년 1월 25일까지 선서진술서를 제출하시면 원천징수세율을 5%로 감면받으실 수 있습니다.',
        submit_sworn_statement:
            '매년 1월 25일까지 선서진술서를 제출하시면 원천징수세율을 5%로 감면받으실 수 있습니다.',
        submit_your_otp: 'OTP 요청서를 제출하십시오.',
        subscribe: '',
        subtotal: '금액',
        sub_total: '금액',
        success: '달성현황',
        successfully_added_to_cart: '장바구니에 정상적으로 추가되었습니다.',
        successfully_removed_to_cart: '장바구니에서 정상적으로 제거되었습니다.',
        success_change_password:
            '귀하의 비밀번호가 정상적으로 변경되었습니다! 다시 로그인 하세요.',
        success_tracker: '성공 트레커',
        suggestion: '제안사항',
        sukhothai_dsc: '',
        sumitomo_account: '미쓰이 스미토모 은행 주식회사 마루노우치 지점',
        summary: '주문 요약',
        sunday: '일요일',
        'sunday-monday': '일-월요일',
        sun_care: '선 케어 (자외선 차단)',
        suphanburi_dsc: '',
        supplements: '보충제',
        supplement_products: '보충 제품',
        suratthani_pannaporn_dsc: '',
        sure_reset_password:
            '비밀번호를 재설정하려고합니다. 이 사용자의 비밀번호를 재설정 하시겠습니까? 재설정할 경우, 시스템에서 로그아웃 됩니다!',
        suspended: '정지',
        switzerland_seminar1:
            '모든 참가자들은 포인트 적립 첫 달부터 시작하여 자격 기간이 종료될 때까지 최소 200회의 현지 CV를 유지하여야 합니다.',
        switzerland_seminar2:
            "참가자들은 자격 종료 후 행사 기간까지 '활성 상태' (100 CV)를 유지하여야 합니다.",
        sworn_statement: '선서 진술서',
        system_aid_tools: '시스템 지원 도구',
        table: '표',
        table_name: '상품명',
        table_price: '가격',
        table_pv: 'CV',
        tab_notice_autoship: '오토십 (Autoship) 공고문',
        taiwan: '',
        'take a look': '',
        take_home_full_commission:
            '수수료 전액을 공제한 후 집으로 가져가십시오.',
        tak_suttachart_dsc: '',
        target: '목표',
        targeted_packs: '대상 팩',
        tax: '세금',
        taxnews: '',
        tax_file_name: '납세자번호',
        tax_preferences: '세금우대',
        tax_withholding: '원천세',
        tc_desc_express_kr:
            '본 주문과 관련된 국내 (한국) 관세나 기타 세금에 대해 책임을 진다는데 동의합니다. 상기 사이트는 유니시티 싱가포르에서 관리 운영되오니, 제품에 관한 문의, 반품, 교환 및 환불에 대하여 유니시티 싱가포르로 연락주시기 바랍니다.',
        tc_desc_express_limit:
            '본 주문과 관련된 국내 (한국) 관세나 기타 세금에 대해 책임을 진다는데 동의합니다. 상기 사이트는 유니시티 싱가포르에서 관리 운영되오니, 제품에 관한 문의, 반품, 교환 및 환불에 대하여 유니시티 싱가포르로 연락주시기 바랍니다.\n',
        tc_desc_ushop_limit:
            '유니시티에서는 주문 금액을 제한합니다. 이는 면세 배송에 대해 고시된 제한금액을 따르는 것입니다. 그러나 이 주문에 관세가 적용되는 경우, 고객은 해당 관세 또는 기타 세금에 대해 책임을 진다는 데 동의합니다.',
        team: '다운라인',
        tell_us:
            '어느 종류의 피드백이 좋은 평가를 받는다고 생각하는지 알려주시기 바랍니다.',
        tell_us_something: '의견을 남겨주세요.',
        terminated: '해지',
        terms_and_conditions: '약관',
        tfn: '납세자번호 (TFN)',
        tfn_duplicated: '이미 존재하는 납세자번호입니다.',
        tha: '태국',
        thailand: '태국',
        thankyou_180: '',
        thankyou_ortigas: '',
        thankyou_sameday: '',
        thankyou_sameday_2: '',
        thankyou_taguig: '',
        thank_you_feedback: '의견을 남겨주셔서 감사합니다.',
        thb: '태국(바트)',
        'this the opportunity to look forword': '',
        this_opportunity: '',
        th_back_err_lenght: '주민등록증 뒷면의 번호가 유효하지 않습니다.',
        th_ba_not_allow_buy_products: '',
        th_err_lenght: '주민등록번호는 13자리여야 합니다.',
        th_in_processing: '',
        th_qrcode_give_link_desc:
            '고객은 링크 또는 QR코드를 사용할 수 있습니다. 이 링크를 사용하여회원가입을 하면, 귀하를 회원가입자로 하여 고객 역시 귀하 소속으로 회원가입이 됩니다. 고객이 쇼핑할 때 이 링크를 사용하는 경우, 회원가입자로서 귀하와 함께 귀하의 소매 고객으로 자동 배치됩니다. 고객들이 쇼핑 또는 회원가입을 할 준비가 되었을 때 사용할 수 있도록 충분한 수의 링크와 QR코드를 배포하십시오.',
        time: '시각',
        tin_id: '납세자등록번호',
        title_ba: 'BA',
        title_downline: '모든 다운라인',
        title_level: '단계',
        title_ov: 'OV',
        title_perid: '기간',
        title_qualified: '유자격',
        title_rank: '직급',
        title_rank_advances: '직급 상승',
        title_selling:
            '6,500 바트를 초과하거나 Easyship 품목이 포함된 주문은 무료 배송이 가능합니다. 귀하의 주문금액은 지불 하기 전 다시 계산됩니다.',
        tokyo_mitzu_account: '도쿄-미쓰비시 UFJ 은행 아오야마 지점',
        tooltip_deliveryfee_sg: '',
        top: '상단',
        top10: '',
        top20pv: '상위 20 CV',
        top20tv: '상위 20 TV',
        topcategories: '상위 카테고리',
        topten: '상위 10개',
        top_products: '',
        total: '',
        total_active_distributor: '활동 중인 디스트리뷰터 수',
        total_amount: '총액',
        total_amount_due: '총 금액',
        total_distributor: '총 디스트리뷰터 수',
        total_points: '',
        total_pv: '총 CV',
        total_seminar: '합계',
        track_your_rack: '귀하의 직급 상승현황을 확인하세요.',
        trip: '여행/출장',
        trip_country: '상하이',
        trip_country_korea: '대한민국 서울',
        trip_country_th_bangkok: '방콕',
        trip_country_th_phuket: '푸켓',
        trip_korea: 'Global Leadership & Innovation Conference 2562',
        trip_title: '유니시티 리더십 세미나 2018',
        'tuesday-friday': '화-금요일',
        tv: 'TV',
        twn: '',
        type: '유형',
        type_to_search: '검색하려면 입력하십시오.',
        ubolratchathani_tao_dsc: '',
        udornthani_manassanan_dsc: '',
        umag: '',
        unavailable: '가능하지않은',
        under_maintenance: '보수 작업중',
        unicity: '유니시티',
        unicity_japan: '유니시티 재팬 주식회사',
        unicity_ph_title: '',
        unlink_from_facebook: '페이스 북에서 링크 해제',
        unpaid: '미지급',
        'update-info-description': '',
        'update-info-mobilephone': '',
        'update-info-title': '',
        'update-info-whatsapp': '',
        'update-info-workphone': '',
        update_cart: '장바구니 업데이트',
        update_info: '',
        upline: 'Upline',
        upline_invalid: '스폰서는 인롤러 조직에 소속되어 있지 않습니다.',
        uploaddocument: '보내다',
        upload_back_nric_description:
            '주민등록증 뒷면을 한 페이지에 업로드 하십시오.',
        upload_doc_btn: '',
        upload_doc_head: '',
        upload_doc_menu: '',
        upload_govid: '주민등록증 앞면',
        upload_govid_back: '주민등록증 뒷면',
        upload_govid_photo: '사진과 주민등록증',
        upload_idcard_copy: '주민등록증 사본 업로드 하기*',
        upload_nric_copy: '싱가포르 주민등록증 사본 업로드 하기*',
        upload_nric_description:
            '주민등록증 앞면을 한 페이지에 업로드 하십시오.',
        upload_nric_whitelist:
            '최대 크기가 5MB인 JPG, JPEG, PNG 파일만 허용됩니다.',
        upload_portrait_description: '얼굴 사진 이미지를 업로드 하십시오.',
        upload__two_ids: '유효한 신분증 2개를 업로드 하십시오.',
        usa: '미국',
        username: '회원번호',
        users: '사용자',
        user_dont_have_leg: '사용자에게 해당 레그가 없습니다.',
        user_type_denied: '해당 회원번호로는 서비스가 불가합니다',
        u_all_api_error_ordercalc_unknow:
            '예기치 않은 오류가 발생했습니다.이 페이지를 다시 새로 고침하거나 직원에게 문의하십시오. (CODE: 502)',
        u_all_autoship_add_autoship: '',
        u_all_autoship_date_created: '',
        u_all_autoship_edit_autoship: '',
        u_all_autoship_email_dear: '',
        u_all_autoship_email_dear2: '',
        u_all_autoship_email_order_details: '',
        u_all_autoship_email_preheader: '',
        u_all_autoship_email_subject: '',
        u_all_autoship_email_subtitle: '',
        u_all_autoship_email_subtitle2: '',
        u_all_autoship_email_title: '',
        u_all_autoship_frequency: '',
        u_all_autoship_last_run_date: '',
        u_all_autoship_order_number: '',
        u_all_autoship_save_card: '',
        u_all_back: '뒤로',
        u_all_balance_error_unknow: '',
        u_all_bank_account: '',
        u_all_bank_type: '계좌 유형',
        u_all_bmi_acceptable: '',
        u_all_bmi_age: '',
        u_all_bmi_athletes: '',
        u_all_bmi_back: '',
        u_all_bmi_body_fat: '',
        u_all_bmi_body_fat_cate: '',
        u_all_bmi_body_fat_gain: '',
        u_all_bmi_body_fat_given_age: '',
        u_all_bmi_body_fat_lean: '',
        u_all_bmi_body_fat_lose: '',
        u_all_bmi_body_fat_mass: '',
        u_all_bmi_calculate: '',
        u_all_bmi_cat_normal_range: '',
        u_all_bmi_cat_overweight: '',
        u_all_bmi_cat_overweight_level1: '',
        u_all_bmi_cat_overweight_level2: '',
        u_all_bmi_cat_overweight_level3: '',
        u_all_bmi_cat_thin: '',
        u_all_bmi_cm: '',
        u_all_bmi_descript_acceptable: '',
        u_all_bmi_descript_athletes: '',
        u_all_bmi_descript_essential_fat: '',
        u_all_bmi_descript_fitness: '',
        u_all_bmi_descript_less_fat: '',
        u_all_bmi_descript_obese: '',
        u_all_bmi_detail: '',
        u_all_bmi_detail_result: '',
        u_all_bmi_essential_fat: '',
        u_all_bmi_feet: '',
        u_all_bmi_fitness: '',
        u_all_bmi_gender: '',
        u_all_bmi_header: '',
        u_all_bmi_height: '',
        u_all_bmi_hip: '',
        u_all_bmi_inch: '',
        u_all_bmi_kg: '',
        u_all_bmi_man: '',
        u_all_bmi_message_result_1: '',
        u_all_bmi_message_result_2: '',
        u_all_bmi_message_validate: '',
        u_all_bmi_metric_units: '',
        u_all_bmi_neck: '',
        u_all_bmi_normal_weight: '',
        u_all_bmi_obese: '',
        u_all_bmi_obesity_1: '',
        u_all_bmi_obesity_2: '',
        u_all_bmi_obesity_3: '',
        u_all_bmi_overweight: '',
        u_all_bmi_pounds: '',
        u_all_bmi_reset: '',
        u_all_bmi_result_bmi: '',
        u_all_bmi_result_category: '',
        u_all_bmi_select_gender: '',
        u_all_bmi_title_body_mass_index: '',
        u_all_bmi_title_body_mass_man: '',
        u_all_bmi_title_body_mass_women: '',
        u_all_bmi_under_weight: '',
        u_all_bmi_units: '',
        u_all_bmi_username: '',
        u_all_bmi_us_units: '',
        u_all_bmi_waist: '',
        u_all_bmi_weight: '',
        u_all_bmi_woman: '',
        u_all_bmi_year: '',
        u_all_cancel: '취소',
        u_all_card_cvv: 'CVV',
        u_all_cart_add_more_products: '더 많은 제품 추가',
        u_all_cart_balance: 'Credit',
        u_all_cart_btn_back: '뒤로',
        u_all_cart_btn_checkout: '결제',
        u_all_cart_btn_clear_all: '모두 지우기',
        u_all_cart_btn_share_cart: '이 장바구니 공유하기',
        u_all_cart_delete_item: '이 항목을 가방에서 제거 하시겠습니까?',
        u_all_cart_download_qr_code: 'QR코드 다운로드 하기',
        u_all_cart_modal_remove_description:
            '선택한 항목이 삭제됩니다. 계속 하시겠습니까?',
        u_all_cart_modal_remove_no: '아니오',
        u_all_cart_modal_remove_yes: '예',
        u_all_cart_pv: 'CV',
        u_all_cart_total: '합계:',
        u_all_cashcoupon_not_enough: '',
        u_all_change_password_cancel: '취소',
        u_all_change_password_error_message: '실패한. 다시 시도하십시오.',
        u_all_change_password_error_message2: '잘못된 비밀번호',
        u_all_checkingpersonal: '개인 수표',
        u_all_confirmation_bank_account: '',
        u_all_confirmation_email_approve_btn: '',
        u_all_confirmation_email_autologin_text: '로그인',
        u_all_confirmation_email_by_cashcoupon: '',
        u_all_confirmation_email_dear: '',
        u_all_confirmation_email_dear2: '',
        u_all_confirmation_email_link_label: 'Link',
        u_all_confirmation_email_no_submit_bank_account: '',
        u_all_confirmation_email_payment_label: '',
        u_all_confirmation_email_pickup_code: '',
        u_all_confirmation_email_preheader: '',
        u_all_confirmation_email_subtitle: '',
        u_all_confirmation_email_subtitle2: '',
        u_all_confirmation_email_title: '',
        u_all_confirmation_email_to_cs_subject: '',
        u_all_confirmation_email_username: '회원번호',
        u_all_confirmation_email_yes_submit_bank_account: '',
        u_all_continue: '계속',
        u_all_cookie_consent_btn_ok: '확인',
        u_all_co_app: '',
        u_all_cpv: 'CPV',
        u_all_credit_card: '신용 카드',
        u_all_customlink_cancel: '',
        u_all_customlink_error_discontinue: '',
        u_all_customlink_modal_no: '',
        u_all_customlink_modal_text_delete: '',
        u_all_customlink_modal_yes: '',
        u_all_customlink_od_customer_id: '회원번호',
        u_all_customlink_ok: '확인',
        u_all_customlink_orderdetail_ba_id_not_found: '',
        u_all_customlink_orderdetail_referral_id: '',
        u_all_customlink_posback_distributor_id: '회원번호',
        u_all_customlink_prodeed_to_payment: '',
        u_all_customlink_receivedEmail_no: '',
        u_all_customlink_receivedEmail_yes: '',
        u_all_customlink_receivedInvoice_no: '',
        u_all_customlink_receivedInvoice_yes: '',
        u_all_customlink_save: '',
        u_all_customlink_total: '',
        u_all_customlink_total_price: '',
        u_all_customlink_total_pv: '',
        u_all_customlink_unit_price: '',
        u_all_dashboard_afp: 'AFP',
        u_all_dashboard_cumulative: '누적',
        u_all_dashboard_done: '확인',
        u_all_dashboard_filtered_no_data: '볼륨이 0 인 다운 라인이 숨겨집니다.',
        u_all_dashboard_genealogy_hide_al_column: '활성 표시',
        u_all_dashboard_genealogy_no_breadcrumb_history: '역사 없음',
        u_all_dashboard_genealogy_open_tree_view: '트리보기로 열기',
        u_all_dashboard_genealogy_show_apv: 'APV 표시',
        u_all_dashboard_genealogy_show_cpv: 'CPV 표시',
        u_all_dashboard_genealogy_show_firstname: '이름 만 표시',
        u_all_dashboard_genealogy_show_fsb: 'FSB 표시',
        u_all_dashboard_genealogy_sort_by_ov: 'OV 별 정렬',
        u_all_dashboard_genealogy_tree: '다운 라인 테이블',
        u_all_dashboard_hide_zero_volume: '무실적 다운라인 숨기기',
        u_all_dashboard_latest_order: '최근 주문',
        u_all_dashboard_more_detail: '자세한 세부 사항',
        u_all_dashboard_no_genealogy_downline: '다운라인 없음',
        u_all_dashboard_no_order_in_last_2_months:
            '지난 2 개월 동안 주문이 없습니다.',
        u_all_dashboard_no_order_in_this_months: '이번 달에는 주문이 없습니다.',
        u_all_dashboard_open_bookmark: '북마크 관리',
        u_all_dashboard_reset: '초기화',
        u_all_dashboard_seat: '좌석',
        u_all_dashboard_seats: '좌석',
        u_all_dashboard_seminars: '세미나',
        u_all_dashboard_video: '비디오',
        u_all_delivery: '',
        u_all_email_idverification_offline_button: '',
        u_all_email_idverification_offline_link: '',
        u_all_email_idverification_online_button: '',
        u_all_email_idverification_text: '',
        u_all_employee_purchased_exceed_per_item: '',
        u_all_employee_purchased_exceed_quota: '',
        u_all_enrollpostback_or: '',
        u_all_enrollpostback_selfie_by_myself: '',
        u_all_enrollpostback_send_mail: '',
        u_all_enrollpostback_sorry: '',
        u_all_enrollpostback_sorry_subtitle_1: '',
        u_all_enrollpostback_sorry_subtitle_2: '',
        u_all_enrollpostback_thankyou: '',
        u_all_enrollpostback_thankyou_subtitle_1: '',
        u_all_enrollpostback_thankyou_subtitle_2: '',
        u_all_enroll_address: '',
        u_all_enroll_back_to_menu: '',
        u_all_enroll_bank_account: '',
        u_all_enroll_bank_account_later: '',
        u_all_enroll_bank_account_name: '',
        u_all_enroll_bank_account_now: '',
        u_all_enroll_bank_account_number: '은행 계좌번호',
        u_all_enroll_bank_account_type: '',
        u_all_enroll_bank_code: '은행 코드',
        u_all_enroll_bank_name: '은행명',
        u_all_enroll_bank_type: '계좌 유형',
        u_all_enroll_birthday: '생년월일',
        u_all_enroll_branch_code: '지점 코드',
        u_all_enroll_branch_name: '지점명',
        u_all_enroll_cancel: '취소',
        u_all_enroll_coapplicant_title: '공동 신청인',
        u_all_enroll_confirm_password: '비밀번호 확인',
        u_all_enroll_contact_address: '연락처',
        u_all_enroll_delivery: '배송 옵션',
        u_all_enroll_delivery_fee: '',
        u_all_enroll_distributor_number: '사용자 이름',
        u_all_enroll_email: '이메일',
        u_all_enroll_enroller: '',
        u_all_enroll_enroll_detail: '등록 세부 정보',
        u_all_enroll_error_icfile: '',
        u_all_enroll_error_icfile_bb: '통장 사진은 비워 둘 수 없습니다.',
        u_all_enroll_error_icfile_bc: '',
        u_all_enroll_err_bookbank_account_name: '',
        u_all_enroll_err_bookbank_bankname: '',
        u_all_enroll_err_bookbank_bankno: '',
        u_all_enroll_err_upload_bookbank_photo: '',
        u_all_enroll_ethnic_group: '',
        u_all_enroll_example_of_id_link: '',
        u_all_enroll_failed_massage: '',
        u_all_enroll_firstnameen: '이름 (영문)',
        u_all_enroll_firstnameth: '',
        u_all_enroll_free: '',
        u_all_enroll_fullname: '',
        u_all_enroll_fullnamejp: '일본명',
        u_all_enroll_fullnametw: '',
        u_all_enroll_gender: '성별',
        u_all_enroll_government_id_label: '',
        u_all_enroll_gov_id: '',
        u_all_enroll_id: '',
        u_all_enroll_information: '정보',
        u_all_enroll_invalid_sponser: '잘못된 스폰서입니다.',
        u_all_enroll_i_agree_company: '회사의 약관에 동의합니다.',
        u_all_enroll_lastnameen: '성 (영문)',
        u_all_enroll_lastnameth: '',
        u_all_enroll_marital_status: '결혼여부',
        u_all_enroll_middlenameen: '',
        u_all_enroll_mobile: '',
        u_all_enroll_mobilephone: '휴대전화번호',
        u_all_enroll_name: '',
        u_all_enroll_nric_id: '',
        u_all_enroll_nric_id_card: '',
        u_all_enroll_ok: '확인',
        u_all_enroll_ordersummary: '회원가입 정보 요약',
        u_all_enroll_order_number: '주문 번호',
        u_all_enroll_password: '암호',
        u_all_enroll_password_confirm: '비밀번호 확인',
        u_all_enroll_payment: '',
        u_all_enroll_payment_failed: '',
        u_all_enroll_payment_result: '회원가입 결과',
        u_all_enroll_payment_resume: '',
        u_all_enroll_payment_resume_text: '결제에 문제가 발생했습니다',
        u_all_enroll_payment_resume_title: '결제 실패',
        u_all_enroll_payment_thank_you: '',
        u_all_enroll_please_select_ethnicity: '',
        u_all_enroll_please_select_marital: '',
        u_all_enroll_pv_month: '',
        u_all_enroll_result_title: '회원가입 결과',
        u_all_enroll_select_bank_type: '',
        u_all_enroll_select_gender: '',
        u_all_enroll_set: '확인',
        u_all_enroll_shipment: '배송 방법',
        u_all_enroll_sponser: '',
        u_all_enroll_sponsor: '스폰서',
        u_all_enroll_success_massage: '',
        u_all_enroll_terms_and_conditions: '약관',
        u_all_enroll_term_and_conditions: '',
        u_all_enroll_term_and_condition_head: '약관',
        u_all_enroll_title_select_starter_kit: '스타터 세트 선택',
        u_all_enroll_upload_book_bank: '',
        u_all_enroll_upload_co_applicant_no: '',
        u_all_enroll_upload_co_applicant_yes: '',
        u_all_enroll_upload_image_fix_size_20: '',
        u_all_enroll_validate_information_bank_account: '',
        u_all_enroll_verified: '확인 됨',
        u_all_enroll_verify_id_card: '',
        u_all_enroll_verify_sponsor: '스폰서 확인',
        u_all_enroll_zipcode: '우편번호',
        u_all_error_not_enough_inventory: '재고가 충분하지 않습니다.',
        u_all_error_sku_not_supported: '',
        u_all_err_idcard_duplicate: '',
        u_all_example: '예:',
        u_all_expire_date: '',
        u_all_feedback: '보내다',
        u_all_feedback_btn_back: '뒤로',
        u_all_feedback_btn_send: '보내다',
        u_all_feedback_comment: '메시지',
        u_all_feedback_exp: '',
        u_all_feedback_send_success: '메세지가 성공적으로 전송되었습니다',
        u_all_feedback_subtitle:
            '어느 종류의 피드백이 좋은 평가를 받는다고 생각하는지 알려주시기 바랍니다',
        u_all_feedback_title: '의견 보내기',
        u_all_female: '여성',
        u_all_filter_choose_view: '다운라인 보기',
        u_all_filter_tools: '',
        u_all_footer_app_download: '다운로드',
        u_all_footer_change_language: '',
        u_all_footer_phone: '전화: {{number}}',
        u_all_footer_section_a_topic: '당사와 연락하십시오.',
        u_all_footer_section_b_topic: '모바일앱',
        u_all_footer_section_c_topic: '빠른 링크',
        u_all_footer_section_d_topic: '도움',
        u_all_footer_title_newsletter: '',
        u_all_genealogy_card_view: '상세',
        u_all_genealogy_cpv: 'CPV',
        u_all_genealogy_dashboard_view: '나의실적',
        u_all_genealogy_end_of_content: '-완료-',
        u_all_genealogy_level: '간편',
        u_all_genealogy_level_view: '간편',
        u_all_genealogy_refresh: '새로고침',
        u_all_genealogy_table_view: '표',
        u_all_genealogy_view: '전망',
        u_all_header_cart_total_pv: 'CV',
        u_all_header_language_name_cn: '',
        u_all_header_language_name_en: 'ENGLISH',
        u_all_header_language_name_hk: '',
        u_all_header_language_name_jp: '',
        u_all_header_language_name_kr: '한국어',
        u_all_header_language_name_th: '',
        u_all_header_language_name_vn: '',
        u_all_home_banner: '',
        u_all_home_button_link: '',
        u_all_home_disclaimer_body1: '',
        u_all_home_disclaimer_body2: '',
        u_all_home_disclaimer_body3: '',
        u_all_home_disclaimer_body4: '',
        u_all_home_disclaimer_header1: '',
        u_all_home_disclaimer_header2: '',
        u_all_home_disclaimer_header3: '',
        u_all_home_disclaimer_header4: '',
        u_all_home_disclaimer_icon1: '',
        u_all_home_disclaimer_icon2: '',
        u_all_home_disclaimer_icon3: '',
        u_all_home_disclaimer_icon4: '',
        u_all_home_header_1: '',
        u_all_home_header_2: '',
        u_all_home_send_to: '',
        u_all_home_text_card: '',
        u_all_home_unicity_description: '',
        u_all_home_unicity_title: '',
        u_all_home_video_banner: '',
        u_all_hydra_error_bad_request: '',
        u_all_idverification_anyquestion: '',
        u_all_idverification_bank: '',
        u_all_idverification_bank_acc_name: '',
        u_all_idverification_bank_acc_number: '',
        u_all_idverification_bank_acc_type: '',
        u_all_idverification_bank_name: '',
        u_all_idverification_click_here: '',
        u_all_idverification_congratulations: '',
        u_all_idverification_congratulations_email_preheader: '',
        u_all_idverification_congratulations_subtitle_1: '',
        u_all_idverification_congratulations_subtitle_2: '',
        u_all_idverification_customer_id: '회원번호',
        u_all_idverification_distributor_id: '회원번호',
        u_all_idverification_email_distributor_id: '회원번호',
        u_all_idverification_email_preheader: '',
        u_all_idverification_email_subject: '',
        u_all_idverification_enter_bank_acc_now: '',
        u_all_idverification_enter_otp: '',
        u_all_idverification_enter_promptpay: '',
        u_all_idverification_have_been_verified: '',
        u_all_idverification_here: '',
        u_all_idverification_identity_verification: '',
        u_all_idverification_id_card: '신분증',
        u_all_idverification_lineid: '',
        u_all_idverification_line_1: '',
        u_all_idverification_line_2: '',
        u_all_idverification_line_link: '',
        u_all_idverification_mobile_number: '',
        u_all_idverification_next: '',
        u_all_idverification_no_government_id: '',
        u_all_idverification_otp_expire_in: '',
        u_all_idverification_please_enter_bank_acc_name: '',
        u_all_idverification_please_enter_bank_acc_number: '',
        u_all_idverification_please_enter_bank_acc_type: '',
        u_all_idverification_please_select_bank_name: '',
        u_all_idverification_please_upload_book_bank: '',
        u_all_idverification_reference_id: '',
        u_all_idverification_reference_not_found: '',
        u_all_idverification_request_new_otp: '',
        u_all_idverification_request_otp_code: '',
        u_all_idverification_resolution_too_small:
            '이미지 크기가 너무 작고 (너비 320px 이상) (높이 720px 이상)',
        u_all_idverification_saving_account: '',
        u_all_idverification_selfie: '',
        u_all_idverification_selfie_line_1: '',
        u_all_idverification_selfie_line_2: '',
        u_all_idverification_selfie_with_idcard: '',
        u_all_idverification_send_bank_acc_later: '',
        u_all_idverification_sms: '',
        u_all_idverification_success_icon: '',
        u_all_idverification_success_text: '',
        u_all_idverification_thankyou: '',
        u_all_idverification_thankyou_subtitle_1: '',
        u_all_idverification_thankyou_subtitle_2: '',
        u_all_internal_server_error: '',
        u_all_jp_err_lenght: '신청확인번호는 9자리여야 합니다.',
        u_all_jp_err_no: '유효하지 않은 신청확인번호입니다.',
        u_all_jp_err_number: '신청확인번호는 숫자로만 구성되어 있어야 합니다.',
        u_all_jp_err_space: '신청확인번호에는 공백이 없어야 합니다.',
        u_all_link_order_number: '',
        u_all_male: '남성',
        u_all_marketing_leaflet: '',
        u_all_marketing_picture: '',
        u_all_marketing_presentation: '',
        u_all_marketing_video: '',
        u_all_married: '기혼',
        u_all_menu_account: '',
        u_all_menu_login_logout: '',
        u_all_menu_notifications: '알림',
        u_all_menu_settings: '설정',
        u_all_nationality: '',
        u_all_news_announcements: '',
        u_all_news_closing: '',
        u_all_news_download: '',
        u_all_news_event: '',
        u_all_news_promotions: '',
        u_all_news_recognition: '',
        u_all_news_reward: '',
        u_all_od_address: '주소',
        u_all_od_address_history_btn_new_address: '새 주소 추가',
        u_all_od_address_history_change: '변경',
        u_all_od_address_history_label_new_address: '새 주소 추가',
        u_all_od_address_history_placeholder: '주소를 선택하세요',
        u_all_od_address_history_selected_template:
            '# {{shipTo.name}}\n{{shipTo.address1}}, {{shipTo.address2}}, {{shipTo.zip}}',
        u_all_od_address_history_title: '주소를 선택하세요',
        u_all_od_address_line1: '',
        u_all_od_address_line2: '',
        u_all_od_btn_back: '뒤로',
        u_all_od_btn_cancel: '취소',
        u_all_od_btn_next: '계속',
        u_all_od_btn_ok: '확인',
        u_all_od_checkbox_email_confirmation:
            '주문확인 이메일을 받으시겠습니까?',
        u_all_od_checkbox_invoice_confirmation:
            '이 주문과 함께 인쇄 된 인보이스 보내기',
        u_all_od_city: '시티',
        u_all_od_comment: '논평',
        u_all_od_country: '',
        u_all_od_district: '',
        u_all_od_email: '이메일',
        u_all_od_error_referral_id_empty: '',
        u_all_od_error_referral_id_invalid: '',
        u_all_od_error_referral_id_invalid_status: '',
        u_all_od_fullname: '',
        u_all_od_header_aramex_form: '',
        u_all_od_header_delivery_form: '',
        u_all_od_header_pickup_form: '',
        u_all_od_mobile: '전화번호',
        u_all_od_name: '이름',
        u_all_od_pickup_address_hanoi: '',
        u_all_od_pickup_address_hochiminh: '',
        u_all_od_province: '',
        u_all_od_referral_id: '',
        u_all_od_renew_title: '',
        u_all_od_searchzip_error_length: '',
        u_all_od_searchzip_error_required: '',
        u_all_od_searchzip_found_items: '',
        u_all_od_searchzip_header: '',
        u_all_od_searchzip_notfound: '',
        u_all_od_section_title_shipping_methods: '배송 방법',
        u_all_od_section_title_shipping_warehouse: '배송 창고',
        u_all_od_shipping_aramex: '',
        u_all_od_shipping_delivery: '택배',
        u_all_od_shipping_pickup: '픽업',
        u_all_od_sns1: '',
        u_all_od_sns2: '',
        u_all_od_state: '',
        u_all_od_title: '',
        u_all_od_village: '마을',
        u_all_od_zip: '',
        u_all_od_zip_search: '검색',
        u_all_ohd_bill_to: '청구 대상',
        u_all_ohd_creator: '머리 글자',
        u_all_ohd_date_created: '주문 날짜',
        u_all_ohd_details_delivery_fee: '배달비',
        u_all_ohd_details_purchase_from: '에서 배송하다',
        u_all_ohd_details_purchase_method: '구매 방법',
        u_all_ohd_details_subtotal: '소계',
        u_all_ohd_details_tax_amount: '큰 통',
        u_all_ohd_details_total: '총액',
        u_all_ohd_details_total_pv: '총 CV',
        u_all_ohd_item_code: '코드: ',
        u_all_ohd_order_number: '주문 번호',
        u_all_ohd_period: 'period',
        u_all_ohd_pv: 'CV',
        u_all_ohd_ship_to: '배송지',
        u_all_ohd_ship_to_phone: '연락처',
        u_all_ohd_subtitle_count_unit: '항목 #S',
        u_all_ohd_title: '주문: {{order_id}}',
        u_all_ohd_tracking_number: '추적 번호',
        u_all_ohm_test: 'Adasd',
        u_all_oh_title: '주문 내역',
        u_all_ok: '확인',
        u_all_orderhistory_do_no: '추적 번호 :',
        u_all_orderhistory_driver_name: '드라이버 이름 :',
        u_all_orderhistory_driver_no: '드라이버 번호 :',
        u_all_orderhistory_driver_tel: '전화 :',
        u_all_orderhistory_on_processing: '처리 중',
        u_all_orderhistory_order_id: '주문 번호 :',
        u_all_orderhistory_order_tracking: '주문 추적',
        u_all_orderhistory_timeline: '주문 추적 일정',
        u_all_orderhistory_title_modal: '주문 추적',
        u_all_os_address_template:
            '{{shipTo.address1}}, {{shipTo.address2}}, {{shipTo.zip}}',
        u_all_os_arbalance_title: '',
        u_all_os_back_to_top: 'Back to top',
        u_all_os_btn_back: '뒤로',
        u_all_os_btn_cancel: '취소',
        u_all_os_btn_ok: '확인',
        u_all_os_btn_payment: '주문 확인',
        u_all_os_btn_payment_again: '다시 시도하십시오',
        u_all_os_btn_payment_duplicate: '다시 주문',
        u_all_os_description_product_price: '',
        u_all_os_details_address: '',
        u_all_os_details_ba_id: '',
        u_all_os_details_confirm_email: '',
        u_all_os_details_confirm_invoice: '',
        u_all_os_details_credit_balance: '',
        u_all_os_details_credit_diff: '',
        u_all_os_details_credit_modal_error_invalid_number:
            'Please enter a valid number',
        u_all_os_details_credit_modal_subtitle: '',
        u_all_os_details_credit_modal_title: '',
        u_all_os_details_credit_spend: '',
        u_all_os_details_delivery_time: '배송 소요기간',
        u_all_os_details_delivery_fee: '배송료',
        u_all_os_details_email: '',
        u_all_os_details_login_id: '로그인 아이디',
        u_all_os_details_mobile: '연락처',
        u_all_os_details_more_info: '추가 정보',
        u_all_os_details_name: '',
        u_all_os_details_period: 'CV 월',
        u_all_os_details_product_price: '',
        u_all_os_details_referral_id: '',
        u_all_os_details_shipping_method: '배송 방법',
        u_all_os_details_sns: '',
        u_all_os_details_total: '총 가격',
        u_all_os_details_total_pv: '총 CV',
        u_all_os_details_warehouse: '창고',
        u_all_os_item_code: '코드: ',
        u_all_os_payment_order_placed: '',
        u_all_os_payment_title: '',
        u_all_os_pv: '{{pv}} CV',
        u_all_os_pv_unit: 'CV',
        u_all_os_renew_title: '',
        u_all_os_term_condition_text_link: '',
        u_all_os_title: '주문 요약',
        u_all_payment_failed_if_error_persists: '',
        u_all_payment_gcash_img: '',
        u_all_payment_gcash_text_info: '',
        u_all_payment_gcash_text_info_url: '',
        u_all_payment_gcash_title: '',
        u_all_payment_response_fail_subtitle: '결제에 문제가 발생했습니다',
        u_all_payment_response_fail_title: '결제 실패',
        u_all_payment_response_message_template: '',
        u_all_payment_response_order_id: '주문 번호:',
        u_all_payment_response_social_facebook: 'Facebook:',
        u_all_payment_response_social_line: 'LINE ID:',
        u_all_payment_response_subtitle: '주문이 성공적으로 완료되었습니다',
        u_all_payment_response_title: '감사합니다',
        u_all_payment_sub_title: '',
        u_all_payment_text_credit_card: '',
        u_all_payment_title: '',
        u_all_pickup_hanoi: '',
        u_all_pickup_hochiminh: '',
        u_all_please_agree_terms_and_conditions: '',
        u_all_rank_dst: '',
        u_all_rank_fo: '회원',
        u_all_rank_pc: '',
        u_all_region_portal_btn_back: '뒤로',
        u_all_region_portal_title: '국적선택',
        u_all_renewal_please_select_renewal_set: '',
        u_all_renewal_title: '',
        u_all_renewal_title_initial_order: '추가 구매',
        u_all_renewal_title_select_renewal: '',
        u_all_renew_back_to_ushop: '',
        u_all_renew_cancel: '취소',
        u_all_renew_none: '',
        u_all_renew_od_address: '',
        u_all_renew_od_address_history_change: '변경',
        u_all_renew_od_address_history_label_new_address: '새 주소 추가',
        u_all_renew_od_address_history_placeholder: '주소를 선택하세요',
        u_all_renew_od_address_history_title: '주소를 선택하세요',
        u_all_renew_ok: '확인',
        u_all_renew_order_number: '주문 번호',
        u_all_savingspersonal: '개인 저축예금',
        u_all_search_marketing_artworks_not_found: '',
        u_all_seminars_leadership_seminars: '',
        u_all_seminars_time_interval: '',
        u_all_seminars_time_range: '',
        u_all_seminar_net_amount: '그물',
        u_all_settings: '설정',
        u_all_settings_account: '계정',
        u_all_settings_change_email: '이메일을 바꾸다',
        u_all_settings_change_language: '언어 변경',
        u_all_settings_change_password: '비밀번호 변경',
        u_all_settings_darkmode: '',
        u_all_settings_distributor_id: '회원번호',
        u_all_settings_edit_profile_picture: '프로필 사진 편집',
        u_all_settings_email: '이메일',
        u_all_settings_email_error: '잘못된 이메일',
        u_all_settings_hide: '',
        u_all_settings_language: '',
        u_all_settings_new_email_address: '새 이메일 주소',
        u_all_settings_notifications: '',
        u_all_settings_show: '',
        u_all_settings_submit: '제출',
        u_all_settings_thank_you: '성공적으로 업데이트되었습니다.',
        u_all_setting_ok: '확인',
        u_all_sharecart_option_customers: '',
        u_all_sharecart_option_downline: '',
        u_all_sharecart_option_enroll: '',
        u_all_sharecart_share_body: '',
        u_all_sharecart_share_title: '',
        u_all_sharecart_with: '',
        u_all_share_cart_title: '',
        u_all_shopping_no_item: '항목 없음',
        u_all_shop_category: '카테고리',
        u_all_shop_category_all: '전체',
        u_all_shop_category_cart: '',
        u_all_shop_category_featured: 'FEATURED',
        u_all_shop_category_promotion: '프로모션',
        u_all_shop_price_high: '',
        u_all_shop_price_low: '',
        u_all_shop_product_id: '코드: {{id}}',
        u_all_shop_product_information: '추가 정보',
        u_all_shop_product_search_placeholder: '검색',
        u_all_shop_pv: '{{pv}} CV',
        u_all_shop_pv_high: '',
        u_all_shop_pv_low: '',
        u_all_shop_search_placeholder: '검색',
        u_all_shop_sort: '분류',
        u_all_shop_sort_alphabet: 'A-Z',
        u_all_shop_sort_name: '상품명',
        u_all_shop_sort_price: '가격',
        u_all_shop_sort_pv: 'CV',
        u_all_shop_sort_suggestion: '기본',
        u_all_sidebar_catalogue_sg_url: '',
        u_all_sidebar_contact_chat_with_us: '',
        u_all_sidebar_contact_send_feedback: '',
        u_all_sidebar_enroll_idverification: '',
        u_all_sidebar_magazine_sg_url: '',
        u_all_sidebar_marketing_artworks: '',
        u_all_single: '미혼',
        u_all_social_cancel: '',
        u_all_social_ok: '확인',
        u_all_social_payment_result: '',
        u_all_social_product_price: '',
        u_all_social_send_confirmation: '',
        u_all_submit_warranty_big_address: '',
        u_all_submit_warranty_member_id: '',
        u_all_submit_warranty_preheader: '',
        u_all_submit_warranty_serial_no: '',
        u_all_submit_warranty_subject: '',
        u_all_submit_warranty_subtitle: '',
        u_all_submit_warranty_title: '',
        u_all_tag_color_back_order: '#6FCF97',
        u_all_tag_color_hot: '#FF5D5D',
        u_all_tag_color_new: '#FE5D7C',
        u_all_tag_color_out_of_stock: '#D0D0CF',
        u_all_tag_color_promotion: '#F2994A',
        u_all_tag_value_back_order: '백오더',
        u_all_tag_value_out_of_stock: '품절',
        u_all_welcome: '',
        u_bmi: '',
        u_bn_email_contact_fb: '',
        u_bn_email_contact_ig: '',
        u_bn_email_contact_mailto: '',
        u_bn_email_contact_tel: '',
        u_bn_email_contact_youtube: '',
        u_bn_id_card_err_incorrect_length: '',
        u_bn_id_card_err_not_a_number: '',
        u_hk_confirmation_email_link_url: '',
        u_hk_customlink_pickup_address: '',
        u_hk_enroll_delivery_detail: '',
        u_hk_enroll_fullname: '',
        u_hk_enroll_id_description: '',
        u_hk_enroll_name: '',
        u_hk_enroll_pickup_detail: '',
        u_hk_od_address_history_selected_template: '',
        u_hk_od_address_line1: '',
        u_hk_od_country: '',
        u_hk_od_email: '',
        u_hk_od_pickup_address: '',
        u_hk_os_address_template: '',
        u_hk_os_shipping_note_delivery: '',
        u_id_confirmation_email_link_url: '',
        u_id_email_currency: '',
        u_id_enroll_bank_account_later: '',
        u_id_enroll_bank_account_now: '',
        u_id_enroll_id_card_invalid: '',
        u_id_od_address_line1: '',
        u_id_od_label_switch_input_a: '',
        u_id_od_label_switch_input_b: '',
        u_id_od_pickup_address_jakarta: '',
        u_id_od_pickup_address_surabaya: '',
        u_id_od_shipping_motor_delivery: '',
        u_id_os_shipping_note_delivery: '',
        u_id_os_shipping_note_motor_delivery: '',
        u_jp_application_desc: '',
        u_jp_autoship_autoship_message: '',
        u_jp_autoship_contract_date: '',
        u_jp_autoship_delivery: '',
        u_jp_autoship_email_currency: '',
        u_jp_autoship_email_currency_suffix: '',
        u_jp_autoship_email_subtitle2: '',
        u_jp_autoship_email_subtitle_add: '',
        u_jp_autoship_email_subtitle_change: '',
        u_jp_autoship_email_subtitle_delete: '',
        u_jp_autoship_fullname: '',
        u_jp_autoship_member_id: '',
        u_jp_autoship_order_number: '',
        u_jp_confirmation_email_link_url: '',
        u_jp_enroll_government_id_label: '',
        u_jp_err_coapp_mobilephone_duplicate: '',
        u_jp_err_idcard_duplicate: '',
        u_jp_err_mobilephone_duplicate: '',
        u_jp_footer_download: '다운로드',
        u_jp_footer_section_b_topic: '',
        u_jp_od_address_history_selected_template: '',
        u_jp_od_address_line1: '',
        u_jp_od_address_line2: '',
        u_jp_od_pickup_address: '',
        u_jp_os_address_template: '',
        u_jp_os_payment_note: '',
        u_jp_os_shipping_note_delivery: '',
        u_jp_payment_response_message_template: '',
        u_kr_contact_us_absence: '부재시 연락요망',
        u_kr_contact_us_before_delivery: '배송전 연락요망',
        u_kr_etc: '기타',
        u_kr_excluding_receipt: '영수증 제외',
        u_kr_request_in_absence: '부재시 요청',
        u_kr_requirements_when_ordering: '주문시 요구사항을 선택 해주세요. ',
        u_kr_shopping_bag_request: '쇼핑백 요청',
        u_link_all_back_to_shop: '',
        u_link_all_payment_failed: '결제 실패',
        u_link_all_try_again: '다시 시도하십시오',
        u_link_fill_up_all_fields: '',
        u_link_ph_ba_declation_message_one: '',
        u_link_ph_ba_declation_message_three: '',
        u_link_ph_ba_declation_message_two: '',
        u_ma_os_shipping_note_delivery: '',
        u_mys_enroll_delivery_detail: '',
        u_mys_enroll_pickUp_detail: '',
        u_my_confirmation_email_link_url: '',
        u_my_enroll_error_message_govid: '',
        u_my_enroll_fullname: '',
        u_my_enroll_nric_label: '',
        u_my_enroll_nric_text: '',
        u_my_od_address_history_selected_template: '',
        u_my_od_address_line1: '',
        u_my_od_address_line2: '',
        u_my_od_city: '',
        u_my_od_pickup_address: '',
        u_my_od_shipping_aramex: '',
        u_my_od_zip: '',
        u_my_os_address_template: '',
        u_my_os_shipping_note_aramex: '',
        u_my_os_shipping_note_delivery: '',
        'u_phl_enroll_delivery.ph_taguig_detail': '',
        'u_phl_enroll_pickup.ph_taguig_detail': '',
        u_ph_confirmation_email_link_url: '',
        u_ph_confirmation_email_payment_bankwire: '',
        u_ph_confirmation_email_payment_cbs: '신용 카드',
        u_ph_customlink_pickup_address: '',
        u_ph_enroll_gov_id: '',
        u_ph_enroll_gov_id_description: '',
        u_ph_enroll_gov_id_description2: '',
        u_ph_enroll_id: '',
        u_ph_enroll_id_description: '',
        u_ph_od_address_history_selected_template: '',
        u_ph_od_address_line1: '',
        u_ph_od_comment: '',
        u_ph_od_pickup_address: '',
        u_ph_od_sameday_delivery_note_1: '',
        u_ph_od_sameday_delivery_note_2: '',
        u_ph_od_shipping_pickup_taguig: '',
        u_ph_od_shipping_sameday_delivery: '',
        u_ph_od_zip: '',
        u_ph_os_address_template: '',
        u_ph_os_bankwire_list: '',
        u_ph_os_gateway_note: '',
        u_ph_os_payment_method_bankwire: '',
        u_ph_os_payment_method_creditcard: '',
        u_ph_os_payment_method_gcash: '',
        u_ph_os_shipping_note_delivery: '',
        u_ph_os_shipping_note_sameday_delivery: '',
        u_ph_os_term_condition_text: '',
        u_ph_os_term_condition_url: '',
        u_ph_payment_response_message_template: '',
        u_ph_valid_ids_1: '',
        u_ph_valid_ids_2: '',
        u_sg_customlink_pickup_address: '',
        u_sg_enroll_choose_branch_name: '',
        u_sg_enroll_delivery_detail: '',
        u_sg_enroll_idcard_description: '',
        u_sg_enroll_nationality: '',
        u_sg_enroll_no_enroll_id: '',
        u_sg_enroll_pickup_detail: '',
        u_sg_enroll_shipping_note_delivery: '',
        u_sg_od_address_history_selected_template: '',
        u_sg_od_address_line1: '',
        u_sg_od_pickup_address: '',
        u_sg_os_address_template: '',
        u_sg_os_shipping_note_aramex: '',
        u_sg_os_shipping_note_delivery: '',
        u_shopprofile_back_to_top: '',
        u_shopprofile_distributor_id: '회원번호',
        u_shopprofile_referral: '',
        u_test_tc: '',
        u_tha_enroll_delivery_detail: '',
        u_tha_enroll_pickup_detail: '',
        u_th_bmi_age: '',
        u_th_bmi_bmi_cate: '',
        u_th_bmi_bmi_value: '',
        u_th_bmi_body_fat: '',
        u_th_bmi_body_fat_cate: '',
        u_th_bmi_body_fat_given_age: '',
        u_th_bmi_body_fat_lean: '',
        u_th_bmi_body_fat_lose: '',
        u_th_bmi_body_fat_mass: '',
        u_th_bmi_calculate: '',
        u_th_bmi_category: '',
        u_th_bmi_cat_moderately_thin: '',
        u_th_bmi_cat_normal_range: '',
        u_th_bmi_cat_overweight: '',
        u_th_bmi_cat_overweight_level1: '',
        u_th_bmi_cat_overweight_level2: '',
        u_th_bmi_cat_overweight_level3: '',
        u_th_bmi_cat_thin: '',
        u_th_bmi_cat_too_thin: '',
        u_th_bmi_cm: '',
        u_th_bmi_descript_acceptable: '',
        u_th_bmi_descript_athletes: '',
        u_th_bmi_descript_essential_fat: '',
        u_th_bmi_descript_fitness: '',
        u_th_bmi_descript_obese: '',
        u_th_bmi_feet: '',
        u_th_bmi_hip: '',
        u_th_bmi_inch: '',
        u_th_bmi_kg: '',
        u_th_bmi_less_than: '',
        u_th_bmi_message_bmi: '',
        u_th_bmi_message_validate: '',
        u_th_bmi_more_than: '',
        u_th_bmi_neck: '',
        u_th_bmi_pounds: '',
        u_th_bmi_title_table_bmi: '',
        u_th_bmi_value_bmi: '',
        u_th_bmi_waist: '',
        u_th_bmi_weight: '',
        u_th_bmi_year: '',
        u_th_confirmation_currency: '',
        u_th_confirmation_email_link_url: '',
        u_th_customlink_pickup_address: '',
        u_th_dsc_branch: '',
        u_th_enroll_firstnameen: '',
        u_th_enroll_id_verification: '',
        u_th_enroll_lastnameen: '',
        u_th_enroll_tc_path: '',
        u_th_enroll_url_term_enroll: '',
        u_th_enroll_verify_id_card: '',
        u_th_idverification_line_image: '',
        u_th_od_address_line1: '',
        u_th_od_address_line21: '',
        u_th_od_address_line22: '',
        u_th_od_pickup_address: '',
        u_th_os_shipping_note_delivery: '',
        u_th_os_url_term_enrollment: '',
        u_th_os_url_term_retail: '',
        u_th_os_url_term_wholesale: '',
        u_th_payment_response_message_template: '',
        u_th_term_condition_header_title: '',
        u_tw_confirmation_email_link_url: '',
        u_tw_enroll_delivery_detail: '',
        u_tw_enroll_fullname: '',
        u_tw_enroll_photo_id: '사진 ID',
        u_tw_enroll_pickup_detail: '',
        u_tw_id_card_err_invalid: '',
        u_tw_id_card_err_length: '',
        u_tw_od_address_history_selected_template: '',
        u_tw_od_address_line1: '',
        u_tw_od_zone: '',
        u_tw_os_address_template: '',
        u_tw_os_shipping_note_delivery: '',
        u_vn_confirmation_email_link_url: '',
        u_vn_os_shipping_note_delivery: '',
        u_vn_pay_domestic: '',
        u_vn_pay_international: '',
        validate_button: '데이터 검증',
        validate_en: '[:기입 내용:]은 반드시 영어 문자로 기입하여야 합니다.',
        validate_error: '오류 확인',
        validate_password_mismatch: '',
        validate_password_more_than: '',
        validate_password_required: '',
        validate_th: '[:기입 내용:]은 반드시 태국어 문자로 기입하여야 합니다.',
        validating: '확인 중...',
        vat: '부가가치세',
        verified: '확인 완료',
        verify_enroller: '인롤러/스폰서 확인하기',
        verify_id: '아이디 확인',
        verify_ird: 'IRD 번호 확인',
        verify_sponsor: '스폰서 확인',
        verify_tfn: '납세자번호를 확인하세요.',
        views: '조회수',
        view_auto_ship: '오토십 (Autoship) 보기',
        view_ba: '프로필 보기',
        view_orders: '주문 조회하기',
        view_products: '제품 보기',
        view_profile: '',
        village: '읍/면/동',
        vip: 'VIP 고객',
        vnm: '베트남',
        vn_err_lenght: '주민등록번호는 9~12자리여야 합니다.',
        volatile_organic_compound: '휘발성 유기 화합물',
        volume: '분량',
        waranty_registration_messege: '',
        warehouse: '',
        warehouse_jakarta: '',
        warehouse_surabaya: '',
        warning_autoship_pv:
            'PCC 회원의 PSV가 오토십 PSV (Autoship PSV)에 추가됩니다. 골드 매니저 직급 이상을 달성하기 위해서, 본인의 [br] 100 오토십 PSV가 있어야 합니다.',
        warning_has_backorder: 'Your order containing backordered items.',
        warning_pv_exceed: '',
        warranty_form: '',
        warranty_msg1: '',
        warranty_msg2: '',
        warranty_msg3: '',
        warranty_registration: '',
        warranty_registration_date: '',
        warranty_success: '',
        weekly: '주간',
        weight: '무게',
        weight_management_system: '체중관리 시스템',
        where_currently_registered: '귀하는 현재 어디에 등록되어 있습니까?',
        wholesalecustomer: '도매 고객',
        wholesale_price: '도매가',
        withheld_tax: '원천세',
        xau_err_lenght: '납세자번호 (TFN)는 7~8자리여야 합니다.',
        xau_err_no: '잘못된 납세자번호입니다.',
        xau_err_space: '',
        xau_remote_code_remark: '',
        xjp_disclaimer_message: '',
        xnz_err_lenght: '주민등록번호는 8자리 이상이어야 합니다.',
        xnz_err_no: '',
        xnz_err_space: '',
        xx1: '구',
        xx10: '주소 - 도로명',
        xx11: '주소 2',
        xx12: '시/군/구 - 읍/면/동',
        xx13: '구 - 시/군',
        xx14: '시/군/구',
        xx15: '의견',
        xx16: '주요 건물',
        xx17: '주 (州)',
        xx2: '주 (예: 뉴욕)',
        xx3: '우편번호',
        xx4: '우편번호',
        xx5: '휴대전화',
        xx6: '이메일',
        xx7: '성명',
        xx8: '국가',
        xx9: '주소 1',
        x_kr_email_contact_fb: 'https://www.facebook.com/UnicitySingapore',
        x_kr_email_contact_ig: 'https://www.instagram.com/unicitymoves/',
        x_kr_email_contact_mailto: 'cs.kor@unicity.com',
        x_kr_email_contact_tel: '003-086-511-447',
        x_kr_email_contact_youtube:
            'https://www.youtube.com/channel/UCAiAqkbresXIqIQhT3Lfq7A',
        yes: '예',
        'your-email': '이메일',
        your_acquired_x_unit: '[total_tshirt] 단위를 획득했습니다.',
        your_balance: '',
        your_password_has_been_reset:
            '비밀번호가 초기화되었습니다. 이메일을 통해 새 비밀번호를 확인하십시오.',
        you_also_like: '다음 사항도 추천합니다.',
        zip: '우편번호',
        zipcode: '우편번호',
        zipcode_id: '우편번호',
        zipcode_sg: '',
        zip_au: '',
        zip_is_empty: '',
        zip_jp: '',
        zip_must_be_number: '',
        zip_my: '',
        zip_nz: '',
        zip_ph: '',
        zip_warranty: ''
    }
}

export { constants_kr }
