import { getCountry } from '@Components/GlobalHelpers'
import { getLoginUserId } from '@Components/ShoppingHelper'
import { inArray } from '@Config'
import { storeElement } from '@Stores/StoreElement'
import { storeFilter } from '@Stores/StoreFilter'
import StoreAuth from '@Stores/User/StoreAuth'
import StoreUser from '@Stores/User/StoreUser'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { GenealogyFilterGetItem, GenealogyFilterRemoveItem, GenealogyFilterSetItem } from '../Apis'

export function useFilterStore() {

    const params = useParams()
    const history = useHistory()

    const getFilterStorage = (setChange, callback) => {

        GenealogyFilterGetItem((status, response) => {

            if (status) {

                if (Object.keys(response).length > 0) {
                    if (response.showAll || response.sortBy || response.view || response.showActiveLeg) {

                        const showActiveLeg = response.showActiveLeg === undefined ? true : response.showActiveLeg


                        storeFilter.setValues(response, setChange)


                        if (callback) {
                            callback(response.view)
                        }

                        if (params.view !== response.view) {
                            history.push(pathToRedirect(params.view, response.view, history.location.pathname))
                        } else {
                            const paths = history.location.pathname.split('/')
                            if (!inArray(response.view, paths)) {
                                history.push(pathToRedirect(params.view, response.view, history.location.pathname))
                            }
                        }


                    } else {
                        GenealogyFilterRemoveItem(() => { }, StoreAuth.id)
                    }
                }
            }
            storeFilter.setInitialFilter(false)
            storeFilter.setIsChanged(false)

        }, localStorage.getItem('dummy-user') || StoreAuth.id)

    }

    const saveFilterToStore = async (shouldInit = true) => {

        if (shouldInit) {
            storeFilter.setInitialFilter(true)
        }

        storeFilter.save().then(response => {
            storeFilter.setInitialFilter(false)
            storeFilter.setIsChanged(false)
        })
    }

    const pathToRedirect = (currentView, storeView, pathname) => {

        if (/card/.test(pathname)) {
            pathname = pathname.replace('card', storeView)
        } else if (/table/.test(pathname)) {
            pathname = pathname.replace('table', storeView)
        } else if (/level/.test(pathname)) {
            pathname = pathname.replace('level', storeView)
        } else {
            pathname = pathname.replace('dashboard', storeView)
        }

        return pathname
    }

    return {
        getFilterStorage: getFilterStorage,
        saveFilterToStore: saveFilterToStore
    }
}

