import PriceWithCurrency from './PriceWithCurrency';
import { store } from '@Stores/MainStore';
import { storeProduct } from '@Stores/StoreProduct';
import { isNothing } from '@Utils/Utils';

export const getProductPrice = () => {
    
    const formatPrice = (price, currencyPosition) => {
        let currency = store.currency
        if (isNothing(currencyPosition) || currencyPosition === 'none') {
            currency = ''
        }
        return <PriceWithCurrency currencyPosition={currencyPosition} price={price} currency={currency} />
    }
        
    return {
        formatPrice: (price, currencyPosition) => formatPrice(price, currencyPosition)
    }
}

/** @deprecated Please use `storeProduct.getProductPrice()` instead. */
export const getPrice = productID => storeProduct.getProductPrice(productID)

/**
 * @param {*} props 
 * @param {object} props.product
 * @param {boolean} props.formatted
 * @param {'none'|'left'|'right'} props.currencyPosition
 */
const ProductPrice = props => {

    const { product, formatted, currencyPosition } = props

    const productPrice = storeProduct.getProductPrice(product.item_code)

    if (formatted) {
        return getProductPrice().formatPrice(productPrice, currencyPosition)
    } else {
        return productPrice
    }
}

export default ProductPrice