import { get } from 'lodash';
import { autorun, makeAutoObservable } from 'mobx';
import Customer from './User/Customer';

/**
 * 마이페이지의 대시보드에 사용되는 데이터들을 관리.
 */
class StoreProfile {
    profileData = null
    successTrackerData = null
    commissionData = null
    facebookLoginData = null
    seminarData = null
    addressBookData = null
    orderHistoryData = null
    genealogyData = null
    lsbData = null
    editBaInfo = false
    saveBaInfo = false
    editForms = {
        homePhone: '',
        mobilePhone: '',
        workPhone: '',
        email: '',
        hasError: false
    }
    periodSelected = 0
    globalCardWidth = 'card-354'
    needReload = false
    genealogyAge = 0
    genealogyCache = false
    isStatusE = false
    isViewBa = false
    profileDataReady = false
    genealogyDataReady = false
    dataReady = false
    rankSelected = ''

    boxProfileHeight = 0
    boxBaInfoHeight = 0
    boxSeminarHeight = 0
    boxGenealogyHeight = 0
    boxOrderHistoryHeight = 0
    boxSuccessTrackerHeight = 0
    boxCommissionHeight = 0
    boxLsbHeight = 0

    closeHeaderMenu = false

    enroller = null
    sponsor = null

    seminarHeaderTab = 0

    currentProfileData = null

    profileChanged = false

    setProfileData(value) {
        this.profileData = value
        this.currentProfileData = new Customer(this.profileData)
    }

    setCommission(value) {
        this.commissionData = value
    }

    setLsb(value) {
        this.lsbData = value
    }

    setOrderHistoryData(value) {
        this.orderHistoryData = value
    }

    setData(profile, commission, lsb) {
        this.profileData = profile
        this.currentProfileData = new Customer(this.profileData)
        this.commissionData = commission
        this.lsbData = lsb
        this.profileDataReady = true
    }

    InitDashboardData(data, isViewBa) {
 
        // if(data.achievementsHistory.success === true) {
        //     this.successTrackerData = data.achievementsHistory
        // }

        // if(data.genealogy.success === true) {
        //     this.genealogyData = data.genealogy
        // }
        
        // if(data.orderHistory.success === true) {
        //     this.orderHistoryData = data.orderHistory
        // }

        this.successTrackerData = data.achievementsHistory
        this.genealogyData = data.genealogy
        this.genealogyAge = data.age
        this.genealogyCache = data.cache

        if (isViewBa) {
            this.currentProfileData = null
            this.setProfileData(data.genealogy.items[0].customer)
        }
        
    }

    
    setCPWE(value) {
        this.isCPWE = value
    }
    /** param {*} {new Customer} */
    CustomerData() {
        if (this.currentProfileData) {
            return this.currentProfileData
        } else {
            this.currentProfileData = new Customer(this.profileData)
        }

        return this.currentProfileData
    }


    isDataReady() {

        if (this.profileDataReady === true && this.genealogyDataReady === true) {
            this.dataReady = true
        } else {
            this.dataReady = false
        }

        return this.dataReady
    }

    resetDataReady() {
        this.profileDataReady = false
        this.genealogyDataReady = false
        this.dataReady = false
    }

    resetData() {
        this.profileData = null
        this.successTrackerData = null
        this.commissionData = null
        this.facebookLoginData = null
        this.seminarData = null
        this.addressBookData = null
        this.orderHistoryData = null
        this.genealogyData = null
        this.enroller = null
        this.sponsor = null
        this.profileDataReady = false
        this.genealogyDataReady = false
        this.dataReady = false
        this.seminarHeaderTab = 0
    }

    constructor() {
        // 사용되는 데코레이터가 없는 경우에 대체로 사용
        // 클래스의 속성을 관찰 가능한 속성으로 만들어주며, 이를 통해 해당 속성이 변경될 때 MobX가 자동으로 리액트하는 것을 허용
        makeAutoObservable(this)

        // 관찰함수:reaction(r), 추적 중인 observable 값이 변경될 때마다 다시 실행
        autorun((r) => {
            if (get(this.profileData, 'id.unicity', null) !== get(this.currentProfileData, 'id.unicity', null)) {
                this.currentProfileData = new Customer(this.profileData)
                // autorun에서 관찰 함수를 더 이상 호출하지 않도록 reaction(r)을 해제합니다. 
                r.dispose()
            }
        })
    }
}

export default new StoreProfile()