export const RankConfigs = {
    allRank: {
        default: ['', 'asc', 'srm', 'exm', 'dir', 'srd', 'exd', 'prd', 'prs', 'prr', 'dia', 'dia2', 'ccm', 'etc'],
        malaysia: ['', 'asc', 'dst', 'mgr', 'exm', 'dir', 'srd', 'exd', 'prd', 'prs', 'prr', 'dia', 'dia2', 'ccm', 'etc']
    },
    GenealogyRanks: {
        all: ['', 'asc', 'dst', 'mgr', 'srm', 'exm', 'dir', 'srd', 'exd', 'prd', 'prs', 'prr', 'dia', 'dia2', 'ccm', 'etc'],
        lowest: 'etc'
    },
    SuccessTranckerRanks: {
        all: ['', 'asc', 'dst', 'mgr', 'srm', 'exm', 'dir', 'srd', 'exd', 'prd', 'prs', 'prr', 'dia', 'dia2', 'ccm', 'etc'],
        lowest: 'etc'
    }
}

export function GetCustomerType(distType) {
    let type = {
        short: '',
        full: ''
    }
    Object.keys(CustomerType).map(key => {
        if (distType !== undefined) {
            if (CustomerType[key].api.type.toLowerCase() === distType.toLowerCase()) {
                type.short = key
                type.full = CustomerType[key].api.type
            }
        } else {
            type.short = 'T'
            type.full = 'Associate'
        }
    })

    return type
}

const CustomerType = {
    A: {
        description: 'Active',
        api: {
            type: 'Associate',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    B: {
        description: 'Pending',
        api: {
            type: 'Associate',
            status: 'Pending',
            priceLevel: 'wholesale_price'
        }
    },
    C: {
        description: 'Customer',
        api: {
            type: 'Customer',
            status: 'Active',
            priceLevel: 'retail_price'
        }
    },
    D: {
        description: 'Balance (CHE, DEU), Defective (JP)',
        api: {
            type: 'Associate',
            status: 'Defective',
            priceLevel: null
        }
    },
    E: {
        description: 'Employee',
        api: {
            type: 'Employee',
            status: 'Active',
            priceLevel: 'employee_price'
        }
    },
    F: {
        description: 'Family Pricing',
        api: {
            type: 'Family',
            status: 'Active',
            priceLevel: 'employee_price'
        }
    },
    H: {
        description: 'Wholesale Customer',
        api: {
            type: 'WholesaleCustomer',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    L: {
        description: 'Performance Labs',
        api: {
            type: 'Performance Labs',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    M: {
        description: 'Member',
        api: {
            type: 'Member',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    P: {
        description: 'Preferred Customer',
        api: {
            type: 'PreferredCustomer',
            status: 'Active',
            priceLevel: 'preferred_price'
        }
    },
    R: {
        description: 'Retired',
        api: {
            type: 'Associate',
            status: 'Retired',
            priceLevel: null
        }
    },
    S: {
        description: 'Suspended',
        api: {
            type: 'Associate',
            status: 'Suspended',
            priceLevel: null
        }
    },
    T: {
        description: 'Terminated',
        api: {
            type: 'Associate',
            status: 'Terminated',
            priceLevel: null
        }
    }
}
