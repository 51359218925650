import { requestAsync, requestKakaoAsync } from '../utils/rest'
import {
    apiURL,
    apiHEADER,
    marketZone,
    getCountryCode3,
    getCountryCode,
    isProduction
} from '@Components/GlobalHelpers'
import { Base64 } from 'js-base64'
//import Customer from '@classes/Customer';

const encode = user => {
    return Base64.encode(user)
}

const decode = decode => {
    return Base64.decode(decode)
}

const getLocalUserData = () => {
    let user = undefined
    try {
        const userEnc = localStorage.getItem('user')
        user = JSON.parse(decode(userEnc))
    } catch (err) {}

    return user
}

const getLocalKakaoData = () => {
    let kakao = undefined
    try {
        const kakaoStr = localStorage.getItem('kakao')
        kakao = JSON.parse(kakaoStr)
    } catch (err) {}

    return kakao
}

const loadProfile = () => {}

const saveKakao = token => {
    const kakaoData = {
        isKakao: true,
        token
    }
    localStorage.setItem('kakao', JSON.stringify(kakaoData))
}

const clearKakao = async () => {
    try {
        let kakaoDataStr = localStorage.getItem('kakao')
        let kakaoDataJson = JSON.parse(kakaoDataStr)
        localStorage.removeItem('kakao')
        await updateProfile('', kakaoDataJson.token)
    } catch (err) {}
}

const getKakao = () => {
    let result = {
        isKakao: false,
        token: undefined
    }

    try {
        let kakaoDataStr = localStorage.getItem('kakao')
        if (!kakaoDataStr) return result

        let kakaoDataJson = JSON.parse(kakaoDataStr)
        if (!kakaoDataJson) return result

        result = kakaoDataJson
    } catch (err) {}

    return result
}

const getUser = res => {
    const { unicity_user } = res.profile.properties

    let user = undefined
    try {
        if (unicity_user) {
            user = decode(unicity_user)
            let userJson = JSON.parse(user)
            return {
                username: userJson.username,
                password: userJson.userpw ? userJson.userpw : userJson.password
            }
        }
    } catch (err) {}

    return user
}
/**
 * @description 사용자 정보 저장하기
 *
 */
const updateProfile = async (user, token) => {
    //const url = 'https://kapi.kakao.com/v1/user/update_profile';

    const url = 'http://ushop.portalsns.com/v1/user/update_profile/' + token

    let unicity_user = ''

    console.log('user : ', user)
    if (user) {
        unicity_user = encode(
            JSON.stringify({ username: user.username, password: user.password })
        )
    }

    const params = {
        properties: JSON.stringify({
            unicity_user
        })
    }

    return await requestKakaoAsync(url, 'POST', params)
}

const KakaoService = {
    updateProfile,
    getUser,
    getLocalUserData,
    encode,
    decode,
    saveKakao,
    clearKakao,
    getLocalKakaoData
}
export default KakaoService
