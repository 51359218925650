import Raven from '../Raven';
import { _getLegacyEndpoint } from '../APIs';
import { getCustomerHref, getCustomerToken, getCustomerID, getCustomerStatus } from '../../components/utils/Customer';
import { isNothing, someOf, isBoolean } from '../../components/utils/Utils';
import { Country } from '../../components/configs/Country';
import { isCountryThailand, isCountrySingapore } from '../../components/GlobalHelpers';
import { devTools, staticFooter } from '../../stores/MainStore';
import { storeBalance } from '../../stores/StoreBalance';
import { logi, loge } from '../../components/utils/PikaLog';
import last from 'lodash/last';
import throttle from 'lodash/throttle';
import { hasQueryString } from '@Components/configs/ConfigsHeader';
import StoreAuth from '@Stores/User/StoreAuth';

export const payWithBalance = referenceID => {
    const result = Raven.post(
        `${_getLegacyEndpoint()}/v1/${Country.getCode2()}/payment/request/direct`,
        { reference_id: referenceID }
    )

    return result
}

export const getCashCoupon = (currency) => {
    const hydraEndpoint = Raven.switchHydraEndpoint(StoreAuth.Href())
    const result = Raven.get(
        `${hydraEndpoint}/pocketBalances`,
        {
            pocketCode: 'CashCoupon',
            currency: currency
        },
        {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StoreAuth.token}`
            }
        }
    )

    return result
}

export const getAllowARBalance = (unicityID) => {
    if (isNothing(unicityID)) return new Promise((resolve, reject) => reject('unicityID should not be empty.'))

    const endpoint = _getLegacyEndpoint(true)
    const result = Raven.get(
        `${endpoint}/v1/global/verify/allow_ar_balance/${unicityID}`
    )

    return result
}

export const getARBalance = () => {
    const hydraEndpoint = Raven.switchHydraEndpoint(StoreAuth.Href())
    const result = Raven.get(
        `${hydraEndpoint}/storecredit/${Country.getCode2()}`,
        null,
        {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${StoreAuth.token}`
            }
        }
    )

    return result
}

const checkAdditionalLogic = (overwrite = false, currentType, currentAllow, currentBalance, currentCurrency) => {
    let isAllow = null

    switch (currentType) {
        case 'ARBalance': 
            // Ref: https://app.clickup.com/t/49ub26
            // https://u67488.p.clickup-attachments.com/u67488/fdb8b45f-f823-409d-87fe-1d49f794d07a/image.png?view=open
            isAllow = currentBalance > 0
            break
        case 'CashCoupon':
            // Ref: https://app.clickup.com/t/49ub26
            // https://app.clickup.com/t/49ub26?comment=56245711
            isAllow = currentBalance > 0
            break
    }

    if (isBoolean(isAllow)) {
        if (overwrite === true) {
            return isAllow
        } else {
            if (currentAllow) {
                return isAllow
            } else {
                return false
            }
        }
    } else {
        return null
    }
}

// * START -> Balance Feature: Cash Coupon & ARBalance
const processBalance = (currentType, currentAllow, currentBalance, currentCurrency) => {
    let resultAllow = currentAllow
    // check any additional logic from front-end and overwrite.
    const finalizeAllow = checkAdditionalLogic(true, currentType, currentAllow, currentBalance, currentCurrency)
    logi('💵 [BALANCE] Front-end finalize allow logic ->', isBoolean(finalizeAllow) ? finalizeAllow : 'None')
    if (isBoolean(finalizeAllow)) {
        resultAllow = finalizeAllow
    }
    storeBalance.type = currentType
    storeBalance.balance = currentBalance
    storeBalance.currency = currentCurrency
    storeBalance.isAllow = resultAllow
}

const requestBalance = () => {
    let [currentType, currentAllow, currentBalance, currentCurrency] = ['', false, 0, '']

    if (isCountryThailand()) {
        currentType = 'CashCoupon'
        const cashCounponCurrency = 'THB'
        Raven.getCashCoupon(cashCounponCurrency)
        .then(({ items }) => {
            if (hasQueryString('credit')) {
                throw 'mockup error'
            }
            
            if (items.length > 0) {
                const balance = parseFloat(last(items).runningBalance)
                currentBalance = balance
                currentCurrency = cashCounponCurrency
                currentAllow = true
                logi(`💵 [BALANCE] CashCoupon found -> Balance: [${balance} ${cashCounponCurrency}]`)
            } else {
                currentAllow = false
                loge(`💵 [BALANCE] CachCoupon NOT found.`)
            }
        })
        .catch(response => {
            currentAllow = false
            storeBalance.api.hasError = true
            storeBalance.api.type = 'warning'
            storeBalance.api.messages = ['u_all_balance_error_unknow']

            loge(`💵 [BALANCE] CashCoupon error. => ${response}`)
        })
        .finally(() => {
            processBalance(currentType, currentAllow, currentBalance, currentCurrency)
        })
    } else if (isCountrySingapore()) {
        currentType = 'ARBalance'
        Raven.getAllowARBalance(getCustomerID())
        .then((response) => {
            logi('💵 [BALANCE] ARBalance is allow ->', response)
            Raven.getARBalance()
            .then(responseARB => {
                logi('💵 [BALANCE] ARBalance found ->', responseARB)
                const { items: [balanceItems] } = responseARB
                if (Object.keys(responseARB.items)[0] !== undefined) {
                    const currency = Object.keys(balanceItems)[0]
                    currentBalance = balanceItems[currency]
                    currentCurrency = currency
                }
                currentAllow = true
            })
            .catch(() => {
                loge(`💵 [BALANCE] ARBalance NOT found.`)
                currentAllow = false
            })
            .finally(() => {
                processBalance(currentType, currentAllow, currentBalance, currentCurrency)
            })
        })
        .catch(response => {
            loge('[BALANCE] ARBalance is NOT allow ->', response)
            currentType = ''
            currentAllow = false
            processBalance(currentType, currentAllow, currentBalance, currentCurrency)
        })
    } else {
        loge(`💵 [BALANCE] feature is NOT support on this country.`)
    }
}

/** Request balacne features update from API (Cash Coupon and ARBalance). 
 * 
 * Can invoke this method only once per 5 seconds to prevent any pointless request.
*/
export const updateBalance = throttle(requestBalance, 5000, { trailing: false })
// * END <- Balance Feature