import { logn } from '@Utils/PikaLog'
import { makeAutoObservable } from 'mobx'

const SERVER_URLS = {
    development: `wss://ushop-io.unicity.com/io_dev`,
    production: `wss://ushop-io.unicity.com/io`,
}
const IO_SERVICE = `cart`
const IO_ALIAS = `IoCart`

const { REACT_APP_ENV, NODE_ENV } = process.env
const USE_STAGE = REACT_APP_ENV || NODE_ENV
const SERVER_URL = SERVER_URLS[USE_STAGE]

const toQueryString = obj => {
    const params = Object.keys(obj).reduce((list, key) => {
        list.push(`${key}=${obj[key]}`)
        return list
    }, [])
    return params.length > 0 ? `?${params.join("&")}` : ``
}

const logInfo = (...messages) => logn(`[${IO_ALIAS}]`, ...messages)

/**
 * StoreIoCart 상태를 관리하며, 웹소켓 연결 기능 등을 지원
 */
class StoreIoCart {
    isConnecting = false
    isCallbackOnConnect = false
    isAwakeOnConnected = false

    client = null
    data = null
    isSentOpenData = false

    // for re-connecting
    countryCode = null
    cartType = null
    baId = null
    onData = null
    onDataOne = null


    constructor() {
        makeAutoObservable(this)
    }

    _send(obj) {
        if (this.client && this.client.readyState === 1) {
            this.client.send(JSON.stringify(obj))
        } else {
            this.open({
                countryCode: this.countryCode,
                cartType: this.cartType,
                baId: this.baId,
            }, {
                onData: this.onData,
                onDataOne: this.onDataOne,
                onConneced: () => this.client.send(JSON.stringify(obj)) // send after opened
            })
            logInfo(`send failed socket is closed`)
        }
    }

    getLastUpdate() {
        this._send({ service: IO_SERVICE, action: "get" })
    }

    open({
        countryCode,
        cartType,
        baId,
    }, 
    { 
        onData, 
        onDataOne, 
        onConneced,
    }) {
        // awake send on connectd
        this.isAwakeOnConnected = false

        // for re-connecting
        this.countryCode = countryCode
        this.cartType = cartType
        this.baId = baId
        if (onData) this.onData = onData
        if (onDataOne) this.onDataOne = onDataOne

        const queryString = toQueryString({
            service: IO_SERVICE,
            countryCode,
            cartType,
            baId,
        })
        const connectUrl = `${SERVER_URL}${queryString}`
        logInfo(`Connecting...`, connectUrl)

        this.client = new WebSocket(connectUrl)
        this.client.addEventListener('open', () => {
            logInfo('connected')
            this.isConnecting = true
            onConneced && onConneced()
        })

        this.client.addEventListener('close', e => {
            logInfo('closed')
            if (this.isConnecting) this.open({
                countryCode,
                cartType,
                baId,
            }, 
            { 
                onData, 
                onDataOne, 
                onConneced,
            })
        })

        this.client.addEventListener('message', e => {
            logInfo('message', e.data)
            const res = JSON.parse(e.data)

            if (res.action === "onData") {
                this.data = res.data
                this.onData && this.onData(res)

                // set data on first connected
                if (this.isAwakeOnConnected === false){
                    this.isAwakeOnConnected = true
                    this.set(res.data)
                }   
            } else if (res.action === "onDataOne") {
                const foundIndex = this.data.findIndex(r => r.itemCode === res.data.itemCode)
                if (foundIndex > -1) {
                    // update row
                    this.data[foundIndex] = res.data
                } else {
                    const isRemoved = res.data.removeItemCode
                    if (isRemoved) {
                        // remove row
                        this.data = this.data.filter(r => r.itemCode !== res.data.removeItemCode)
                    } else {
                        // add row
                        this.data.push(res.data)
                    }
                }
                this.onDataOne && this.onDataOne(res.data)
            }
        })

        this.client.addEventListener('error', e => {
            console.error(e)
        })
    }

    close() {
        this.client.close()
        this.data = null
        this.client = null
        this.isConnecting = false
    }

    set(data) {
        logInfo('set', data)
        this._send({ service: IO_SERVICE, action: "set", data })
    }

    setOne(data) {
        logInfo('setOne', data)
        this._send({ service: IO_SERVICE, action: "setOne", data })
    }

    remove(data){
        logInfo('remove', data)
        this._send({ service: IO_SERVICE, action: "remove", data })
    }

    clear() {
        this._send({ service: IO_SERVICE, action: "set" })
    }
}

export default new StoreIoCart()