import { createBrowserHistory } from 'history';
import { useLocation } from 'react-router-dom';
import { wrapHistory } from 'oaf-react-router';
import { makeAutoObservable, get as mget, remove as mremove, reaction } from 'mobx';
import { checkOutStore, store } from '@Stores/MainStore';
import { findString, someOfString } from '@Utils/String';
import * as S from '@Configs/StorageKeys';
import { ShippingMethods } from '@Configs/ShippingMethods';
import { StorageExtend } from '@Utils/StorageManager';
import { isNumber } from '@Utils/Utils';

class StoreHistory {
    isInitialized = false
    location = {}
    prevLocation = {}

    constructor() {
        makeAutoObservable(this)
        this.isInitialized = true
    }

    set setLocation(loc) {
        this.location = loc
    }

    set setPevLocation(loc) {
        this.prevLocation = loc
    }
}

const basePath = ''
const history = createBrowserHistory({ basename: basePath })

wrapHistory(
    history, 
    {
        shouldHandleAction: (previousLocation, nextLocation) => {
            // Inspect/compare nextLocation and/or previousLocation.
            if (previousLocation !== nextLocation) {
                classStoreHistory.setPevLocation = previousLocation
                classStoreHistory.setLocation = nextLocation

                if (someOfString(nextLocation.pathname, ['/checkout']) === false) {
                    const shippingKey = ShippingMethods.getShippingName()
                    if (isNumber(mget(checkOutStore.billingAddress[shippingKey], 'addressHistorySelected'))) {
                        mremove(checkOutStore.billingAddress[shippingKey], 'addressHistorySelected')
                    }
                    StorageExtend.unsetFromPersonal(S.CheckoutRestore)
                }
    
                // always go to top when location is changed
                window.scroll(0, 0)
                store.showCart = false
            }
            return false // false if you don't want scroll restoration.
        },
        disableAutoScrollRestoration: true
    }
)

export const usePathnameInclude = (expectancy = []) => {
    let result = false
    const location = useLocation()
    expectancy.forEach(element => {
        if (findString(location.pathname, element)) {
            result = true
            return
        }
    })
    return result
}

export const usePathnameExclude = (expectancy = []) => {
    let result = true
    const location = useLocation()
    expectancy.forEach(element => {
        if (findString(location.pathname, element)) {
            result = false
            return
        }
    })
    return result
}
const classStoreHistory = new StoreHistory()

export { history as default }
export { classStoreHistory as storeHistory }