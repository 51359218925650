import Raven from '../Raven'
import {
    createCustomerToken,
    getCustomerToken
} from '../../components/utils/Customer'
import { last } from 'lodash';
import StoreAuth from '@Stores/User/StoreAuth';

/** Get order currency from API.
 * @param {string} periodStart market as a country code (e.g. SG, AU, NZ).
 * @param {string} periodEnd ship to country code (e.g. SG, AU, NZ).
 * @param {string} href order invoice id.
 * @param {string} token order invoice id.
 */
export const getOrderHistoryList = (
    baId,
    hydraToken,
    isBypassCache = false
) => {
    // const params = {
    //     expand: 'orderHistory',
    //     expandOrderHistory: 'order,rma',
    //     dateCreated: `[${periodStart}|${periodEnd}]`,
    //     customer: 'me|sponsoredCustomers?type=Customer',
    //     customerHref: href
    // }

    const params = {
        baId: baId,
        token: createCustomerToken(baId),
        ushopCountryCode: 'KOR',
        countryCode: 'KOR'
    }

    if (isBypassCache) {
        params.byPassCache = '1'
    }

    const configs = {
        headers: {
            'Content-Type': 'application/json',
            'authorization-hydra': `Bearer ${JSON.parse(localStorage.getItem('login-extends')).korea.token}`,
            'authorization-ushop': 'Bearer tVxbmKcjme'
        }
    }

    return Raven.get('etlV2/ordersHistory', params, configs)
}

/** Get order currency from API.
 * @param {string} market market as a country code (e.g. SG, AU, NZ).
 * @param {string} shipToCountry ship to country code (e.g. SG, AU, NZ).
 * @param {string} orderId order invoice id.
 */
export const getOrderHistoryCurrency = (market, shipToCountry, orderId) => {
    const data = {
        market: market,
        shipToCountry: shipToCountry,
        orderId: orderId
    }

    const configs = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    }

    return Raven.post(
        'https://tkvjrct9we.execute-api.ap-southeast-1.amazonaws.com/production',
        data,
        configs
    )
}

export const getOrderHistoryDetails = (token, orderUrl) => {

    let ref = last(orderUrl.split('/'))
    let country = 'KOR';

    const isDummy = localStorage.getItem("isDummy")
    const data = {
        orderRef: ref,
        ushopCountryCode: country,
        ...(isDummy ? { isDummy } : {}),
    }

    if (localStorage.getItem('dummy-user')) {
        data.isMockup = 1
    }

    return (
        Raven.get(
            'https://member-calls2.unicity.com/etlV2/orderDetail',
            data,
            {
                'headers': {
                    'authorization-hydra': StoreAuth.adminToken
                }
            }
        )
    )

    // const data = {
    //     url: orderUrl
    // }
    // return Raven.get(
    //     'https://member-calls2-kr.unicity.com/etl/orderHistory/detail',
    //     data,
    //     {
    //         headers: { Authorization: `Bearer ${token}` }
    //     }
    // )
}

export const getOrderHistoryByBAID = baID => {
    const params = {
        baId: baID,
        token: create(baID),
        countryCode: 'KOR'
    }

    const configs = {
        headers: {
            'Content-Type': 'application/json',
            'authorization-hydra': `Bearer ${getCustomerToken()}`,
            'authorization-ushop': 'Bearer tVxbmKcjme'
        }
    }

    return Raven.get('adapter/etl/genealogy', params, configs)
}

const create = (baId, now = new Date()) => {
    const date = now.getUTCDate()
    const weekDay = now.getUTCDay() + 1
    const modeWeekDay = (date % weekDay) + 1
    const hash = baId
        .toString()
        .split('')
        .map(c => parseInt(c) % modeWeekDay)
        .join('')
    return `${hash}${weekDay}${date}`
}
