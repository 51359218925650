import { makeAutoObservable } from 'mobx';
import { isSomething, someOf } from '@Utils/Utils';
import { language } from '@Language';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { lowerCase } from '@Components/utils/String';

class StoreHomePage {
    topSection = false
    loginSection = false
    serviceSection = false
    banner = []

    isShowCookieConsent = false

    isBannerInitialized() {
        return isSomething(this.banner)
    }

    constructor() {
        makeAutoObservable(this)
    }

    getBannerType() {
        if (get(this.topSection, `backgroundType[${language.footer}]`) === 'video') {
            return 'video'
        } else {
            return 'image'
        }
    }

    getHomeBGURL(type, device = 'desktop') {

        type = 'image'

        let formatType = lowerCase(type)
        let formatDevice = lowerCase(device)

        // if (someOf(formatType, ['image', 'video']) && someOf(formatDevice, ['desktop', 'mobile'])) {
        if (someOf(formatType, ['image', 'video']) && someOf(formatDevice, ['desktop', 'mobile'])) {
            if (formatDevice === 'desktop') formatDevice = ''
            return language.listen(get(this.topSection[`background${capitalize(formatType)}${capitalize(formatDevice)}`], 'url'))
        } else {
            return ''
        }
    }

    getLoginBGURL(type) {
        const lwType = lowerCase(type)
        if (someOf(lwType, ['desktop', 'mobile', 'tablet'])) {
            return language.listen(get(this.loginSection[`background${capitalize(type)}`], 'url'))
        } else {
            return ''
        }
    }

    get isVideoBanner() {
        return this.getBannerType() === 'video'
    }
}

export const storeHomePage = new StoreHomePage()