import * as K from '../configs/Keywords'
import * as S from '../configs/StorageKeys'
import { Country } from '../configs/Country'
import {
    isNothing,
    isSomething,
    isLength,
    isNullOrUndefined,
    trim,
    isEqualText,
    defaults,
    someOf
} from './Utils'
import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'
import includes from 'lodash/includes'
import last from 'lodash/last'
import { isLanguageNative } from '../GlobalHelpers'
import { findString } from './String'
import { storeEasyShop } from '@Stores/StoreEasyShop'
import StoreAuth from '@Stores/User/StoreAuth'
import { StorageExtend } from './StorageManager'
import { entries, keys, toPairs } from 'lodash'

const { Code2, MarketingCode } = Country

/** Use this `Object` template as a mockup data, to prevent any undefined error. */
const dataTemplate = {
    id: {
        unicity: ''
    },
    achievementsHistory: {
        href: '',
        items: ''
    },
    acl: {
        api: {
            priceLevel: '', // e.g. 'preferred_price'
            status: '', // e.g. 'Active'
            type: '' // e.g. 'PreferredCustomer'
        },
        showPv: '', // e.g. false (boolean)
        status: '', // e.g. 'P'

        allowList: '',
        allowed: '',
        country: '',
        description: '',
        inPageMenu: '',
        mainMenu: ''
    },
    birthDate: '',
    cumulativeMetricsProfile: {
        href: ''
    },
    entryPeriod: '',
    email: '',
    humanName: '',
    homePhone: '',
    href: '',
    joinDate: '',
    mainAddress: '',
    metricsProfile: '',
    metricsProfileHistory: {
        aggregate: {
            cumulativeMetricsProfile: {
                highestRankShort: ''
            }
        },
        href: '',
        items: ''
    },
    mobilePhone: '',
    profilePicture: '',
    type: ''
}

const _getCustomer = () => sessionStorage.getItem(S.Customer)

/** Get customer data from sessionStorage. */
export const getCustomerRaw = () => _getCustomer()
/** Assign customer data to sessionStorage. */
export const assignCustomer = (data, href, token) => {
    sessionStorage.setItem(
        S.Customer,
        JSON.stringify(data.onself.ushop.profile)
    )
    sessionStorage.setItem(S.CustomerHref, href)
    sessionStorage.setItem(S.CustomerToken, token)
    sessionStorage.setItem('user-href', href)
    sessionStorage.setItem('user-token', token)
    sessionStorage.setItem('onself-response', JSON.stringify(data.onself))
}

/** Delete customer data from sessionStorage. */
export const killCustomer = () => {
    sessionStorage.removeItem(S.Customer)
    sessionStorage.removeItem(S.CustomerHref)
    sessionStorage.removeItem(S.CustomerToken)
    sessionStorage.removeItem('user-token')
    sessionStorage.removeItem('user-href')
}

/** Log customer data to console.log(). */
export const logCustomerObject = () => console.log(JSON.parse(_getCustomer()))
/** Check the existing of any customer in this site. */
export const isCustomerValid = () => isNothing(_getCustomer()) === false

/** Get customer data as an `Object`. */
export const getCustomerObject = () => {
    const c = JSON.parse(_getCustomer())
    const data = {}
    data.id = {
        unicity: get(c, 'id.unicity')
    }
    data.achievementsHistory = {
        href: get(c, 'achievementsHistory.href,'),
        items: get(c, 'achievementsHistory.items,')
    }
    data.acl = {
        api: {
            priceLevel: get(c, 'acl.api.priceLevel'), // e.g. 'preferred_price'
            status: get(c, 'acl.api.status'), // e.g. 'Active'
            type: get(c, 'acl.api.type') // e.g. 'PreferredCustomer'
        },
        showPv: get(c, 'acl.showPv'), // e.g. false (boolean)
        status: get(c, 'acl.status'), // e.g. 'P'

        allowList: get(c, 'acl.allowList'),
        allowed: get(c, 'acl.allowed'),
        country: get(c, 'acl.country'),
        description: get(c, 'acl.description'),
        inPageMenu: get(c, 'acl.inPageMenu'),
        mainMenu: get(c, 'acl.mainMenu')
    }
    data.birthDate = get(c, 'birthDate')
    data.cumulativeMetricsProfile = {
        href: get(c, 'cumulativeMetricsProfile.href')
    }
    data.entryPeriod = get(c, 'entryPeriod')
    data.email = get(c, 'email')
    data.employmentDetails = get(c, 'employmentDetails')
    data.humanName = get(c, 'humanName')
    data.homePhone = get(c, 'homePhone')
    data.href = get(c, 'href')
    data.joinDate = get(c, 'joinDate')
    data.mainAddress = get(c, 'mainAddress')
    data.metricsProfile = get(c, 'metricsProfile')
    data.metricsProfileHistory = {
        aggregate: {
            cumulativeMetricsProfile: {
                highestRankShort: get(
                    c,
                    'metricsProfileHistory.aggregate.cumulativeMetricsProfile.highestRankShort'
                )
            }
        },
        href: get(c, 'metricsProfileHistory.href'),
        items: get(c, 'metricsProfileHistory.items')
    }
    data.mobilePhone = get(c, 'mobilePhone')
    data.profilePicture = get(c, 'profilePicture')
    data.type = get(c, 'type')

    return data
}
/** Validate current customer then get customer data as an `Object`. */
export const getCustomerValidObject = () => {
    if (isCustomerValid()) {
        return getCustomerObject()
    } else {
        return dataTemplate
    }
}

/** Get customer ACL object. */
export const getCustomerAcl = () => getCustomerObject().acl
/** Get customer `unicity` ID (e.g. 108357166). */
export const getCustomerID = () => trim(getCustomerObject().id.unicity)
/** Get customer highest rank short (e.g. DIA). */
export const getCustomerHighestRankShort = () =>
    getCustomerObject().metricsProfileHistory.aggregate.cumulativeMetricsProfile
        .highestRankShort

/** Get customer `href`. */
export const getCustomerHref = () => sessionStorage.getItem(S.CustomerHref)
/** Get customer `href` value. */
export const getCustomerHrefValue = () =>
    last(sessionStorage.getItem(S.CustomerHref).split('/customers/'))
/** Get customer `token`. */
export const getCustomerToken = () => sessionStorage.getItem(S.CustomerToken)

/** Get an `homePhone` from customer. */
export const getCustomerHomePhone = () => getCustomerObject().homePhone
/** Get an `mobilePhone` from customer. */
export const getCustomerMobilePhone = () => getCustomerObject().mobilePhone

export const getCustomerEntryPeriod = () => getCustomerObject().entryPeriod

/** Get an `email` from customer. */
export const getCustomerEmail = () => getCustomerObject().email

/** Get `birthDate` from customer. */
export const getCustomerBirthDate = () => getCustomerObject().birthDate
/** Get customer `type` in full phrase (e.g. Associate). */
export const getCustomerType = () => getCustomerAcl().api.type
/** Get an alphabet of customer type (e.g. A). */
export const getCustomerStatus = () => getCustomerAcl().status
/** Get an active status of customer (e.g. Active). */
export const getCustomerActiveStatus = () => getCustomerAcl().api.status
/** Get customer price level (e.g. wholesale_price). */
export const getCustomerPriceLevel = () => getCustomerAcl().api.priceLevel
/** Get customer `humanName`. */
export const getCustomerHumanName = () => {
    const human = getCustomerObject().humanName
    const data = {
        fullName: human.fullName,
        firstName: human.firstName,
        lastName: human.lastName
    }

    let native = ''
    Object.keys(human).map(key => {
        if (includes(key, '@') === true) {
            native = human[key]
        }
    })

    if (native) {
        set(data, 'fullName@native', native)
    }

    return data
}

export const getCustomerFullName = (translate = true) => {
    if (isLanguageNative() && translate === true) {
        if (isSomething(getCustomerHumanName()['fullName@native'])) {
            return getCustomerHumanName()['fullName@native']
        } else {
            return getCustomerHumanName().fullName
        }
    } else {
        return getCustomerHumanName().fullName
    }
}

export const getCustomerFullNameNative = () => {
    return getCustomerHumanName()['fullName@native']
}

/** Get customer `mainAddress`. */
export const getCustomerMainAddress = () => getCustomerObject().mainAddress

export const getCustomerPicture = () => getCustomerObject().profilePicture
export const getCustomerPicture500 = () => {
    let result = get(getCustomerPicture(), 'sizes', []).find(
        item => item.size === '500x500'
    )
    result = get(result, 'media', '')
    if (findString(result, 'http:')) {
        result = result.replace('http:', 'https:')
    }
    return result
}

/** Get customer country code from `unicity` ID (e.g. 66). */
export const getCustomerDialingCode = (length = 2) => {
    return isNothing(getCustomerID()) === false
        ? getCustomerID().toString().substr(-length)
        : null
}

/** Get country code as ISO 3166-1 alpha-2 code from customer (e.g. TH, SG, VN),
 * which mapped by dialing code from `unicity` ID. Return `unknown` if could not mapped any country.
 * Return `null` if there was no customer logged in. */
export const getCustomerCountryCode = () => {
    if (isCustomerLogin()) {
        let result = null
        Object.keys(MarketingCode).map(key => {
            if (getCustomerDialingCode(2) === MarketingCode[key]) result = key
        })
        if (result === null) {
            Object.keys(MarketingCode).map(key => {
                if (getCustomerDialingCode(3) === MarketingCode[key])
                    result = key
            })
        }
        return isNullOrUndefined(Code2[result]) ? K.Unknown : Code2[result]
    } else {
        return null
    }
}

// ** A variant of checking customer methods.
/** Return `true` if any customer already logged in. */
export const isCustomerLogin = () => isSomething(_getCustomer())
/** An atonym version of `isCustomerLogin()`.
 * Return `false` if any customer already logged in. */
export const isAnonymous = () => isCustomerLogin() === false
/** Check the **Marketing Unit** number to located customer from **Australia**. */
export const isCustomerFromAustralia = () =>
    getCustomerCountryCode() === Code2.australia
/** Check the **Marketing Unit** number to located customer from **Cambodia**. */
export const isCustomerFromCambodia = () =>
    getCustomerCountryCode() === Code2.cambodia
/** Check the **Marketing Unit** number to located customer from **Hong Kong**. */
export const isCustomerFromHongkong = () =>
    getCustomerCountryCode() === Code2.hongkong
/** Check the **Marketing Unit** number to located customer from **Korea**. */
export const isCustomerFromKorea = () =>
    getCustomerCountryCode() === Code2.korea
/** Check the **Marketing Unit** number to located customer from **Laos**. */
export const isCustomerFromLaos = () => getCustomerCountryCode() === Code2.laos
/** Check the **Marketing Unit** number to located customer from **Indonesia**. */
export const isCustomerFromIndonesia = () =>
    getCustomerCountryCode() === Code2.indonesia
/** Check the **Marketing Unit** number to located customer from **New Zealand**. */
export const isCustomerFromNewzealand = () =>
    getCustomerCountryCode() === Code2.newzealand
/** Check the **Marketing Unit** number to located customer from **Philippines**. */
export const isCustomerFromPhilippines = () =>
    getCustomerCountryCode() === Code2.philippines
/** Check the **Marketing Unit** number to located customer from **Singapore**. */
export const isCustomerFromSingapore = () =>
    getCustomerCountryCode() === Code2.singapore
/** Check the **Marketing Unit** number to located customer from **Thailand**. */
export const isCustomerFromThailand = () =>
    getCustomerCountryCode() === Code2.thailand
/** Check the **Marketing Unit** number to located customer from **Japan**. */
export const isCustomerFromJapan = () =>
    getCustomerCountryCode() === Code2.japan
/** Check the **Marketing Unit** number to located customer from **Vietnam**. */
export const isCustomerFromVietnam = () =>
    getCustomerCountryCode() === Code2.vietnam
/** Return `true` if the **Marketing Unit** number matched with current country. */
export const isCustomerLiveHere = () =>
    getCustomerCountryCode() === Country.getCode2()

export const isCustomerStatusE = () => StoreAuth.StatusE()
export const isCustomerStatusL = () =>
    isEqualText(
        get(getCustomerObject(), 'employmentDetails.employerName', ''),
        'Performance Labs'
    )

/** Check the origin of customer by **Marketing Unit** number. */
export const isCustomerFrom = (countryCodeList = []) => {
    if (isLength(countryCodeList)) {
        return countryCodeList.some(countryCode => {
            const dialingCode =
                MarketingCode[Country.getPathByCode2(countryCode)]
            return (
                getCustomerDialingCode(2) === dialingCode ||
                getCustomerDialingCode(3) === dialingCode
            )
        })
    } else {
        return false
    }
}

// ** Methods for sessionStorage cart management
const keyCartStorage = ''
/** @deprecated
 * Get current country **localStorage** cart as an `Object`. */
export const getCartStorage = () => {
    // const localCart = sessionStorage.getItem(keyCartStorage)
    // if (localCart) return JSON.parse(localCart)
    // else return null
    return null
}

/** @deprecated
 * Save current cart to  current country **localStorage** cart. */
export const saveCartStorage = () => {
    // sessionStorage.setItem(keyCartStorage, JSON.stringify(toJS(storeProduct.cart)))
}

/** @deprecated
 * Completely removed current country **localStorage** cart. */
export const clearCartStorage = () => {
    // sessionStorage.removeItem(keyCartStorage)
}

/** @deprecated
 * Completely removed ***every*** country **localStorage** cart. */
export const clearAllCartStorage = () => {
    /* CountryAvailable.map((c) => {
        sessionStorage.removeItem(`${S.CartStorage}-${CountryCode3[c].toLowerCase()}`)
    }) */
}

/** @deprecated
 * Return `true` if country **localStorage** cart was empty. */
export const isEmptyCart = () => isNothing(getCartStorage())

/** @deprecated
 * Get current country **localStorage** cart's legnth. */
export const getCartLength = () => (isEmptyCart() ? 0 : getCartStorage().length)

export const getExtendByKey = (key, group = '') => {
    return StorageExtend.get(`${group ? `${group}.` : ''}${key}`)
}

export const pushExtend = (data, group = '') => {
    toPairs(data).map(([key, value]) => {
        StorageExtend.set(value, `${group ? `${group}.` : ''}${key}`)
    })
}

export const removeExtendByKey = (key, group = '') => {
    StorageExtend.unset(`${group ? `${group}.` : ''}${key}`)
}

/**
 *
 * @param {object} achievementsHistory
 */
export const isCustomerVip = achievementsHistory => {
    if (achievementsHistory) {
        return achievementsHistory.items[0].value.find(item => {
            if (item.achievement.bonusName === 'FSB') {
                return item.qualified
            }
        })
    }

    return false
}

export const createCustomerToken = (baId, now = new Date()) => {
    const date = now.getUTCDate()
    const weekDay = now.getUTCDay() + 1
    const modeWeekDay = (date % weekDay) + 1
    const hash = defaults(baId, '')
        .toString()
        .split('')
        .map(c => parseInt(c) % modeWeekDay)
        .join('')
    return `${hash}${weekDay}${date}`
}

export const validateCustomerToken = (baId, token) => {
    const now = new Date()
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const tormorrow = new Date()
    tormorrow.setDate(tormorrow.getDate() + 1)

    const validList = [
        createCustomerToken(baId, now),
        createCustomerToken(baId, yesterday),
        createCustomerToken(baId, tormorrow)
    ]

    return validList.includes(token)
}

/** Check if current customer available to use **PV** feature. */
export const isCustomerPV = () => {
    if (isCustomerLogin()) {
        return someOf(getCustomerStatus(), ['A', 'B'])
    } else {
        if (storeEasyShop.isEnabled) {
            return storeEasyShop.easyChoice === 1
        } else {
            return false
        }
    }
}
