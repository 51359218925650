import Raven from '../Raven';

export const getRemoteStorage = (key) => {
    return Raven.get(`remoteStorage/data/${key}`)
}

export const setRemoteStorage = (key, data) => {
    const result = Raven.post(
        `remoteStorage/data/${key}`,
        data,
        {
            'headers': {
                'Content-Type': 'application/json'
            }
        }
    )

    return result
}

export const deleteRemoteStorage = (key) => {
    return Raven.delete(`remoteStorage/data/${key}`)
}