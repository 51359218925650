import { useEffect } from 'react';
import App from 'firebase/app';
import 'firebase/database';

import StoreAuth from '@Stores/User/StoreAuth';
import { Country } from '@Configs/Country';
import { someOf } from '@Utils/Utils';

const { REACT_APP_ENV, NODE_ENV } = process.env
const USE_STAGE = REACT_APP_ENV || NODE_ENV
const configs = {
    development: {
        apiKey: 'AIzaSyAl8-VxEXY02WFj4Eirm6KjCGe0HVdJ7SA',
        authDomain: 'ushop-dev-78eba.firebaseapp.com',
        databaseURL: 'https://ushop-dev-78eba.firebaseio.com',
        projectId: 'ushop-dev-78eba',
        storageBucket: 'ushop-dev-78eba.appspot.com',
        messagingSenderId: '731248922189',
        appId: '1:731248922189:web:db0a3a491848e84f9c1604'
    },
    production: {
        apiKey: 'AIzaSyB80YXDZ6UTukLndIOMmN2UNWRiJeYkJns',
        authDomain: 'ushop-prod-19369.firebaseapp.com',
        databaseURL: 'https://ushop-prod-19369.firebaseio.com',
        projectId: 'ushop-prod-19369',
        storageBucket: 'ushop-prod-19369.appspot.com',
        messagingSenderId: '6935723190',
        appId: '1:6935723190:web:c87205c6d40d118afbfa17'
    }
}

const useFirebaseApp = () => {

    useEffect(() => {

        if (App.apps.length === 0) {
            const options = configs[USE_STAGE]
            App.initializeApp(options)
        }
        
    }, [])
    
}

/** Get Firebase reference.
 * @param {object} options additional options for reference result.
 * @param {string} options.type 
 * @param {string} options.customerID 
 * @param {string} options.itemCode 
 * @return {string} the result of reference.
 */
const getReference = (options = { type: 'shopping', customerID: 'auto', itemCode: undefined }) => {

    const legitOptions = {
        type: someOf(options.type, [null, undefined, '', 'auto']) ? 'shopping' : options.type, 
        customerID: someOf(options.customerID, [null, undefined, '', 'auto']) ? StoreAuth.id : options.customerID, 
        itemCode: options.itemCode === undefined ? '' : `/${options.itemCode}`,
    }

    return `carts/${legitOptions.customerID}/${Country.getCode3()}/shopping${legitOptions.itemCode}`
}

export { App as default }
export { useFirebaseApp, getReference, configs }