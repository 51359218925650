import { reaction } from 'mobx';
import moment from 'moment';
import get from 'lodash/get';
import { language } from '@Language';
import { momentLocale } from '@Config';
import { isNothing, testArray } from './Utils';
import { lowerCase } from './String';
import { store, devTools, staticFooter } from '@Stores/MainStore';
import { getCountryCode, getStoreLanguageName } from '@GlobalHelpers';
import { Country } from '@Configs/Country';
import { loge, logn } from './PikaLog';
import { StorageExtend } from './StorageManager';
import { getCustomerID } from '@Customer';
import debounce from 'lodash/debounce';
import { constants_kr } from '../../constants/index'

const missingList = []

export const plainTranslate = (key) => {
    return get(language.dictionary, key, undefined) || get(language.dictionary, lowerCase(key), '')
}

const update = (languageCode) => {

    // console.log(`Updating translation (${lang}).`)

    if (languageCode) {
        // update personal language to storage
        StorageExtend.setToPersonal(languageCode, 'language', { toCustomer: getCustomerID(), fallbackToPublic: false })

        // update flag, dictionary, currency
        language.memoFooter()
        language.dictionaries.KR = constants_kr
        language.dictionary = language.dictionaries[languageCode].KR
        store.language = language.dictionaries[languageCode].KR // <-- should remove this later (deprecated api)
        store.currency = '원'//language.listen(staticFooter.footerGeneral.currency_code)

        // update moment
        const momentLocaleCode = momentLocale('korea', languageCode)
        if (testArray(momentLocaleCode, moment.locales()) === false) {
            if (momentLocaleCode !== 'en') {
                moment.locale(momentLocaleCode, require(`moment/locale/${momentLocaleCode}`).default)
            }
        } else {
            moment.locale(momentLocaleCode)
        }
        //logi('Moment Update (', momentLocaleCode, ') >>', moment("2019-06-10").format('MMM YYYY'), moment.locales())
        // setLoadingLog(`Update language setting for ${language}.`)
        logn(`✅ 🌐 Language setting for [${languageCode}] has been updated.`)
        // setLoadingLog(`Language setting for [${lang}] has been update.`)
    }
}

/** @param {string} languageCode specified language code. */
export const updateTranslation = debounce(update, 500, { leading: true })

// NOTE: Optimized this later
/** Display a NATIVE word from dictionary with specified key in native language
 * 
 * @param {string} key any key for a word related with dictionary.
 * @param {boolean} showLabelWhenMissing show label instead when this word is missing.
 * @param {boolean} showBracketAlongLabel use with missing word, show brackets along a label.
 * Use same as dictionary() but use dictionaryNative() instead of dictionary()
*/
export const translateNative = (key, showLabelWhenMissing = true, showBracketAlongLabel = true) => {

    const country = getCountryCode()

    if (store === undefined) return key
    if (store.dictionaryAll === undefined) return key
    if (store.dictionaryAll[country] === undefined) return key
    if (store.dictionaryAll[country][key] === undefined) return key

    let word = store.dictionaryAll[country][key]
    // follow language object when it's changed
    reaction(() => store.languageCurrent, () => word = store.dictionaryAll[country][key])
    // how to display brackets
    const bracketLeft = '[', bracketRight = ']'
    const tagBracket = (_string, brl, lb, brr) => `${showBracketAlongLabel ? brl : ''}${lb}${showBracketAlongLabel ? brr : ''}`
    const missingWord = tagBracket`${bracketLeft}${key}${bracketRight}`

    if (isNothing(word)) {
        // * Missing word case
        // check if this label alrady added in missing list
        const isSkipErrorMissingWord = testArray(key, missingList)
        if (isSkipErrorMissingWord === false) {
            loge(`Label [${key}] was not exists in dictionary (${getStoreLanguageName()})`)
            missingList.push(key)
        }

        // this word does not exists
        return showLabelWhenMissing ? missingWord : ''
    } else {
        // * Word exists case
        return devTools.isShowDictionaryLabel ? missingWord : word
    }
}