import Raven from '@Raven'

export const GenealogyFilterSetItem = (callback, baId, data) => {
    const setItemUrl = `https://member-calls2-kr.unicity.com/remoteStorage/data/genealogy-filters-${baId}`

    const headers = {
        'Content-Type': 'application/json'
    }

    return Raven.post(setItemUrl, data, { headers: headers })
}

export const GenealogyFilterGetItem = (callback, baId) => {
    const getItemUrl = `https://member-calls2-kr.unicity.com/remoteStorage/data/genealogy-filters-${baId}`

    Raven.get(getItemUrl)
        .then(data => {
            callback(true, data)
        })
        .catch(error => {
            callback(false, error)
        })
}

export const GenealogyFilterRemoveItem = (callback, baId) => {
    const getItemUrl = `https://member-calls2-kr.unicity.com/remoteStorage/data/genealogy-filters-${baId}`

    Raven.get(getItemUrl)
        .then(data => {
            callback(true, data)
        })
        .catch(error => {
            callback(false, error)
        })
}
