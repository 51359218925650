import { makeAutoObservable } from 'mobx'
import * as $ from './APIs'
import {
    getOrderHistoryList,
    getOrderHistoryCurrency,
    getOrderHistoryDetails,
    getOrderHistoryByBAID
} from './APIs/APIOrderHistory'
import { getProductSource, getProductSourceV2 } from './APIs/APIProductSource'
import {
    getCashCoupon,
    getARBalance,
    getAllowARBalance,
    payWithBalance
} from './APIs/APIBalance'
import { getFooter, getOnlineCountry } from './APIs/APIFooter'
import { sendFeedback } from './APIs/APICommon'
import {
    getRemoteStorage,
    setRemoteStorage,
    deleteRemoteStorage
} from './APIs/APIRemoteStorage'
import { getSamedayDelivery } from './APIs/APIShipping'

/** **Raven** 🐦 is a promise based HTTP client base on `AJAX` and `Mobx`.
 * Officialy use to connect with **Ushop** APIs. */
class StoreRaven {
    constructor() {
        makeAutoObservable(this)
    }

    get get() {
        return $._get
    }
    get post() {
        return $._post
    }
    get update() {
        return $._update
    }
    get delete() {
        return $._delete
    }

    get getRS() {
        return getRemoteStorage
    }
    get setRS() {
        return setRemoteStorage
    }
    get delRS() {
        return deleteRemoteStorage
    }
    get getRemoteStorage() {
        return getRemoteStorage
    }
    get setRemoteStorage() {
        return setRemoteStorage
    }
    get deleteRemoteStorage() {
        return deleteRemoteStorage
    }

    get hydraURL() {
        return $._getHydraEndpoint
    }
    get switchHydraEndpoint() {
        return $._switchHydraEndpoint
    }

    get getFooter() {
        return getFooter
    }
    get getOnlineCountry() {
        return getOnlineCountry
    }

    /** Get translation JSON of specified languages.
     * @param {string|array} languages could be **array** or **string** separated with comma (with case insensitive).
     * @example
     * Raven.getTranslations('EN,TH')
     * Raven.getTranslations(['EN','TH']) */
    get getTranslations() {
        return $._getTranslations
    }
    /** Request server for calculation of single order. Return its price,
     * availability of items and usually handle any unattended errors of order data. */
    get getOrderCalc() {
        return $._getOrderCalc
    }
    get getValidateCart() {
        return $._getValidateCart
    }
    get getSplitAddress() {
        return $._getSplitAddress
    }
    // * Balance Features
    get getARBalance() {
        return getARBalance
    }
    get getAllowARBalance() {
        return getAllowARBalance
    }
    get getCashCoupon() {
        return getCashCoupon
    }
    // * Validation
    /** Request an **Address** data validation result. */
    get validateAddress() {
        return $._validateAddress
    }
    /** Request an **Aramex** data validation result. */
    get validateAramex() {
        return $._validateAramex
    }
    // * Payment
    /** Request a direct payment process by **ARBalance** credit.
     * @param {string} referenceID a specified reference id for request order. */
    get payWithBalance() {
        return payWithBalance
    }
    // * Authorization
    get login() {
        return $._login
    }
    get getCustomer() {
        return $._getCustomer
    }
    get getCustomerOnselfProfile() {
        return $._getCustomerOnselfProfile
    }
    // * Address History
    get getAddressHistory() {
        return $._getAddressHistory
    }
    get addAddressHistory() {
        return $._addAddressHistory
    }
    get delAddressHistory() {
        return $._delAddressHistory
    }
    // * Zip Search
    get getZipSearch() {
        return $._getZipSearch
    }
    // * search address etc. parent
    get getAddSearchParent() {
        return $._getAddSearchParent
    }
    get getCitySearch() {
        return $._getCitySearch
    }
    get getCustomerExpand() {
        return $._getCustomerExpand
    }

    get getCartInfo() {
        return $._getCartInfo
    }
    get addCartInfo() {
        return $._addCartInfo
    }

    // * Order History Details
    get getOrderHistoryList() {
        return getOrderHistoryList
    }
    get getOrderHistoryCurrency() {
        return getOrderHistoryCurrency
    }
    get getOrderHistoryDetails() {
        return getOrderHistoryDetails
    }
    get getOrderHistoryByBAID() {
        return getOrderHistoryByBAID
    }

    // * Product Source
    get getProductSource() {
        return getProductSource
    }
    get getProductSourceV2() {
        return getProductSourceV2
    }
    // home page
    get getHomePageData() {
        return $._getHomePageData
    }

    get getBankBook() {
        return $._getBankBook
    }
    get getMenuPublic() {
        return $._getMenuPublic
    }
    get getMenuWithLogin() {
        return $._getMenuWithLogin
    }

    get getPaymentData() {
        return $._getPaymentData
    }

    // * Common APIs
    get sendFeedback() {
        return sendFeedback
    }

    // * seminars page
    get getSeminarsData() {
        return $._getSeminarsData
    }

    // * Shipping Methods
    get getSamedayDelivery() {
        return getSamedayDelivery
    }
}

const Raven = new StoreRaven()
export default Raven
