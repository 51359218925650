import { makeAutoObservable } from 'mobx';

class StoreEasyShop {
    isEnabled = false
    isEasyShopForm = false
    easyChoice = 2
    baID = ''
    referralCode = ''
    referralBy = ''
    referralValue = ''
    referralText = ''
    referenceDetail = {}
    referralAddress = {
        delivery: {
            fullName: '',
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            mobile: '',
            email: '',
            comment: ''
        },
        pickUp: {
            fullName: '',
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            mobile: '',
            email: '',
            comment: ''
        },
        aramex: {
            fullName: '',
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            mobile: '',
            email: '',
            comment: '',
            sns1: '',
            sns2: ''
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export const storeEasyShop = new StoreEasyShop()