import { devTools, store } from '../stores/MainStore'
import { storeAPI } from '../stores/StoreAPI'
import {
    isNothing,
    isSomething,
    isFunction,
    isString,
    isArray,
    isJSON
} from '../components/utils/Utils'
import {
    apiURL as globalAPIURL,
    getStoreLanguageName,
    getCountryCode3,
    dictionary,
    getCountryCode
} from '../components/GlobalHelpers'
import { apiURL, getAPIDomain } from '../components/ShoppingHelper'
import $ from 'jquery'
import {
    getCustomerToken,
    getCustomerHref,
    createCustomerToken,
    getCustomerHrefValue,
    getCustomerID
} from '../components/utils/Customer'
import { checkHost, isLocalhost } from '../components/configs/ConfigsHeader'
import * as K from '../components/configs/Keywords'
import { Country } from '../components/configs/Country'
import trim from 'lodash/trim'
import extend from 'lodash/extend'
import { get } from 'lodash'
import { storeMenu } from '../stores/StoreMenu'
import { createToken } from './use/FetchData'
import StoreAuth from '@Stores/User/StoreAuth'
import { language as l } from '@Language'
import StoreCountry from '@Stores/StoreCountry'

const version = {
    subdomain: 'hydra',
    latest: 'v5a',
    append: '' //-test
}

export const create = (baId, now = new Date()) => {
    const date = now.getUTCDate()
    const weekDay = now.getUTCDay() + 1
    const modeWeekDay = (date % weekDay) + 1
    const hash = baId
        .toString()
        .split('')
        .map(c => parseInt(c) % modeWeekDay)
        .join('')
    return `${hash}${weekDay}${date}`
}

const APIEndpoint = 'https://member-calls2-kr.unicity.com'

const APIEndpointLegacy = {
    local: 'http://localhost:8888/api/unishop',
    dev: 'https://member-kr.unicity.com/unifoapi_dev',
    live: 'https://member-kr2.unicity.com/unifoapi'
}

export const _getLegacyEndpoint = (forceLive = false) => {
    let resultEndpoint = APIEndpointLegacy.live
    if (forceLive) return resultEndpoint

    switch (checkHost()) {
        case K.Localhost:
            resultEndpoint = APIEndpointLegacy.dev
            break
        case K.Development:
            resultEndpoint = APIEndpointLegacy.live
            break
        default:
            resultEndpoint = APIEndpointLegacy.live
            break
    }

    return resultEndpoint
}

export const _switchHydraEndpoint = endpoint => {
    if (isNothing(endpoint)) return endpoint
    if (endpoint.indexOf('hydra') === -1) return endpoint

    //hydraqa

    switch (checkHost()) {
        case K.Localhost:
            version.append = ''
            break
        case K.Development:
            version.append = ''
            break
        default:
            version.append = ''
            break
    }

    const resultEndpoint = endpoint.replace(
        '/v5a/',
        `/${version.latest}${version.append}/`
    )

    return resultEndpoint
}

export function _getHydraEndpoint() {
    const version = {
        subdomain: 'hydra',
        latest: 'v5a',
        append: '' //-test
    }
    return `https://${version.subdomain}.unicity.net/${version.latest}${version.append}`
}

/** An alias version for `_getHydraEndpoint()`. */
export function hydraURL() {
    return _getHydraEndpoint()
}

/**
 * @param {String} url - The end-point of request.
 * @param {Object} params - Any query string as an `Object`.
 * @param {Function} fulfilledInterceptor - If **Succeed** to request url, call this method before return the response.
 * @param {Function} rejectInterceptor - If **Fail** to request url, call this method before return the response.
 * @return {Promise}
 */
export const _get = (
    url,
    params,
    configs,
    fulfilledInterceptor,
    rejectInterceptor
) => {
    let resultEndpoint = ''
    if (url.search('http') !== -1) {
        resultEndpoint = url
    } else {
        // resultEndpoint = `${APIEndpoint}/${url}`
        resultEndpoint = `https://member-calls2.unicity.com/${url}`
    }

    const options = {
        method: 'GET',
        url: resultEndpoint,
        async: true,
        ...configs
    }

    if (isSomething(params)) {
        options.data = params
    }

    return new Promise((resolve, reject) => {
        $.ajax(options)
            .done(response => {
                if (isFunction(fulfilledInterceptor)) {
                    /* const newResponse =  */ fulfilledInterceptor(response)
                    /* if (newResponse) {
                        response = newResponse
                    } */
                }
                if (isString(response)) {
                    if (isJSON(response)) {
                        response = JSON.parse(response)
                    }
                }

                resolve(response)
            })
            .fail((xhr, textStatus, errorThrown) => {
                const { responseJSON } = xhr
                if (isFunction(xhr, textStatus, errorThrown))
                    rejectInterceptor(xhr, textStatus, errorThrown)
                reject(responseJSON)
            })
    })
}

export const _post = (
    url,
    body,
    configs,
    fulfilledInterceptor,
    rejectInterceptor
) => {
    let resultEndpoint = ''
    if (url.search('http') !== -1) {
        resultEndpoint = url
    } else {
        //resultEndpoint = `${APIEndpoint}/${url}`
        resultEndpoint = `https://member-calls2-kr.unicity.com/${url}`
    }

    const options = {
        method: 'POST',
        url: resultEndpoint,
        data: JSON.stringify(body),
        async: true,
        ...configs
    }

    return new Promise((resolve, reject) => {
        $.ajax(options)
            .done(response => {
                if (isFunction(fulfilledInterceptor)) {
                    /* const newResponse =  */ fulfilledInterceptor(response)
                    /* if (newResponse) {
                        response = newResponse
                    } */
                }
                if (isString(response)) {
                    if (isJSON(response)) {
                        response = JSON.parse(response)
                    }
                }
                resolve(response)
            })
            .fail((xhr, textStatus, errorThrown) => {
                const { responseJSON } = xhr
                if (isFunction(xhr, textStatus, errorThrown))
                    rejectInterceptor(xhr, textStatus, errorThrown)
                reject(responseJSON)
            })
    })
}

export const _update = (
    url,
    body,
    configs,
    fulfilledInterceptor,
    rejectInterceptor
) => {
    let resultEndpoint = ''
    if (url.search('http') !== -1) {
        resultEndpoint = url
    } else {
        resultEndpoint = `${APIEndpoint}/${url}`
    }

    const options = {
        method: 'UPDATE',
        url: resultEndpoint,
        data: JSON.stringify(body),
        async: true,
        ...configs
    }

    return new Promise((resolve, reject) => {
        $.ajax(options)
            .done(response => {
                if (isFunction(fulfilledInterceptor)) {
                    /* const newResponse =  */ fulfilledInterceptor(response)
                    /* if (newResponse) {
                        response = newResponse
                    } */
                }
                if (isString(response)) {
                    if (isJSON(response)) {
                        response = JSON.parse(response)
                    }
                }
                resolve(response)
            })
            .fail((xhr, textStatus, errorThrown) => {
                const { responseJSON } = xhr
                if (isFunction(xhr, textStatus, errorThrown))
                    rejectInterceptor(xhr, textStatus, errorThrown)
                reject(responseJSON)
            })
    })
}

export const _delete = (
    url,
    configs,
    fulfilledInterceptor,
    rejectInterceptor
) => {
    let resultEndpoint = ''
    if (url.search('http') !== -1) {
        resultEndpoint = url
    } else {
        resultEndpoint = `${APIEndpoint}/${url}`
    }

    const options = {
        method: 'DELETE',
        url: resultEndpoint,
        async: true,
        ...configs
    }

    return new Promise((resolve, reject) => {
        $.ajax(options)
            .done(response => {
                if (isFunction(fulfilledInterceptor)) {
                    /* const newResponse =  */ fulfilledInterceptor(response)
                    /* if (newResponse) {
                        response = newResponse
                    } */
                }
                if (isString(response)) {
                    if (isJSON(response)) {
                        response = JSON.parse(response)
                    }
                }

                resolve(response)
            })
            .fail((xhr, textStatus, errorThrown) => {
                const { responseJSON } = xhr
                if (isFunction(xhr, textStatus, errorThrown))
                    rejectInterceptor(xhr, textStatus, errorThrown)
                reject(responseJSON)
            })
    })
}

export const _getOrderCalc = body => {
    // reset hasError state every start
    storeAPI.orderCalc.hasError = false

    // hardcode additional keys
    body.order.notes = 'ushop|'

    // simulated errors by query string ?error
    if (devTools.isHasError) {
        // body.order.lines.items = [] // test: no items error
        storeAPI.orderCalc.hasMockError = true
    }

    const result = _post(
        // 'https://member-calls2.unicity.com/ordercalc',
        'ordercalc',
        body,
        { header: { 'Content-Type': 'application/json' } },
        data => {},
        () => {
            // always mark hasError whenever rejected
            storeAPI.orderCalc.hasError = true
        }
    )

    return result
}

export const _getValidateCart = body => {
    const result = _post(apiURL.shopping.validate_cart_api, body)

    return result
}

// * Validation
export const _validateAddress = data => {
    data.language = l.getName(false)
    const result = _post(
        globalAPIURL[Country.getCode2()].validateAddress,
        data,
        {
            beforeSend: (_xhr, plianObject) => {
                plianObject.data = `strData=${encodeURIComponent(
                    plianObject.data
                )}`
            }
        }
    )
    return result
}

export const _validateAramex = (data, version = '2') => {
    // data.language = getStoreLanguageName()
    const countryCode3 = Country.getCode3()
    const result = _post(
        `https://member-calls4.unicity.com/${countryCode3}/${countryCode3}_Order_ARAMEX_ValidationV${version}.asp`,
        data,
        {
            dataType: 'json',
            beforeSend: (xhr, plianObject) =>
                (plianObject.data = `strData=${encodeURI(plianObject.data)}`)
        }
    )

    return result
}
// --------------
// Big Address
export const _getSplitAddress = data => {
    const result = _post(
        'https://member-calls2-kr.unicity.com/fn-split-address',
        data
    )
    return result
}
// --------------

export const _login = (username, password) => {
    let data = {
        type: 'base64',
        value: btoa(
            unescape(encodeURIComponent(trim(`${username}:${trim(password)}`)))
        ),
        namespace: `${hydraURL()}/customers`
    }



    const queryStrings = [
        `ushopCountryCode=${getCountryCode3()}`
    ].join("&")


    const result = _post(
        `${globalAPIURL.loginTokens}?${queryStrings}`,
        data,
        { headers: { 'Content-Type': 'application/json' } }
    )
    return result
}

export const _getCustomer = (token, href) => {
    const result = _get(
        `${href}`,
        { expand: 'customers, profilePicture' },
        { headers: { Authorization: `Bearer ${token}` } }
    )

    return result
}

export const _getCustomerOnselfProfile = baID => {
    const tempToken = createCustomerToken(baID)
    const url = `https://member-calls2.unicity.com/adapter/etl/onself?baId=${baID}&token=${tempToken}`

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${StoreAuth.token}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    }

    const result = _get(url, null, { headers: headers }, data => {
        return data.profile
    })

    return result
}

/** Get translation JSON of specified languages.
 * @param {string|array} languages could be **array** or **string** separated with comma (with case insensitive).
 * @example
 * Raven.getTranslations('EN,TH')
 * Raven.getTranslations(['EN','TH']) */
export const _getTranslations = languages => {
    let requestLanguages = ''
    if (isArray(languages)) {
        requestLanguages = languages.map(l => {
            return `${l},`
        })
        requestLanguages = requestLanguages.slice(0, -1)
    } else {
        requestLanguages = languages
    }

    const result = _get('dictionary/publish', { lang: `${languages}` })

    return result
}

// Address History
export const _getAddressHistory = () => {
    const result = _get(
        'adapter/recentAddress',
        { customerHref: StoreAuth.Href(false) },
        {
            headers: {
                'authorization-hydra': `Bearer ${StoreAuth.token}`
            }
        }
    )
    return result
}

export const _addAddressHistory = ({ shipToName, shipToAddress }) => {
    const result = _post(
        `adapter/recentAddress?customerHref=${StoreAuth.Href(false)}`,
        {
            shipToName: { ...shipToName },
            shipToAddress: { ...shipToAddress }
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'authorization-hydra': `Bearer ${StoreAuth.token}`
            }
        }
    )
    return result
}

export const _delAddressHistory = ({ addressHref }) => {
    const result = _post(
        `adapter/recentAddress?customerHref=${StoreAuth.Href(
            false
        )}&addressHref=${addressHref}`,
        {},
        {
            headers: {
                'Content-Type': 'application/json',
                'authorization-hydra': `Bearer ${StoreAuth.token}`
            }
        }
    )
    return result
}

export const _getCartInfo = userID => {
    const result = _get(`${apiURL.cart.get}/${userID}`)

    return result
}

export const _addCartInfo = (userID, cart) => {
    const data = {
        ba_id: userID,
        cart: cart
    }

    const result = _post(`${apiURL.cart.add}`, data)

    return result
}

export const _getZipSearch = (countryCode3, keyword, limit) => {
    const params = {
        country_code: countryCode3,
        keyword: keyword
    }

    if (limit) {
        extend(params, { limit: limit })
    }

    const result = _get(`unishop-fn-misc/city/search`, params)

    return result
}

export const _getAddSearchParent = (countryCode3, value, parent) => {
    const params = {
        parent,
        value
    }
    const result = _get(
        `unishop-fn-misc/city/country/${countryCode3}/list`,
        params
    )
    return result
}

export const _getCitySearch = countryCode3 => {
    const result = _get(`unishop-fn-misc/city/country/${countryCode3}`)
    return result
}

export const _getCustomerExpand = (userID, expand) => {
    const params = {
        token: createCustomerToken(userID)
    }

    if (expand) {
        params.expand = expand
    }

    return _get(`h/info/${userID}`, params)
}

export const _getHomePageData = countryCode3 => {
    let result = _get(
        `https://member-calls2-kr.unicity.com/home/data/${countryCode3}`
    )
    return result
}

export const _getBankBook = () => {
    let result = _get(
        `${APIEndpoint}/unishop-fn-misc/key_value/${getCountryCode3().toLowerCase()}_banks`
    )
    return result
}

export const _getMenuPublic = () => {
    let country_code3 = Country.getCode3()

    if (!country_code3) {
        country_code3 = StoreCountry.country.short
    }

    if (country_code3) {
        return _get(
            `https://member-calls2.unicity.com/etlV2/menu?ushopCountryCode=KOR`
        )
    } else {
        throw 'An impossible attempt detected: Do not use `getMenuPublic` without acknowledge country.'
    }
}

export const _getUserMenu = baId => {
    let country_code3 = Country.getCode3()
    if (baId && country_code3) {
        return _get(
            `https://member-calls2.unicity.com/etlV2/menu?countryCode=${country_code3}&baId=${baId}&token=${createToken(
                baId
            )}&ushopCountryCode=KOR`
        )
    } else {
        throw 'An impossible attempt detected: Do not use `getUserMenu` with an unauthorized case, use `getMenuPublic` instead.'
    }
}

export const _getMenuWithLogin = (customerId, country3Alpha, customerToken, byPassCache = false) => {
    let baId = customerId || StoreUser.getID()
    const token = createToken(baId)
    const country_code3 = country3Alpha || Country.getCode3()
    
    if (localStorage.getItem('dummy-user')) {
        baId = localStorage.getItem('dummy-user')
    }

    const isDummy = localStorage.getItem('isDummy')
    const params = {
        ushopCountryCode: country_code3,
        baId: baId,
        ...(isDummy ? { isDummy } : {}),
    }

    if (localStorage.getItem('dummy-user')) {
        params.isMockup = 1
    }

    if(byPassCache) {
        params.byPassCache = 1
    }

    if (localStorage.getItem('dummy-user')) {
        params.isMockup = 1
    }

    return _get(
        `etlV2/menu`,
        params,
        { 
            headers: {
                'Content-Type': 'application/json',
                'authorization-hydra': `Bearer ${customerToken || StoreAuth.GetToken()}`
            }
        }
    )
}

export const _getPaymentData = refID => {
    const result = _get(
        `https://member-calls2${
            isLocalhost() ? '-dev' : ''
        }.unicity.com/request-payment-data/${btoa(
            unescape(encodeURIComponent(trim(refID)))
        )}`
    )

    return result
}

export const _getProfileData = (baId, byPassCache) => {
    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${sessionStorage.getItem(
            'customerToken'
        )}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    }
    if (baId === '2970466') {
        localStorage.setItem('mockup-user-id', baId)
    }
    let result = _get(
        `https://member-calls2.unicity.com/adapter/etl/onself?baId=${baId}&token=${create(
            baId
        )}${byPassCache ? '&byPassCache=1' : ''}`,
        null,
        { headers: headers }
    )
    return result
}

export const _getSeminarsData = baId => {
    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${sessionStorage.getItem(
            'customerToken'
        )}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    }
    let result = _get(
        `https://member-calls2-kr.unicity.com/unishop-fn-misc/seminar/v2/get/${baId}?country_code=${getCountryCode3().toUpperCase()}`,
        { headers: headers }
    )
    return result
}
